<template>
  <div class="sd">
    <PagesSlider :banners="topBanners" :noHost="true" :color="'#830051'" />
    <section class="first-block">
      <div class="fixed-tabs">
        <div class="content">
          <div class="first-block__tabs">
            <div
              class="first-block__arrow first-block__arrow_left"
              @click="
                choiceFirstTab(
                  firstTabsActive > 0 ? firstTabsActive - 1 : firstTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="first-block__tabs-row">
              <div
                class="first-block__tab"
                @click="choiceFirstTab(0)"
                :class="{ 'first-block__tab_active': firstTabsActive === 0 }"
              >
                <img
                  class="first-block__tab-back"
                  v-if="firstTabsActive === 0"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="first-block__tab-icon">
                  <img
                    v-if="firstTabsActive === 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-1_active.svg`"
                  />
                  <img
                    v-if="firstTabsActive !== 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-1.svg`"
                  />
                </div>
                <div class="first-block__tab-text">
                  Диагностические критерии
                </div>
              </div>
              <div
                class="first-block__tab"
                @click="choiceFirstTab(1)"
                :class="{ 'first-block__tab_active': firstTabsActive === 1 }"
              >
                <img
                  class="first-block__tab-back"
                  v-if="firstTabsActive === 1"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="first-block__tab-icon">
                  <img
                    v-if="firstTabsActive === 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-2_active.svg`"
                  />
                  <img
                    v-if="firstTabsActive !== 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-2.svg`"
                  />
                </div>
                <div class="first-block__tab-text">Классификация</div>
              </div>
              <div
                class="first-block__tab"
                @click="choiceFirstTab(2)"
                :class="{ 'first-block__tab_active': firstTabsActive === 2 }"
              >
                <img
                  class="first-block__tab-back"
                  v-if="firstTabsActive === 2"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="first-block__tab-icon">
                  <img
                    v-if="firstTabsActive === 2"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-3_active.svg`"
                  />
                  <img
                    v-if="firstTabsActive !== 2"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs1-3.svg`"
                  />
                </div>
                <div class="first-block__tab-text">
                  Клиническая картина&nbsp;СД<sup>1,3,4</sup>
                </div>
              </div>
            </div>

            <div
              class="first-block__arrow first-block__arrow_right"
              @click="
                choiceFirstTab(
                  firstTabsActive < 2 ? firstTabsActive + 1 : firstTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="first-block__tab-body">
          <div v-if="firstTabsActive === 0">
            <div class="first-block__tab1-text">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-1.png`" />
              <span>
                <b>Сахарный диабет (СД)</b> – это группа метаболических
                (обменных) заболеваний, характеризующихся хронической
                гипергликемией, которая является результатом нарушения секреции
                инсулина, действия инсулина или обоих этих факторов. Хроническая
                гипергликемия при СД сопровождается повреждением, дисфункцией и
                недостаточностью различных органов, особенно глаз, почек,
                нервов, сердца и кровеносных сосудов<sup>1</sup>.
                <br />
                <br />
                Диагноз СД может быть установлен на основание выявления
                повышенного уровня гликированного гемоглобина (HbA1C) в крови
                или гипергликемии (как натощак, так и в ходе перорального
                глюкозотолерантного теста (ПГТТ)), а также в случае случайного
                выявления гипергликемии у пациентов с классическими симптомами
                (полиурия, полидипсия, потеря веса и тд)<sup>3-7</sup>.
              </span>
            </div>
            <PopUpDefault
              class="first-block__modal"
              @close="firstBlockModalToggle(false)"
              v-if="firstBlockModal"
            >
              <div class="first-block__modal-title">
                Пероральный глюкозотолерантный тест&nbsp;<sup>1,3-8</sup>
              </div>
              <div class="first-block__modal-block">
                <div class="first-block__modal-subtitle">
                  Показания для проведения ПГТТ:
                </div>
                <ul>
                  <li>Всем беременным женщинам на сроке 24–28 недель</li>
                  <li>
                    Гестационный сахарный диабет и/или рождение крупного плода
                    (более 4 кг)
                  </li>
                  <li>Наличие сахарного диабета у близких родственников</li>
                  <li>Ожирение (ИМТ>30 кг/м&#178;)</li>
                  <li>
                    Окружность талии >80 см у женщин и >94 см у мужчин и другие
                    факторы риска
                  </li>
                </ul>
              </div>
              <div class="first-block__modal-block">
                <div class="first-block__modal-subtitle">
                  ПГТТ не проводится:
                </div>
                <ul>
                  <li>На фоне острого заболевания;</li>
                  <li>
                    На фоне кратковременного приема препаратов, повышающих
                    уровень гликемии (глюкокортикоиды, тиреоидные гормоны,
                    тиазиды, бета-адреноблокаторы и др.)
                  </li>
                </ul>
              </div>
              <div class="first-block__modal-block">
                <div class="first-block__modal-subtitle">
                  Правила проведения ПГТТ:
                </div>
                <ul>
                  <li>
                    ПГТТ следует проводить утром на фоне не менее чем 3-дневного
                    неограниченного питания (более 150 г углеводов в сутки) и
                    обычной физической активности.
                  </li>
                  <li>
                    Тесту должно предшествовать ночное голодание в течение 8–14
                    ч (можно пить воду).
                  </li>
                  <li>
                    Последний вечерний прием пищи должен содержать 30–50 г
                    углеводов.
                  </li>
                  <li>
                    После забора крови натощак испытуемый должен не более чем за
                    5 мин выпить 75 г безводной глюкозы или 82,5 г моногидрата
                    глюкозы, растворенных в 250–300 мл воды.
                  </li>
                  <li>В процессе теста не разрешается курение.</li>
                  <li>Через 2 ч осуществляется повторный забор крови.</li>
                  <li>
                    Для предотвращения гликолиза и ошибочных результатов
                    определение концентрации глюкозы проводится сразу после
                    взятия крови, или кровь должна быть центрифугирована сразу
                    после взятия, или храниться при температуре 0–4°С, или быть
                    взята в пробирку с консервантом (флуорид натрия).
                  </li>
                </ul>
              </div>
            </PopUpDefault>
            <swiper
              class="d-xl-none first-block__tab1-carousel"
              v-bind="swiperSettings"
            >
              <swiper-slide>
                <div
                  class="first-block__tab1-block"
                  :style="'background-color: #5ecad8'"
                >
                  <div class="first-block__tab1-block-title">
                    Диагностические критерии
                    <b style="color: #3e1876">сахарного диабета</b>
                    <sup>1-9</sup>
                  </div>
                  <div class="first-block__tab1-block-items">
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item1.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-label">
                          Гликемия натощак<sup>a</sup>
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥7.0 ммоль/л
                        </div>
                      </div>
                    </div>
                    <span>или</span>
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item2.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-label">
                          Гликемия через 2 часа после ПГТТ<sup>b</sup>
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥11.1 ммоль/л
                          <svg
                            @click="firstBlockModalToggle(true)"
                            class="d-inline"
                            style="vertical-align: middle; cursor: pointer"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_353_8698)">
                              <path
                                d="M10.9995 0C4.92521 0 0 4.92521 0 10.9995C0 17.0739 4.92521 22 10.9995 22C17.0739 22 22 17.0739 22 10.9995C22 4.92521 17.0739 0 10.9995 0ZM13.2894 17.0478C12.7232 17.2713 12.2725 17.4408 11.9345 17.5581C11.5974 17.6754 11.2053 17.7341 10.7593 17.7341C10.0739 17.7341 9.54032 17.5665 9.16038 17.2322C8.78044 16.8979 8.59141 16.4742 8.59141 15.9592C8.59141 15.759 8.60538 15.5541 8.63331 15.3455C8.66218 15.1369 8.70781 14.9023 8.7702 14.6387L9.47886 12.1356C9.54125 11.8954 9.59526 11.6672 9.6381 11.4549C9.68093 11.2407 9.70142 11.0442 9.70142 10.8654C9.70142 10.547 9.6353 10.3235 9.504 10.1978C9.37084 10.072 9.12034 10.0106 8.74692 10.0106C8.5644 10.0106 8.3763 10.0376 8.18353 10.0944C7.99264 10.1531 7.82688 10.2061 7.69092 10.2583L7.8781 9.48724C8.34184 9.2982 8.78603 9.13617 9.20974 9.00207C9.63344 8.86612 10.0339 8.79907 10.411 8.79907C11.0917 8.79907 11.6169 8.96483 11.9866 9.29261C12.3545 9.62133 12.5398 10.0488 12.5398 10.574C12.5398 10.6829 12.5267 10.8748 12.5016 11.1485C12.4764 11.4232 12.429 11.6737 12.36 11.9037L11.6551 14.3994C11.5974 14.5996 11.5462 14.8287 11.4996 15.0848C11.454 15.3409 11.4316 15.5364 11.4316 15.6677C11.4316 15.9992 11.5052 16.2255 11.6542 16.3457C11.8013 16.4658 12.0593 16.5263 12.4243 16.5263C12.5966 16.5263 12.7893 16.4956 13.0072 16.436C13.2233 16.3764 13.3797 16.3233 13.4784 16.2777L13.2894 17.0478ZM13.1646 6.91801C12.8359 7.22345 12.4401 7.37617 11.9773 7.37617C11.5154 7.37617 11.1169 7.22345 10.7854 6.91801C10.4557 6.61257 10.289 6.24102 10.289 5.80707C10.289 5.37405 10.4566 5.00157 10.7854 4.69333C11.1169 4.38417 11.5154 4.23052 11.9773 4.23052C12.4401 4.23052 12.8368 4.38417 13.1646 4.69333C13.4933 5.00157 13.6582 5.37405 13.6582 5.80707C13.6582 6.24195 13.4933 6.61257 13.1646 6.91801Z"
                                fill="#D0006F"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_353_8698">
                                <rect width="22" height="22" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <span>или</span>
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item3.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-label">
                          Случайное<sup>c</sup> выявление гипергликемии
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥11.1 ммоль/л
                        </div>
                        <div class="first-block__tab1-block-item-description">
                          и наличие характерных симптомов СД
                        </div>
                      </div>
                    </div>
                    <span>или</span>
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item4.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-label">
                          HbA1C<sup>d</sup>
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥6.5% (≥48 ммоль/моль)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div
                  class="first-block__tab1-block first-block__tab1-block2"
                  :style="'background-color: #d0006f'"
                >
                  <div class="first-block__tab1-block-title">
                    Диагностические критерии
                    <b style="color: #fff">гестационного СД</b>
                    <sup>8, 9</sup>
                  </div>
                  <div class="first-block__tab1-block-description">
                    Беременным пациенткам диагноз ГСД может быть установлен в
                    случае однократного выявления повышения уровня глюкозы в
                    венозной крови:
                  </div>
                  <div class="first-block__tab1-block-items">
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item1.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-description">
                          Натощак
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥5,1 и &lt;7,0 ммоль/л
                        </div>
                      </div>
                    </div>

                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item2.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-description">
                          Через 1 час после ПГТТ
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥ 10,0 ммоль/л
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab1-block-item">
                      <div class="first-block__tab1-block-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item3.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab1-block-item-text">
                        <div class="first-block__tab1-block-item-description">
                          Через 2 часа после ПГТТ
                        </div>
                        <div class="first-block__tab1-block-item-value">
                          ≥ 8,5 и &lt;11,1 ммоль/л
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    class="first-block__tab1-block-back"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-back.png
                    `"
                  />
                </div>
              </swiper-slide>
              <div slot="container-end">
                <div class="slider-dots">
                  <div class="slider-dots__row"></div>
                  <div class="slider-dots__icon">
                    <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/swipe-left.svg`" />
                  </div>
                </div>
              </div>
            </swiper>
            <div class="first-block__tab1-blocks d-none d-xl-grid">
              <div
                class="first-block__tab1-block"
                style="background-color: #5ecad8"
              >
                <div class="first-block__tab1-block-title">
                  Диагностические критерии
                  <b style="color: #3e1876">сахарного диабета</b> <sup>1-9</sup>
                </div>
                <div class="first-block__tab1-block-items">
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item1.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-label">
                        Гликемия натощак<sup>a</sup>
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥7.0 ммоль/л
                      </div>
                    </div>
                  </div>
                  <span>или</span>
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item2.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-label">
                        Гликемия через 2 часа после ПГТТ<sup>b</sup>
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥11.1 ммоль/л
                        <svg
                          @click="firstBlockModalToggle(true)"
                          class="d-inline"
                          style="vertical-align: middle; cursor: pointer"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_353_8698)">
                            <path
                              d="M10.9995 0C4.92521 0 0 4.92521 0 10.9995C0 17.0739 4.92521 22 10.9995 22C17.0739 22 22 17.0739 22 10.9995C22 4.92521 17.0739 0 10.9995 0ZM13.2894 17.0478C12.7232 17.2713 12.2725 17.4408 11.9345 17.5581C11.5974 17.6754 11.2053 17.7341 10.7593 17.7341C10.0739 17.7341 9.54032 17.5665 9.16038 17.2322C8.78044 16.8979 8.59141 16.4742 8.59141 15.9592C8.59141 15.759 8.60538 15.5541 8.63331 15.3455C8.66218 15.1369 8.70781 14.9023 8.7702 14.6387L9.47886 12.1356C9.54125 11.8954 9.59526 11.6672 9.6381 11.4549C9.68093 11.2407 9.70142 11.0442 9.70142 10.8654C9.70142 10.547 9.6353 10.3235 9.504 10.1978C9.37084 10.072 9.12034 10.0106 8.74692 10.0106C8.5644 10.0106 8.3763 10.0376 8.18353 10.0944C7.99264 10.1531 7.82688 10.2061 7.69092 10.2583L7.8781 9.48724C8.34184 9.2982 8.78603 9.13617 9.20974 9.00207C9.63344 8.86612 10.0339 8.79907 10.411 8.79907C11.0917 8.79907 11.6169 8.96483 11.9866 9.29261C12.3545 9.62133 12.5398 10.0488 12.5398 10.574C12.5398 10.6829 12.5267 10.8748 12.5016 11.1485C12.4764 11.4232 12.429 11.6737 12.36 11.9037L11.6551 14.3994C11.5974 14.5996 11.5462 14.8287 11.4996 15.0848C11.454 15.3409 11.4316 15.5364 11.4316 15.6677C11.4316 15.9992 11.5052 16.2255 11.6542 16.3457C11.8013 16.4658 12.0593 16.5263 12.4243 16.5263C12.5966 16.5263 12.7893 16.4956 13.0072 16.436C13.2233 16.3764 13.3797 16.3233 13.4784 16.2777L13.2894 17.0478ZM13.1646 6.91801C12.8359 7.22345 12.4401 7.37617 11.9773 7.37617C11.5154 7.37617 11.1169 7.22345 10.7854 6.91801C10.4557 6.61257 10.289 6.24102 10.289 5.80707C10.289 5.37405 10.4566 5.00157 10.7854 4.69333C11.1169 4.38417 11.5154 4.23052 11.9773 4.23052C12.4401 4.23052 12.8368 4.38417 13.1646 4.69333C13.4933 5.00157 13.6582 5.37405 13.6582 5.80707C13.6582 6.24195 13.4933 6.61257 13.1646 6.91801Z"
                              fill="#D0006F"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_353_8698">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <span>или</span>
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item3.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-label">
                        Случайное<sup>c</sup> выявление гипергликемии
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥11.1 ммоль/л
                      </div>
                      <div class="first-block__tab1-block-item-description">
                        и наличие характерных симптомов СД
                      </div>
                    </div>
                  </div>
                  <span>или</span>
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block1-item4.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-label">
                        HbA1C<sup>d</sup>
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥6.5% (≥48 ммоль/моль)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="first-block__tab1-block first-block__tab1-block2"
                style="background-color: #d0006f"
              >
                <div class="first-block__tab1-block-title">
                  Диагностические критерии
                  <b style="color: #fff">гестационного СД</b> <sup>8, 9</sup>
                </div>
                <div class="first-block__tab1-block-description">
                  Беременным пациенткам диагноз ГСД может быть установлен в
                  случае однократного выявления повышения уровня глюкозы в
                  венозной крови:
                </div>
                <div class="first-block__tab1-block-items">
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item1.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-description">
                        Натощак
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥5,1 и &lt;7,0 ммоль/л
                      </div>
                    </div>
                  </div>

                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item2.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-description">
                        Через 1 час после ПГТТ
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥ 10,0 ммоль/л
                      </div>
                    </div>
                  </div>
                  <div class="first-block__tab1-block-item">
                    <div class="first-block__tab1-block-item-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-item3.svg
                        `"
                      />
                    </div>
                    <div class="first-block__tab1-block-item-text">
                      <div class="first-block__tab1-block-item-description">
                        Через 2 часа после ПГТТ
                      </div>
                      <div class="first-block__tab1-block-item-value">
                        ≥ 8,5 и &lt;11,1 ммоль/л
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  class="first-block__tab1-block-back"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-block2-back.png
                  `"
                />
              </div>
            </div>
            <div class="first-block__tab1-tip">
              Примечание:
              <br />&#7491; – Исследование натощак подразумевает, что пациент не
              принимал пищу в течение 8 часов перед исследованием. <br />&#7495;
              – ПГТТ – стандартизированная процедура, в ходе которой пациенту
              измеряется уровень глюкозы крови натощак и через 2 часа после
              приема 75 г глюкозы, разведенной в воде (для детей: 1,75 г глюкозы
              на 1 кг веса, но не более 75 г). <br />&#7580; – Под случайной
              гипергликемией подразумевается выявление высокого уровня сахара
              крови вне зависимости от предшествующего времени приема пищи.
              <br />&#7496; – Уровень HbA1C отражает усредненную гликемию в
              течение последних 3-х месяцев. Его диагностическая точность может
              быть ограничена в случае стремительно развивающегося СД (например,
              у детей). Кроме того, есть ряд негликемических факторов,
              искажающих уровень HbA1C, например, анемия, хроническое
              употребление алкоголя или опиатов, беременность, прием некоторых
              препаратов, терминальная почечная недостаточность и др.
            </div>
            <a
              :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf`"
              download="ПГТТ.pdf"
              target="_blank"
              class="
                first-block__tab1-button
                button button_pink-light
                px-8 px-xs-6
                py-4
                mx-auto
              "
            >
              Скачать правила проведения ПГТТ
              <svg
                class="ml-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                  fill="white"
                />
                <path
                  d="M12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V13.59L7.71 10.29C7.5217 10.1017 7.2663 9.99591 7 9.99591C6.7337 9.99591 6.47831 10.1017 6.29 10.29C6.1017 10.4783 5.99591 10.7337 5.99591 11C5.99591 11.2663 6.1017 11.5217 6.29 11.71L11.29 16.71C11.383 16.8037 11.4936 16.8781 11.6154 16.9289C11.7373 16.9797 11.868 17.0058 12 17.0058C12.132 17.0058 12.2627 16.9797 12.3846 16.9289C12.5064 16.8781 12.617 16.8037 12.71 16.71L17.71 11.71C17.8032 11.6168 17.8772 11.5061 17.9277 11.3843C17.9781 11.2624 18.0041 11.1319 18.0041 11C18.0041 10.8681 17.9781 10.7376 17.9277 10.6158C17.8772 10.4939 17.8032 10.3832 17.71 10.29C17.6168 10.1968 17.5061 10.1228 17.3843 10.0723C17.2624 10.0219 17.1319 9.99591 17 9.99591C16.8681 9.99591 16.7376 10.0219 16.6158 10.0723C16.4939 10.1228 16.3832 10.1968 16.29 10.29L13 13.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2Z"
                  fill="white"
                />
              </svg>
            </a>
            <div class="first-block__tab1-bottom">
              <div class="first-block__tab1-bottom-img">
                <img
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab1-bottom.png
                  `"
                />
              </div>
              <div class="first-block__tab1-bottom-text">
                Диагноз СД всегда следует подтверждать повторным определением
                гликемии в последующие дни, за исключением случаев несомненной
                гипергликемии с острой метаболической декомпенсацией или с
                очевидными симптомами.
              </div>
            </div>
          </div>
          <div v-if="firstTabsActive === 1">
            <div class="tap-hint mb-4 mt-4">
              <div class="tap-hint__icon mr-2">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tap.svg`" />
              </div>
              <div class="tap-hint__text">Выберите тип сахарного диабета</div>
            </div>
            <div class="first-block__tab2-row">
              <div class="first-block__tab2-tabs">
                <div
                  class="first-block__tab2-tab"
                  @click="changeTabs2(0)"
                  :class="{
                    'first-block__tab2-tab_active': tab2ActiveTab === 0,
                  }"
                >
                  <img
                    class="first-block__tab2-tab-back"
                    v-if="tab2ActiveTab === 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                  />
                  <div class="first-block__tab2-tab-icon">
                    <img
                      v-if="tab2ActiveTab !== 0"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab1.svg
                      `"
                    />
                    <img
                      v-if="tab2ActiveTab === 0"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab1_active.svg
                      `"
                    />
                  </div>
                  <div class="first-block__tab2-tab-text">
                    Сахарный диабет 1 типа
                  </div>
                </div>
                <div
                  class="first-block__tab2-tab"
                  @click="changeTabs2(1)"
                  :class="{
                    'first-block__tab2-tab_active': tab2ActiveTab === 1,
                  }"
                >
                  <img
                    class="first-block__tab2-tab-back"
                    v-if="tab2ActiveTab === 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                  />
                  <div class="first-block__tab2-tab-icon">
                    <img
                      v-if="tab2ActiveTab !== 1"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab2.svg
                      `"
                    />
                    <img
                      v-if="tab2ActiveTab === 1"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab2_active.svg
                      `"
                    />
                  </div>
                  <div class="first-block__tab2-tab-text">
                    Сахарный диабет 2 типа
                  </div>
                </div>
                <div
                  class="first-block__tab2-tab"
                  @click="changeTabs2(2)"
                  :class="{
                    'first-block__tab2-tab_active': tab2ActiveTab === 2,
                  }"
                >
                  <img
                    class="first-block__tab2-tab-back"
                    v-if="tab2ActiveTab === 2"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                  />
                  <div class="first-block__tab2-tab-icon">
                    <img
                      v-if="tab2ActiveTab !== 2"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab3.svg
                      `"
                    />
                    <img
                      v-if="tab2ActiveTab === 2"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab3_active.svg
                      `"
                    />
                  </div>
                  <div class="first-block__tab2-tab-text">
                    Другие формы сахарного диабета
                  </div>
                </div>
                <div
                  class="first-block__tab2-tab"
                  @click="changeTabs2(3)"
                  :class="{
                    'first-block__tab2-tab_active': tab2ActiveTab === 3,
                  }"
                >
                  <img
                    class="first-block__tab2-tab-back"
                    v-if="tab2ActiveTab === 3"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                  />
                  <div class="first-block__tab2-tab-icon">
                    <img
                      v-if="tab2ActiveTab !== 3"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab4.svg
                      `"
                    />
                    <img
                      v-if="tab2ActiveTab === 3"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab2-tab4_active.svg
                      `"
                    />
                  </div>
                  <div class="first-block__tab2-tab-text">
                    Гестационный сахарный диабет
                  </div>
                </div>
              </div>
              <div class="first-block__tab2-body">
                <div v-if="tab2ActiveTab === 0">
                  <div class="first-block__tab2-body-title">
                    <span>СД 1</span> характеризуется хронической,
                    иммуноопосредованной деструкцией β-клеток островков
                    поджелудочной железы, которая приводит, в большинстве
                    случаев, к абсолютному дефициту инсулина.
                    <br />
                    <br />
                    Разрушение β-клеток происходит с различной скоростью и
                    становится клинически значимым при потере примерно 90% их
                    общей массы<sup>3,4</sup>.
                  </div>
                  <div class="first-block__tab2-body-text">
                    СД1 является многофакторным заболеванием. Помимо
                    особенностей иммунной системы, выделяют генетическую
                    предрасположенность, а также экологические и инфекционные
                    триггеры развития заболевания. Аутоиммунное разрушение
                    β-клеток – сложный, многоэтапный процесс, в ходе которого
                    активируется как клеточное, так и гуморальное звено
                    иммунитета. Ассоциированные с СД1 аутоантитела (АТ) являются
                    серологическими маркерами аутоиммунной деструкции β-клеток.
                    К ним относятся АТ к глютаматдекарбоксилазе (GADA), к
                    тирозинфосфатазе (IA2), к инсулину (IAA) и к транспортеру
                    цинка 8 (ZnT8A)<sup>3,4</sup>.
                    <br />
                    <br />
                    Согласно данным международной федерации сахарного диабета
                    (IDF) на 2021 год, в мире общее число детей и подростков (до
                    19 лет) с СД1 составляет более 1,2 млн. человек из них более
                    половины (54%) – дети до 15 лет. Заболеваемость СД1 растет с
                    каждым годом и ежегодно заболевает более 108 тыс. детей в
                    возрасте от 0 до 14 лет и более 41 тыс. подростков в
                    возрасте от 15 до 19 лет<sup>2</sup>. В РФ также отмечается
                    рост заболеваемости. Так, в период с 2010 по 2022 г.
                    распространённость СД 1 типа выросла с 146,0 до 191,0/100
                    тыс. населения. Кроме того, многие эпидемиологические
                    исследования отмечают значимый рост заболеваемости у детей в
                    возрасте до 5 лет<sup>3,4</sup>.
                  </div>
                </div>
                <div v-if="tab2ActiveTab === 1">
                  <div class="first-block__tab2-body-title">
                    <span>Сахарный диабет 2 типа (СД 2)</span> — нарушение
                    углеводного обмена, вызванное преимущественной
                    инсулинорезистентностью и относительной инсулиновой
                    недостаточностью или преимущественным нарушением секреции
                    инсулина с инсулинорезистентностью или без нее<sup>7</sup>.
                  </div>
                  <div class="first-block__tab2-body-text">
                    Является полигенной формой диабета, развивающейся вследствие
                    <b
                      >сочетания двух основных патофизиологических
                      механизмов:</b
                    >
                    <div class="first-block__tab2-body-text-check mb-4 mt-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        <b>Инсулинорезистентность</b> периферических тканей к
                        действию собственного инсулина.
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Относительное <b>снижение секреции инсулина.</b> При
                        этом на первых этапах развития СД 2 типа отмечается
                        гиперсекреция инсулина, возникающая в организме как
                        гиперкомпенсация с целью преодоления
                        инсулинорезистентности тканей.
                      </div>
                    </div>
                    Существует более 40 генов, которые контролируют
                    предрасположенность к развитию СД 2 типа в целом (эти гены
                    кодируют инсулинорезистентность, дисфункцию B клеток,
                    склонность к ожирению и гиперпродукцию глюкозы
                    печенью)<sup>64</sup>. Кроме того, на развитие хронической
                    гипергликемии могут влиять недостаточность инкретинов,
                    нарушение секреции глюкагона, повышенная реабсорбция глюкозы
                    в почках, хроническое воспаление, изменения микробиоты
                    кишечника и др.<sup>7</sup>
                    <br />
                    <br />
                    Численность случаев СД 2 типа неуклонно растет во всем мире.
                    На 2023 год количество пациентов с СД в РФ составляет около
                    4,5 млн человек. Эти данные отражают лишь выявленные и
                    зарегистрированные случаи. Результаты масштабного
                    российского эпидемиологического исследования (NATION)
                    подтверждают, что по обращаемости в рутинной клинической
                    практике диагностируется лишь 54% СД 2 и у 46% пациентов СД
                    выявляется только посредством активного скрининга. Таким
                    образом, можно предположить, что реальная численность
                    пациентов с СД 2 в РФ не менее 9 млн. человек<sup>1</sup>.
                  </div>
                </div>
                <div v-if="tab2ActiveTab === 2">
                  <div class="first-block__tab2-body-text mb-4">
                    Другие формы сахарного диабета включают в себя разнообразные
                    варианты СД, принципиально отличающиеся по этиологии и
                    патогенезу. Течение СД, равно как и выбор терапии в данном
                    случае напрямую зависит от этиологической причины.
                  </div>
                  <div class="first-block__tab2-body-title">
                    <span>Специфические причины развития СД:</span>
                  </div>
                  <div class="first-block__tab2-body-line">
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>А</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Генетические дефекты функции β-клеток:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Хромосома 12, HNF-1α (MODY3)
                          <br />
                          2. Хромосома 7, глюкокиназа (MODY2)
                          <br />
                          3. Хромосома 20, HNF-4α (MODY1)
                          <br />
                          4. Хромосома 13, инсулиновый промоторный фактор —
                          IPF-1 (MODY4)
                          <br />
                          5. Хромосома 17, HNF-1Я (MODY5)
                          <br />
                          6. Хромосома 2, NeuroD1 (MODY6)
                          <br />
                          7. Митохондриальная ДНК-мутация
                          <br />
                          8. Хромосома 6, KCNJ11 (Kir6.2), ABCC8 (Sur 1)
                          <br />
                          9. Некоторые другие, причина которых — моногенные
                          дефекты синтеза инсулина.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>B</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Генетические дефекты действия инсулина:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Резистентность к инсулину типа А
                          <br />
                          2. Лепречаунизм (синдром Донохью)
                          <br />
                          3. Синдром Рабсона–Менделхолла
                          <br />
                          4. Липоатрофический диабет
                          <br />
                          5. Некоторые другие формы диабета, возникающие
                          вследствие мутации гена рецептора инсулина. Клинически
                          — нарушения углеводного обмена от умеренной
                          гипергликемии и гиперинсулинемии до явного сахарного
                          диабета. Синдром Донохью и синдром Рэбсона–Менделхолла
                          манифестируют в детском возрасте и проявляются резко
                          выраженной резистентностью к инсулину.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>C</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Заболевания экзокринной части поджелудочной железы:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Панкреатит
                          <br />
                          2. Травма, панкреатэктомия
                          <br />
                          3. Новообразования поджелудочной железы
                          <br />
                          4. Кистозный фиброз (муковисцедоз)
                          <br />
                          5. Гемохроматоз
                          <br />
                          6. Фибро-калькулезная панкреатопатия
                          <br />
                          7. Некоторые другие нозологически различные
                          заболевания, при которых наряду со значительными
                          нарушениями экзокринной функции поджелудочной железы
                          наблюдается и недостаточность секреторной функции
                          островковых клеток.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>D</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Эндокринопатии:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Акромегалия
                          <br />
                          2. Синдром Кушинга
                          <br />
                          3. Глюкагонома
                          <br />
                          4. Феохромоцитома
                          <br />
                          5. Гипертиреоз
                          <br />
                          6. Соматостатинома
                          <br />
                          7. Альдостерома
                          <br />
                          8. Некоторые другие вследствие контринсулярного
                          действия выделяемых в избыточном количестве гормонов
                          могут приводить к истощению функциональных
                          компенсаторных резервов β-клеток поджелудочной железы.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>Е</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Диабет, индуцированный лекарственными препаратами или
                          химическими веществами:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Вакор
                          <br />
                          2. Пентамидин
                          <br />
                          3. Никотиновая кислота
                          <br />
                          4. Глюкокортикоиды
                          <br />
                          5. Тиреоидные гормоны
                          <br />
                          6. Диазоксид
                          <br />
                          7. β-адренергические агонисты
                          <br />
                          8. Тиазиды
                          <br />
                          9. Дилантин
                          <br />
                          10. α-интерферон
                          <br />
                          11. Другие препараты. Механизмы их действия различны:
                          ухудшение действия инсулина на периферии, усиление
                          имеющейся инсулинорезистентности. В детском возрасте
                          наибольшее значение имеет применение α-интерферона,
                          под влиянием которого может развиться аутоиммунный
                          диабет с выраженной абсолютной недостаточностью
                          инсулина.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>F</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Инфекции:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Врожденная краснуха
                          <br />
                          2. Цитомегаловирус
                          <br />
                          3. Другие. Под действием β-токсических вирусных
                          инфекций происходит деструкция β-клеток с развитием
                          абсолютной инсулиновой недостаточности.
                          Непосредственное поражение вирусом островкового
                          аппарата встречается редко.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>G</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Редкие формы сахарного диабета:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          1. Синдром ригидного человека («Stiff-man» syndrome) —
                          аутоиммунное заболевание ЦНС, характеризующееся
                          ригидностью аксиальных мышц с болезненным их спазмом,
                          при котором выявляются антитела к
                          глютаматдекарбоксилазе и почти в 50% случаев
                          развивается сахарный диабет
                          <br />
                          2. Синдром полиэндокринной аутоиммунной
                          недостаточности I и II (APS I и II) типов
                          <br />
                          3. Другие заболевания с образованием аутоантител к
                          рецепторам инсулина — системная красная волчанка,
                          пигментно-сосочковая дистрофия кожи (acanthosis
                          nigricans). При этом может наблюдаться выраженная
                          резистентность к инсулину.
                        </div>
                      </div>
                    </div>
                    <div class="first-block__tab2-body-line-item">
                      <div class="first-block__tab2-body-line-item-index">
                        <span>H</span>
                      </div>
                      <div class="first-block__tab2-body-line-item-text">
                        <div class="first-block__tab2-body-line-item-title">
                          Другие генетические синдромы, иногда сочетающиеся с
                          диабетом:
                        </div>
                        <div class="first-block__tab2-body-line-item-text">
                          <b
                            >Сахарный диабет может быть компонентом многих
                            генетических синдромов:</b
                          >
                          <br />
                          <br />
                          1. Синдром Вольфрама
                          <br />
                          2. Синдром Дауна
                          <br />
                          3. Синдром Шерешевского–Тернера
                          <br />
                          4. Синдром Клайн-Фельтера
                          <br />
                          5. Синдром Лоуренса–Муна–Бидля
                          <br />
                          6. Синдром Прадер–Вили
                          <br />
                          7. Атаксия Фридрайха
                          <br />
                          8. Хорея Хантигтона
                          <br />
                          9. Порфирия
                          <br />
                          10 Миотоническая дистрофия и пр.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="first-block__tab2-body-tip">
                    Адаптировано из: ISPAD, 2009.
                  </div>
                </div>
                <div v-if="tab2ActiveTab === 3">
                  <div class="first-block__tab2-body-title">
                    <span>Гестационный сахарный диабет (ГСД)</span> – это
                    заболевание, характеризующееся гипергликемией, впервые
                    выявленной во время беременности, но не соответствующей
                    критериям «манифестного» СД<sup>6</sup>.
                  </div>
                  <div class="first-block__tab2-body-text">
                    Распространенность ГСД, как и других форм СД, во всем мире
                    неуклонно растет. Это связано со множеством причин, в том
                    числе с повышением среднего возраста рожениц.
                    <br />
                    <br />
                    <b
                      >Неконтролируемый СД во время беременности ассоциирован с
                      рисками:</b
                    >
                    <br />
                    <br />
                    <div class="first-block__tab2-body-text-check mb-4 mt-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Невынашивания
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Развитием аномалий плода
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Преэклампсией
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">Макросомией</div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Неонатальной гипогликемией, гипербилирубинемией
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Неонатальным респираторным дистресс-синдромом и многими
                        другими.
                      </div>
                    </div>
                    <b>
                      Кроме того, в литературе имеются данные об ассоциации
                      диабета во время беременности с развитием ожирения,
                      гипертонии и диабета 2 типа у потомства во взрослом
                      возрасте<sup>6</sup>.
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="firstTabsActive === 2">
            <div class="first-block__tab3-tabs">
              <div class="first-block__tab3-tabs-list">
                <div
                  class="first-block__tab3-tabs-tab"
                  @click="changeTabs3(0)"
                  :class="{
                    'first-block__tab3-tabs-tab_active': tab3ActiveTab === 0,
                  }"
                >
                  Симптомы
                </div>
                <div
                  class="first-block__tab3-tabs-tab"
                  @click="changeTabs3(1)"
                  :class="{
                    'first-block__tab3-tabs-tab_active': tab3ActiveTab === 1,
                  }"
                >
                  Клинические проявления диабетического кетоацидоза
                </div>
                <div
                  class="first-block__tab3-tabs-tab"
                  @click="changeTabs3(2)"
                  :class="{
                    'first-block__tab3-tabs-tab_active': tab3ActiveTab === 2,
                  }"
                >
                  Дифференциальная диагностика&nbsp;СД
                </div>
                <div
                  class="first-block__tab3-tabs-tab"
                  @click="changeTabs3(3)"
                  :class="{
                    'first-block__tab3-tabs-tab_active': tab3ActiveTab === 3,
                  }"
                >
                  Сложности
                </div>
              </div>
              <div class="first-block__tab3-tabs-arrows">
                <div
                  @click="
                    changeTabs3(
                      tab3ActiveTab > 0 ? tab3ActiveTab - 1 : tab3ActiveTab
                    )
                  "
                  class="
                    first-block__tab3-tabs-arrow
                    first-block__tab3-tabs-arrow_left
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.25 4.5L3.75 12L11.25 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                    <path
                      d="M5.25 12L20.25 12"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
                <div
                  @click="
                    changeTabs3(
                      tab3ActiveTab < 3 ? tab3ActiveTab + 1 : tab3ActiveTab
                    )
                  "
                  class="
                    first-block__tab3-tabs-arrow
                    first-block__tab3-tabs-arrow_right
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.75 4.5L20.25 12L12.75 19.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                    <path
                      d="M18.75 12L3.75 12"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="first-block__tab3-tabs-body">
              <div v-if="tab3ActiveTab === 0">
                <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="first-block__tab3-tab-img"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab1.jpg
                      `"
                    />
                  </div>
                </div>
                <div class="first-block__tab3-tab1-warning">
                  <div class="first-block__tab3-tab1-warning-icon">
                    <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`" />
                  </div>
                  <div class="first-block__tab3-tab1-warning-text">
                    При СД 1 типа, особенно у детей, ввиду гипоинсулинемии может
                    отмечаться быстрое развитие диабетического кетоацидоза
                    (ДКА).
                    <br />
                    <br />
                    ДКА является жизнеугрожающим состоянием и требует
                    незамедлительного лечения<sup>4</sup>.
                  </div>
                </div>
              </div>
              <div v-if="tab3ActiveTab === 1">
                <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="first-block__tab3-tab-img"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab2.jpg
                      `"
                    />
                  </div>
                </div>
              </div>
              <div v-if="tab3ActiveTab === 2">
                <div class="d-flex">
                  <div>
                    <div class="first-block__tab2-body-text">
                      <b
                        >Для дифференциальной диагностики СД 1 типа, СД 2 типа,
                        моногенных форм СД используют дополнительные методы
                        исследования<sup>4,5,13</sup>:</b
                      >
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4 mt-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Определение базального и стимулированного уровня
                        С-пептида
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Аутоантитела к инсулину (IAA)
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Аутоантитела к глютаматдекарбоксилазе (GAD)
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Аутоантитела к тирозинфосфатазе (IA- 2 и IA-2β)
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Аутоантитела к поверхностным антигенам (ICA)
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Аутоантитела к транспортеру цинка (ZnT-8)
                      </div>
                    </div>
                    <div class="first-block__tab2-body-text-check mb-4">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                      <div class="first-block__tab2-body-text">
                        Выполненение молекулярно-генетического исследования
                      </div>
                    </div>
                  </div>
                  <div class="ml-8 d-none d-xl-block">
                    <img
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3.png
                      `"
                    />
                  </div>
                </div>
                <div class="d-md-flex d-xl-block">
                  <div class="first-block__tab2-body-text">
                    <b
                      >В отличие от взрослых, СД 2 типа у детей выявляется
                      казуистически редко. В случае обнаружения неаутоиммунного
                      СД у ребенка целесообразно проведение генетического
                      обследования<sup>4,5</sup>.</b
                    >
                  </div>
                  <div
                    class="ml-8 ml-xs-0 mt-xs-8 d-xl-none"
                    style="flex-basis: 280px; flex-shrink: 0"
                  >
                    <img
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3.png
                      `"
                    />
                  </div>
                </div>
                <div class="first-block__tab3-tab3-block">
                  <div class="first-block__tab3-tab3-block-title">
                    Маркеры, указывающие на возможную генетическую природу СД в
                    детском возрасте<sup>13</sup>
                  </div>
                  <div class="first-block__tab3-tab3-block-list">
                    <div class="first-block__tab3-tab3-block-list-item">
                      <div class="first-block__tab3-tab3-block-list-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3-icon1.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab3-tab3-block-list-item-text">
                        Ранний возраст (до 6 месяцев жизни) дебюта заболевания
                      </div>
                    </div>
                    <div class="first-block__tab3-tab3-block-list-item">
                      <div class="first-block__tab3-tab3-block-list-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3-icon2.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab3-tab3-block-list-item-text">
                        Нетипичное течение СД или медленное прогрессирование
                        заболевания
                      </div>
                    </div>
                    <div class="first-block__tab3-tab3-block-list-item">
                      <div class="first-block__tab3-tab3-block-list-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3-icon3.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab3-tab3-block-list-item-text">
                        Наличие отягощенного семейного анамнеза
                      </div>
                    </div>
                    <div class="first-block__tab3-tab3-block-list-item">
                      <div class="first-block__tab3-tab3-block-list-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3-icon4.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab3-tab3-block-list-item-text">
                        Нормальный уровень С-пептида
                      </div>
                    </div>
                    <div class="first-block__tab3-tab3-block-list-item">
                      <div class="first-block__tab3-tab3-block-list-item-icon">
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab3-icon5.svg
                          `"
                        />
                      </div>
                      <div class="first-block__tab3-tab3-block-list-item-text">
                        Отсутствие специфических диабетических антител в дебюте
                        заболевания
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tab3ActiveTab === 3">
                <div class="first-block__tab2-body-title">
                  Возможные сложности диагностики и распространенные ошибки
                </div>
                <div class="first-block__tab2-body-text">
                  Лабораторная диагностика СД не представляет значительных
                  сложностей и не требует применения дорогостоящий
                  высокотехнологичных методов. Однако, во всем мире, в том числе
                  и в РФ верификация диагноза зачастую отсрочена, что может
                  привести к развитию острых осложнений, особенно в случае СД 1
                  типа<sup>4</sup>.
                </div>
                <div class="first-block__tab3-tab4-tabs">
                  <div
                    class="
                      first-block__tab3-tab4-tab first-block__tab3-tab4-tab_blue
                    "
                    @click="changeTabs4(0)"
                    :class="{
                      'first-block__tab3-tab4-tab_active': tab4ActiveTab === 0,
                    }"
                  >
                    В детском возрасте
                  </div>
                  <div
                    class="
                      first-block__tab3-tab4-tab
                      first-block__tab3-tab4-tab_green
                    "
                    @click="changeTabs4(1)"
                    :class="{
                      'first-block__tab3-tab4-tab_active': tab4ActiveTab === 1,
                    }"
                  >
                    Во взрослом возрасте
                  </div>
                </div>
                <div class="first-block__tab3-tab4-body">
                  <div v-if="tab4ActiveTab === 0">
                    <div
                      class="
                        first-block__tab3-tab4-dropdown
                        first-block__tab3-tab4-dropdown_blue
                      "
                    >
                      <input type="checkbox" id="dropdown_4_1_1" />
                      <label
                        for="dropdown_4_1_1"
                        class="first-block__tab3-tab4-dropdown-head"
                      >
                        <div class="first-block__tab3-tab4-dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M32.8459 11.3247H29.401L27.3926 6.22832C26.8291 4.79668 25.4157 3.83496 23.8736 3.83496H16.1264C14.5843 3.83496 13.1696 4.79668 12.6086 6.2271L10.5978 11.3247H7.15407C4.86029 11.3247 3 13.1837 3 15.4787V32.01C3 34.3038 4.86029 36.1641 7.15407 36.1641H32.8459C35.1397 36.1641 37 34.3038 37 32.01V15.4787C37 13.1837 35.1397 11.3247 32.8459 11.3247ZM27.0465 25.8099C27.0465 26.196 26.7332 26.5081 26.3483 26.5081H22.7649V30.0915C22.7649 30.4776 22.4529 30.7897 22.0667 30.7897H17.9333C17.5471 30.7897 17.2351 30.4764 17.2351 30.0915V26.5081H13.6517C13.2656 26.5081 12.9535 26.196 12.9535 25.8099V21.6765C12.9535 21.2903 13.2668 20.9782 13.6517 20.9782H17.2351V17.3949C17.2351 17.0087 17.5471 16.6967 17.9333 16.6967H22.0667C22.4529 16.6967 22.7649 17.01 22.7649 17.3949V20.9782H26.3483C26.7344 20.9782 27.0465 21.2903 27.0465 21.6765V25.8099ZM12.9474 11.3247L14.6414 7.02732C14.8781 6.42503 15.4756 6.02068 16.1264 6.02068H23.8736C24.5244 6.02068 25.1206 6.42503 25.3586 7.02853L27.0526 11.3247H12.9474Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-title">
                          Позднее обращение родителей за медицинской помощью
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                      <div class="first-block__tab3-tab4-dropdown-body">
                        <div class="d-md-flex">
                          <div class="first-block__tab2-body-text my-auto">
                            В некоторых случаях родители не обращаются за
                            медицинской помощью до появления тяжелых симптомов
                            (многократная рвота, летаргия и т.д.). При этом
                            такие жалобы как полиурия и полидипсия (особенно в
                            жаркое время года) могут быть ошибочно расценены как
                            физиологические. Резкое снижение массы тела,
                            особенно у ребенка с предшествующим ожирением, также
                            может не привлекать внимание взрослых. Таким
                            образом, повышение уровня образования,
                            настороженности и информированности населения (в
                            т.ч. сотрудников детских учреждений) о первых
                            симптомах СД у детей является значимой социальной
                            задачей<sup>4</sup>.
                          </div>
                          <div
                            class="ml-8 d-none d-xl-block"
                            style="flex-basis: 280px; flex-shrink: 0"
                          >
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-1.jpg
                              `"
                            />
                          </div>
                          <div
                            class="ml-8 ml-xs-0 mt-xs-8 d-xl-none"
                            style="flex-basis: 176px; flex-shrink: 0"
                          >
                            <img
                              style="
                                width: 100%;
                                height: 55%;
                                border-radius: 30px;
                                object-fit: cover;
                              "
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-1.jpg
                              `"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        first-block__tab3-tab4-dropdown
                        first-block__tab3-tab4-dropdown_blue
                      "
                    >
                      <input type="checkbox" id="dropdown_4_1_2" />
                      <label
                        for="dropdown_4_1_2"
                        class="first-block__tab3-tab4-dropdown-head"
                      >
                        <div class="first-block__tab3-tab4-dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4062 7.23926H19.5312C20.7394 7.23926 21.7188 6.25991 21.7188 5.05176V3.52051C21.7188 2.31235 20.7394 1.33301 19.5312 1.33301H13.4062C12.1981 1.33301 11.2188 2.31235 11.2188 3.52051V5.05176C11.2188 6.25991 12.1981 7.23926 13.4062 7.23926Z"
                              fill="currentColor"
                            />
                            <path
                              d="M26.5312 4.32227H23.9062V5.05143C23.9062 7.46352 21.9433 9.42643 19.5312 9.42643H13.4062C10.9942 9.42643 9.03125 7.46352 9.03125 5.05143V4.32227H6.40625C4.59719 4.32227 3.125 5.79445 3.125 7.60352V33.5618C3.125 35.3709 4.59719 36.8431 6.40625 36.8431H24.7674L24.7689 32.1429C24.4524 31.9766 24.1461 31.7922 23.8508 31.5931H6.55208C5.94833 31.5931 5.45833 31.1031 5.45833 30.4993C5.45833 29.8956 5.94833 29.4056 6.55208 29.4056H21.5299C20.9582 28.6567 20.493 27.8226 20.1554 26.9264H6.55208C5.94833 26.9264 5.45833 26.4364 5.45833 25.8327C5.45833 25.2289 5.94833 24.7389 6.55208 24.7389H19.6115C19.5057 23.9128 19.5035 23.0903 19.6085 22.2598H6.55208C5.94833 22.2598 5.45833 21.7698 5.45833 21.166C5.45833 20.5623 5.94833 20.0723 6.55208 20.0723H20.1503C21.6137 16.1734 25.455 13.5185 29.8125 13.7416V7.60352C29.8125 5.79445 28.3403 4.32227 26.5312 4.32227ZM10.6354 15.8431H9.97917V16.4993C9.97917 17.1031 9.48917 17.5931 8.88542 17.5931C8.28167 17.5931 7.79167 17.1031 7.79167 16.4993V15.8431H7.13542C6.53167 15.8431 6.04167 15.3531 6.04167 14.7493C6.04167 14.1456 6.53167 13.6556 7.13542 13.6556H7.79167V12.9993C7.79167 12.3956 8.28167 11.9056 8.88542 11.9056C9.48917 11.9056 9.97917 12.3956 9.97917 12.9993V13.6556H10.6354C11.2392 13.6556 11.7292 14.1456 11.7292 14.7493C11.7292 15.3531 11.2392 15.8431 10.6354 15.8431ZM20.5521 15.8431H15.3021C14.6983 15.8431 14.2083 15.3531 14.2083 14.7493C14.2083 14.1456 14.6983 13.6556 15.3021 13.6556H20.5521C21.1558 13.6556 21.6458 14.1456 21.6458 14.7493C21.6458 15.3531 21.1558 15.8431 20.5521 15.8431Z"
                              fill="currentColor"
                            />
                            <path
                              d="M26.957 32.9746V36.3332C26.957 37.6216 28.0019 38.6665 29.2904 38.6665C30.5795 38.6665 31.6237 37.6216 31.6237 36.3332V32.9783C30.1366 33.3431 28.5225 33.3614 26.957 32.9746Z"
                              fill="currentColor"
                            />
                            <path
                              d="M29.296 31.0706C33.4808 31.0706 36.8733 27.6781 36.8733 23.4933C36.8733 19.3085 33.4808 15.916 29.296 15.916C25.1112 15.916 21.7188 19.3085 21.7188 23.4933C21.7188 27.6781 25.1112 31.0706 29.296 31.0706Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-title">
                          Отсутствие «диабетической настороженности» у
                          педиатров, врачей СМП на этапе диагностики
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                      <div class="first-block__tab3-tab4-dropdown-body">
                        <div class="d-md-flex">
                          <div>
                            <div
                              class="first-block__tab2-body-text mb-6 mb-md-0"
                            >
                              Причинами отсроченной диагностики СД 1 типа у
                              детей являются относительная редкость данного
                              заболевания и необходимость проведения обширной
                              дифференциальной диагностики (например, с ОРВИ,
                              инфекцией мочевыводящих путей, вагинитом,
                              гастритом и т.д.)<sup>4</sup>. Повышение
                              информированности семейных врачей и педиатров о
                              ранней симптоматике СД имеет принципиальное
                              значение для предотвращения тяжелых осложнений.
                            </div>
                            <div class="d-none d-xl-flex">
                              <div
                                class="mr-6"
                                style="flex-basis: 100px; flex-shrink: 0"
                              >
                                <img
                                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`"
                                />
                              </div>
                              <div class="first-block__tab2-body-text">
                                <b style="color: #d0006f">
                                  Манифестация СД с развития диабетического
                                  кетоацидоза (ДКА) связана с несвоевременным
                                  обращением за медицинской помощью или неверной
                                  интерпретацией ранних жалоб пациента врачом.
                                  Особенно важно, что в детском возрасте
                                  развитие ДКА может быть стремительным!<sup
                                    >4</sup
                                  >
                                </b>
                              </div>
                            </div>
                          </div>
                          <div
                            class="ml-8 d-none d-xl-block"
                            style="flex-basis: 340px; flex-shrink: 0"
                          >
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-2.jpg
                              `"
                            />
                          </div>
                          <div
                            class="ml-8 ml-xs-0 mt-xs-8 d-xl-none"
                            style="flex-basis: 176px; flex-shrink: 0"
                          >
                            <img
                              style="
                                width: 100%;
                                height: 100%;
                                border-radius: 30px;
                                object-fit: cover;
                              "
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-2.jpg
                              `"
                            />
                          </div>
                        </div>
                        <div class="d-xl-none d-md-flex mt-6">
                          <div
                            class="mr-6 d-none d-md-block"
                            style="flex-basis: 100px; flex-shrink: 0"
                          >
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`"
                            />
                          </div>
                          <div class="mb-2 d-md-none" style="width: 50px">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`"
                            />
                          </div>
                          <div class="first-block__tab2-body-text">
                            <b style="color: #d0006f">
                              Манифестация СД с развития диабетического
                              кетоацидоза (ДКА) связана с несвоевременным
                              обращением за медицинской помощью или неверной
                              интерпретацией ранних жалоб пациента врачом.
                              Особенно важно, что в детском возрасте развитие
                              ДКА может быть стремительным!<sup>4</sup>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        first-block__tab3-tab4-dropdown
                        first-block__tab3-tab4-dropdown_blue
                      "
                    >
                      <input type="checkbox" id="dropdown_4_1_3" />
                      <label
                        for="dropdown_4_1_3"
                        class="first-block__tab3-tab4-dropdown-head"
                      >
                        <div class="first-block__tab3-tab4-dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M36.5974 28.85C35.8071 27.2 34.8587 25.1 33.7521 23.15C32.6458 21.05 31.5392 19.1 30.5908 17.3C28.6939 14 25.6906 9.8 23.4775 6.8C22.6872 5.75 21.4225 5 20 5C18.5774 5 17.3128 5.6 16.5224 6.8C14.3094 9.8 11.4641 14 9.40922 17.3C7.35429 20.75 4.98322 25.55 3.40251 28.85C2.77023 30.05 2.9283 31.55 3.56059 32.6C4.35094 33.8 5.61552 34.4 7.03814 34.55C10.8319 34.7 16.0482 35 20 35C23.9518 35 29.3262 34.7 32.9618 34.55C34.3845 34.55 35.649 33.8 36.4393 32.6C37.0717 31.55 37.2298 30.05 36.5974 28.85ZM18.7354 17.45C18.7354 16.85 19.2097 16.25 20 16.25C20.7903 16.25 21.2646 16.7 21.2646 17.45V23.45C21.2646 24.05 20.7903 24.65 20 24.65C19.2097 24.65 18.7354 24.2 18.7354 23.45V17.45ZM20 29.45C19.2097 29.45 18.4193 28.7 18.4193 27.95C18.4193 27.2 19.2097 26.45 20 26.45C20.7903 26.45 21.5806 27.2 21.5806 27.95C21.5806 28.7 20.7903 29.45 20 29.45Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-title">
                          Ошибки при интерпретации симптомов и критериев
                          диагностики СД
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                      <div class="first-block__tab3-tab4-dropdown-body">
                        <div class="first-block__tab2-body-text">
                          По данным ретроспективных исследований ~10-15% детям в
                          дебюте диабета устанавливается неверный диагноз, что
                          приводит к неадекватной терапии и развитию
                          ДКА<sup>10,11</sup>.
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        first-block__tab3-tab4-dropdown
                        first-block__tab3-tab4-dropdown_blue
                      "
                    >
                      <input type="checkbox" id="dropdown_4_1_4" />
                      <label
                        for="dropdown_4_1_4"
                        class="first-block__tab3-tab4-dropdown-head"
                      >
                        <div class="first-block__tab3-tab4-dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M30.7384 5.17858C29.7643 4.31993 28.9243 3.5768 28.9243 2.45274C28.9193 2.20483 28.7195 2.005 28.4716 2C27.8128 2 23.9473 6.14027 23.9473 8.94729C23.9463 11.4529 25.9767 13.4849 28.4824 13.4858C30.988 13.4868 33.02 11.4563 33.0209 8.95072C33.0209 8.94955 33.0209 8.94838 33.0209 8.94729C33.0209 7.19876 31.8094 6.12466 30.7384 5.17858Z"
                              fill="currentColor"
                            />
                            <path
                              d="M24.7698 16.0413C22.0065 13.5996 19.3806 11.2953 19.3806 7.77642C19.384 7.51781 19.1772 7.30525 18.9186 7.30182C18.9143 7.30174 18.9102 7.30174 18.906 7.30182C17.8132 7.30182 6.97852 18.4019 6.97852 26.0361C6.97734 32.6424 12.3319 37.9988 18.9382 38C25.5446 38.0012 30.901 32.6466 30.9022 26.0403C30.9022 26.0389 30.9022 26.0375 30.9022 26.0361C30.9146 21.4649 27.7923 18.7079 24.7698 16.0413ZM18.9528 34.557C18.7088 34.557 18.511 34.3592 18.511 34.1152C18.511 33.8712 18.7088 33.6734 18.9528 33.6734C22.794 33.67 25.9075 30.5579 25.9126 26.7168C25.9126 26.4727 26.1104 26.2749 26.3544 26.2749C26.5984 26.2749 26.7962 26.4727 26.7962 26.7168C26.7911 31.0459 23.282 34.5536 18.9528 34.557Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-title">
                          Отсутствие «диабетической настороженности» у
                          педиатров, врачей СМП на этапе диагностики
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                      <div class="first-block__tab3-tab4-dropdown-body">
                        <div class="d-md-flex">
                          <div>
                            <div
                              class="
                                first-block__tab2-body-text
                                mb-6
                                d-none d-xl-block
                              "
                            >
                              Помимо своевременной диагностики, некоторые
                              сложности могут возникать при правильном
                              определении типа СД, что сказывается на подборе
                              рациональной терапии. По данным литературы более
                              чем 6% подростков и молодым людям неверно
                              устанавливается тип СД в дебюте
                              заболевания<sup>12</sup>.
                              <br />
                              <br />
                              В детском возрасте важно своевременно провести
                              дифференциальную диагностику между сахарного
                              диабета 1 типа (всегда инсулинопотребный) от
                              редких моногенных форм (MODY, неонатальный
                              диабет)<sup>13</sup>.
                              <br />
                              <br />
                              При многогенных формах СД лечение и определение
                              прогноза <b>принципиально</b> отличается от
                              тактики при СД 1 типа: во многих случаях
                              моногенные формы успешно поддаются терапии
                              сахароснижающими препаратами и не требуют
                              назначения инсулина<sup>13</sup>. Кроме того,
                              моногенные формы определяют необходимость
                              семейного консультирования. Широкое
                              распространение методов молекулярно-генетической
                              диагностики позволяет достаточно быстро и уверенно
                              диагностировать моногенные типы СД<sup>4,13</sup>.
                            </div>
                            <div class="first-block__tab2-body-text d-xl-none">
                              Помимо своевременной диагностики, некоторые
                              сложности могут возникать при правильном
                              определении типа СД, что сказывается на подборе
                              рациональной терапии. По данным литературы более
                              чем 6% подростков и молодым людям неверно
                              устанавливается тип СД в дебюте
                              заболевания<sup>12</sup>.
                            </div>
                          </div>
                          <div
                            class="ml-8 d-none d-xl-block"
                            style="flex-basis: 340px; flex-shrink: 0"
                          >
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-3.jpg
                              `"
                            />
                          </div>
                          <div
                            class="ml-8 ml-xs-0 mt-xs-8 d-xl-none"
                            style="flex-basis: 176px; flex-shrink: 0"
                          >
                            <img
                              style="
                                width: 100%;
                                height: 100%;
                                border-radius: 30px;
                                object-fit: cover;
                              "
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-3.jpg
                              `"
                            />
                          </div>
                        </div>
                        <div
                          class="
                            first-block__tab2-body-text
                            mt-6
                            mb-6
                            d-xl-none
                          "
                        >
                          В детском возрасте важно своевременно провести
                          дифференциальную диагностику между сахарного диабета 1
                          типа (всегда инсулинопотребный) от редких моногенных
                          форм (MODY, неонатальный диабет)<sup>13</sup>.
                          <br />
                          <br />
                          При многогенных формах СД лечение и определение
                          прогноза <b>принципиально</b> отличается от тактики
                          при СД 1 типа: во многих случаях моногенные формы
                          успешно поддаются терапии сахароснижающими препаратами
                          и не требуют назначения инсулина<sup>13</sup>. Кроме
                          того, моногенные формы определяют необходимость
                          семейного консультирования. Широкое распространение
                          методов молекулярно-генетической диагностики позволяет
                          достаточно быстро и уверенно диагностировать
                          моногенные типы СД<sup>4,13</sup>.
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        first-block__tab3-tab4-dropdown
                        first-block__tab3-tab4-dropdown_blue
                      "
                    >
                      <div class="first-block__tab3-tab4-dropdown-head">
                        <div class="first-block__tab3-tab4-dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_357_14817)">
                              <path
                                d="M25.2677 13.624H14.7327C12.3586 13.624 10.4277 15.5549 10.4277 17.929V29.6949C10.4277 32.069 12.3586 33.9999 14.7327 33.9999H25.2677C27.6477 33.9999 29.5727 32.069 29.5727 29.6949V17.929C29.5727 15.5549 27.6477 13.624 25.2677 13.624ZM22.1819 16.4824C22.1819 16.179 22.4211 15.9399 22.7186 15.9399H24.9236C25.2211 15.9399 25.4602 16.179 25.4602 16.4824V17.999C25.4602 18.2965 25.2211 18.5415 24.9236 18.5415H22.7186C22.4211 18.5415 22.1819 18.2965 22.1819 17.999V16.4824ZM14.5402 16.4824C14.5402 16.179 14.7794 15.9399 15.0827 15.9399H17.2819C17.5794 15.9399 17.8244 16.179 17.8244 16.4824V17.999C17.8244 18.2965 17.5794 18.5415 17.2819 18.5415H15.0827C14.7794 18.5415 14.5402 18.2965 14.5402 17.999L14.5402 16.4824ZM26.3936 29.7298C26.3936 30.5874 25.6994 31.2815 24.8419 31.2815H15.1644C14.301 31.2815 13.6069 30.5874 13.6069 29.7298V22.1582C13.6069 21.3007 14.301 20.6007 15.1644 20.6007H24.8419C25.6994 20.6007 26.3936 21.3007 26.3936 22.1582V29.7298Z"
                                fill="#58C5D2"
                              />
                              <path
                                d="M24.8424 21.4761H15.1649C14.7916 21.4761 14.4824 21.7852 14.4824 22.1586V29.7302C14.4824 30.1036 14.7916 30.4069 15.1649 30.4069H24.8424C25.2157 30.4069 25.5191 30.1036 25.5191 29.7302V22.1586C25.5191 21.7852 25.2157 21.4761 24.8424 21.4761ZM20.0007 28.8708C18.7866 28.8708 17.8005 27.89 17.8005 26.6759C17.8005 25.8541 18.8025 24.2635 19.4494 23.3039C19.7198 22.9169 20.2923 22.9169 20.5521 23.3039C21.2096 24.2635 22.201 25.8541 22.201 26.6759C22.201 27.89 21.2149 28.8708 20.0007 28.8708Z"
                                fill="#58C5D2"
                              />
                              <path
                                d="M20.0009 6C18.6651 6 17.5801 7.08503 17.5801 8.42085V12.7492H22.4276V8.42085C22.4276 7.09954 21.3469 6 20.0009 6ZM20.0009 9.80918C19.4117 9.80918 18.9334 9.33084 18.9334 8.74167C18.9334 8.1525 19.4117 7.66836 20.0009 7.66836C20.5959 7.66836 21.0742 8.1525 21.0742 8.74167C21.0742 9.33084 20.5959 9.80918 20.0009 9.80918Z"
                                fill="#58C5D2"
                              />
                              <path
                                d="M20 0C8.95447 0 0 8.95447 0 20C0 31.0455 8.95447 40 20 40C31.0455 40 40 31.0455 40 20C40 8.95447 31.0455 0 20 0ZM2.41455 20C2.41455 15.303 4.24377 10.8868 7.56531 7.56531C10.8868 4.24377 15.303 2.41455 20 2.41455C24.2923 2.41455 28.3493 3.94287 31.5515 6.74103L6.74103 31.5515C3.94318 28.3493 2.41455 24.2923 2.41455 20ZM32.4347 32.4347C29.1132 35.7562 24.697 37.5854 20 37.5854C15.7077 37.5854 11.6507 36.0571 8.44849 33.259L33.259 8.44849C36.0568 11.6507 37.5854 15.7077 37.5854 20C37.5854 24.697 35.7562 29.1132 32.4347 32.4347Z"
                                fill="#58C5D2"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_357_14817">
                                <rect width="40" height="40" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="first-block__tab3-tab4-dropdown-head-title">
                          Отсутствие необходимого лабораторного оснащения
                          (глюкометры), отсроченное получение результатов
                          анализов, лабораторные ошибки
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="tab4ActiveTab === 1">
                    <div class="d-xl-flex">
                      <div style="flex-basis: 100%">
                        <div
                          class="
                            first-block__tab3-tab4-dropdown
                            first-block__tab3-tab4-dropdown_green
                          "
                        >
                          <input type="checkbox" id="dropdown_4_2_1" />
                          <label
                            for="dropdown_4_2_1"
                            class="first-block__tab3-tab4-dropdown-head"
                          >
                            <div
                              class="first-block__tab3-tab4-dropdown-head-icon"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M31.5964 11.4147C31.5964 9.60539 30.6735 8.00253 29.2772 7.0675C28.4393 6.49683 27.4314 6.16895 26.3507 6.16895H25.0514V9.36252C25.0514 10.0304 24.5171 10.5768 23.8371 10.5768C23.1693 10.5768 22.6228 10.0304 22.6228 9.36252V6.16895H11.9736V9.36252C11.9736 10.0304 11.4271 10.5768 10.7593 10.5768C10.0793 10.5768 9.54502 10.0304 9.54502 9.36252V6.16895H8.24572C6.80072 6.16895 5.48927 6.75185 4.54217 7.71111C3.58283 8.65822 3 9.96967 3 11.4147V12.2161H31.5964V11.4147Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.24572 32.798H19.1621C18.2393 31.2073 17.705 29.3495 17.705 27.3702C17.705 21.3716 22.5743 16.5023 28.5607 16.5023C29.6171 16.5023 30.6372 16.6481 31.5964 16.9273V14.6445H3V27.5645C3 30.4545 5.35572 32.798 8.24572 32.798Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11.9735 5.41643C11.9735 4.74859 11.427 4.20215 10.7592 4.20215C10.0792 4.20215 9.54492 4.74859 9.54492 5.41643V6.16929H11.9735V5.41643Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M25.0516 5.41643C25.0516 4.74859 24.5173 4.20215 23.8373 4.20215C23.1695 4.20215 22.623 4.74859 22.623 5.41643V6.16929H25.0516V5.41643Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M28.56 18.9316C23.9092 18.9316 20.1328 22.7202 20.1328 27.3709C20.1328 32.0217 23.9092 35.7981 28.56 35.7981C33.2107 35.7981 36.9993 32.0217 36.9993 27.3709C36.9993 22.7202 33.2107 18.9316 28.56 18.9316ZM30.9521 28.5852H28.56C27.8921 28.5852 27.3457 28.0388 27.3457 27.3709V24.8695C27.3457 24.2017 27.8921 23.6552 28.56 23.6552C29.24 23.6552 29.7742 24.2017 29.7742 24.8695V26.1567H30.9521C31.62 26.1567 32.1664 26.6909 32.1664 27.3709C32.1664 28.0388 31.62 28.5852 30.9521 28.5852Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-title"
                            >
                              Отсрочка постановки диагноза
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-arrow"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </label>
                          <div class="first-block__tab3-tab4-dropdown-body">
                            <div class="first-block__tab2-body-text">
                              По данным опубликованных исследований, до 40%
                              случаев СД были верифицированы с
                              отсрочкой<sup>14</sup>. Установлено, что
                              постановка диагноза СД 2 типа у взрослых пациентов
                              может отставать в среднем на 4–7 лет от впервые
                              выявленной гипергликемии<sup>15,16</sup>.
                              <br />
                              <br />
                              Несмотря на то, что в этот период, как правило,
                              пациенты не испытывают особых жалоб, данное время
                              может и должно быть использовано для профилактики
                              осложнений, модификации образа жизни и наиболее
                              раннего начала терапии<sup>1</sup>. Проспективное
                              исследование (UKPDS) продемонстрировало, что
                              ранний контроль показателей гликемии приводит к
                              существенно более низкому риску микрососудистых
                              осложнений и развития инфаркта миокарда, причем
                              данная тенденция сохраняется в течение десятилетий
                              после постановки диагноза СД по сравнению с
                              людьми, которым в доклинической стадии заболевания
                              не проводился контроль гликемического
                              профиля<sup>17</sup>.
                              <br />
                              <br />
                              <b
                                >Причинами поздней диагностики, как правило,
                                становятся<sup>14-17</sup>:
                              </b>

                              <div
                                class="
                                  first-block__tab2-body-text-check
                                  mb-4
                                  mt-4
                                "
                              >
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg
                                  `"
                                />
                                <div class="first-block__tab2-body-text">
                                  Несвоевременное (позднее) обращение к врачу
                                </div>
                              </div>
                              <div
                                class="
                                  first-block__tab2-body-text-check
                                  mb-4
                                  mt-4
                                "
                              >
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg
                                  `"
                                />
                                <div class="first-block__tab2-body-text">
                                  Отсутствие регулярных диспансерных осмотров
                                </div>
                              </div>
                              <div
                                class="
                                  first-block__tab2-body-text-check
                                  mb-4
                                  mt-4
                                "
                              >
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg
                                  `"
                                />
                                <div class="first-block__tab2-body-text">
                                  Неправильная интерпретация результатов
                                  обследования
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            first-block__tab3-tab4-dropdown
                            first-block__tab3-tab4-dropdown_green
                          "
                        >
                          <input type="checkbox" id="dropdown_4_2_2" />
                          <label
                            for="dropdown_4_2_2"
                            class="first-block__tab3-tab4-dropdown-head"
                          >
                            <div
                              class="first-block__tab3-tab4-dropdown-head-icon"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.4062 7.23926H19.5312C20.7394 7.23926 21.7188 6.25991 21.7188 5.05176V3.52051C21.7188 2.31235 20.7394 1.33301 19.5312 1.33301H13.4062C12.1981 1.33301 11.2188 2.31235 11.2188 3.52051V5.05176C11.2188 6.25991 12.1981 7.23926 13.4062 7.23926Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M26.5312 4.32227H23.9062V5.05143C23.9062 7.46352 21.9433 9.42643 19.5312 9.42643H13.4062C10.9942 9.42643 9.03125 7.46352 9.03125 5.05143V4.32227H6.40625C4.59719 4.32227 3.125 5.79445 3.125 7.60352V33.5618C3.125 35.3709 4.59719 36.8431 6.40625 36.8431H24.7674L24.7689 32.1429C24.4524 31.9766 24.1461 31.7922 23.8508 31.5931H6.55208C5.94833 31.5931 5.45833 31.1031 5.45833 30.4993C5.45833 29.8956 5.94833 29.4056 6.55208 29.4056H21.5299C20.9582 28.6567 20.493 27.8226 20.1554 26.9264H6.55208C5.94833 26.9264 5.45833 26.4364 5.45833 25.8327C5.45833 25.2289 5.94833 24.7389 6.55208 24.7389H19.6115C19.5057 23.9128 19.5035 23.0903 19.6085 22.2598H6.55208C5.94833 22.2598 5.45833 21.7698 5.45833 21.166C5.45833 20.5623 5.94833 20.0723 6.55208 20.0723H20.1503C21.6137 16.1734 25.455 13.5185 29.8125 13.7416V7.60352C29.8125 5.79445 28.3403 4.32227 26.5312 4.32227ZM10.6354 15.8431H9.97917V16.4993C9.97917 17.1031 9.48917 17.5931 8.88542 17.5931C8.28167 17.5931 7.79167 17.1031 7.79167 16.4993V15.8431H7.13542C6.53167 15.8431 6.04167 15.3531 6.04167 14.7493C6.04167 14.1456 6.53167 13.6556 7.13542 13.6556H7.79167V12.9993C7.79167 12.3956 8.28167 11.9056 8.88542 11.9056C9.48917 11.9056 9.97917 12.3956 9.97917 12.9993V13.6556H10.6354C11.2392 13.6556 11.7292 14.1456 11.7292 14.7493C11.7292 15.3531 11.2392 15.8431 10.6354 15.8431ZM20.5521 15.8431H15.3021C14.6983 15.8431 14.2083 15.3531 14.2083 14.7493C14.2083 14.1456 14.6983 13.6556 15.3021 13.6556H20.5521C21.1558 13.6556 21.6458 14.1456 21.6458 14.7493C21.6458 15.3531 21.1558 15.8431 20.5521 15.8431Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M26.957 32.9746V36.3332C26.957 37.6216 28.0019 38.6665 29.2904 38.6665C30.5795 38.6665 31.6237 37.6216 31.6237 36.3332V32.9783C30.1366 33.3431 28.5225 33.3614 26.957 32.9746Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M29.296 31.0706C33.4808 31.0706 36.8733 27.6781 36.8733 23.4933C36.8733 19.3085 33.4808 15.916 29.296 15.916C25.1112 15.916 21.7188 19.3085 21.7188 23.4933C21.7188 27.6781 25.1112 31.0706 29.296 31.0706Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-title"
                            >
                              Ошибки в дифференциальной диагностике типа диабета
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-arrow"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </label>
                          <div class="first-block__tab3-tab4-dropdown-body">
                            <div class="first-block__tab2-body-text">
                              У взрослых пациентов широкое распространение СД 2
                              типа требует, как правило, во всех впервые
                              выявленных случаях болезни исключить или
                              подтвердить именно этот тип
                              заболевания<sup>1,7</sup>.
                              <br />
                              <br />
                              Среди 4-14% пациентов с клинической картиной СД 2
                              типа может быть выявлен положительный титр
                              специфических антител, говорящий об
                              <b>аутоиммунной</b> природе
                              заболевания<sup>1,7</sup>. Данный вариант течения
                              СД получил название
                              <b
                                >LADA (латентный аутоиммунный диабет
                                взрослых)</b
                              >, в новой классификации он заменен на «медленно
                              развивающийся иммуноопосредованный диабет
                              взрослых»<sup>18</sup>. ВОЗ определяет LADA как
                              латентную форму СД 1 типа, что, соответственно,
                              определяет отличный от 2 типа СД подход к
                              терапии<sup>18</sup>. Кроме того, манифестация
                              некоторых моногенных форм СД может приходиться на
                              взрослый возраст или, например, маскировать
                              гестационный СД<sup>1-9,13</sup>.
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            first-block__tab3-tab4-dropdown
                            first-block__tab3-tab4-dropdown_green
                          "
                        >
                          <input type="checkbox" id="dropdown_4_2_3" />
                          <label
                            for="dropdown_4_2_3"
                            class="first-block__tab3-tab4-dropdown-head"
                          >
                            <div
                              class="first-block__tab3-tab4-dropdown-head-icon"
                            >
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M31.0959 28.9629C29.0267 28.9629 27.3164 27.2527 27.3164 25.1835V18.1101H34.8753V25.1835C34.8753 27.2527 33.1862 28.9629 31.0959 28.9629ZM27.3164 17.1178V12.2192H34.8753V17.1178H27.3164Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M35.3813 11.2481H26.9778C26.7033 11.2481 26.4922 11.0159 26.4922 10.7414V6.75077C26.4922 6.47628 26.7033 6.26514 26.9778 6.26514H35.3813C35.6558 6.26514 35.867 6.47628 35.867 6.75077V10.7414C35.867 11.0159 35.6558 11.2481 35.3813 11.2481Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.92596 28.9629C6.83563 28.9629 5.14648 27.2527 5.14648 25.1835V18.1101H12.6843V25.1835C12.6843 27.2527 10.9952 28.9629 8.92596 28.9629ZM5.14648 17.1178V12.2192H12.6843V17.1178H5.14648Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.1899 11.2481H4.80753C4.53304 11.2481 4.30078 11.0159 4.30078 10.7414V6.75077C4.30078 6.47628 4.53304 6.26514 4.80753 6.26514H13.1899C13.4644 6.26514 13.6967 6.47628 13.6967 6.75077V10.7414C13.6967 11.0159 13.4644 11.2481 13.1899 11.2481Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M20.0099 28.9629C17.9196 28.9629 16.2305 27.2527 16.2305 25.1835V18.1101H23.7894V25.1835C23.7894 27.2527 22.1003 28.9629 20.0099 28.9629ZM16.2305 17.1178V12.2192H23.7894V17.1178H16.2305Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M24.2974 11.2481H15.8938C15.6193 11.2481 15.4082 11.0159 15.4082 10.7414V6.75077C15.4082 6.47628 15.6193 6.26514 15.8938 6.26514H24.2974C24.5718 6.26514 24.783 6.47628 24.783 6.75077V10.7414C24.783 11.0159 24.5718 11.2481 24.2974 11.2481Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M37.5144 33.735H2.48563C2.21114 33.735 2 33.5027 2 33.2282V15.7244C2 15.4499 2.21114 15.2388 2.48563 15.2388H5.63167C5.90616 15.2388 6.13842 15.4499 6.13842 15.7244C6.13842 15.9989 5.90616 16.21 5.63167 16.21H2.97126V32.7426H37.0076V16.21H34.3894C34.1149 16.21 33.8827 15.9989 33.8827 15.7244C33.8827 15.4499 34.1149 15.2388 34.3894 15.2388H37.5144C37.7677 15.2388 38 15.4499 38 15.7244V33.2282C38 33.5027 37.7677 33.735 37.5144 33.735ZM27.8229 16.21H23.2833C23.0299 16.21 22.7976 15.9989 22.7976 15.7244C22.7976 15.4499 23.0299 15.2388 23.2833 15.2388H27.8229C28.0974 15.2388 28.3085 15.4499 28.3085 15.7244C28.3085 15.9989 28.0974 16.21 27.8229 16.21ZM16.7378 16.21H12.1982C11.9238 16.21 11.7126 15.9989 11.7126 15.7244C11.7126 15.4499 11.9238 15.2388 12.1982 15.2388H16.7378C16.9912 15.2388 17.2235 15.4499 17.2235 15.7244C17.2235 15.9989 16.9912 16.21 16.7378 16.21Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-title"
                            >
                              Ошибки при проведении лабораторного исследования
                            </div>
                            <div
                              class="first-block__tab3-tab4-dropdown-head-arrow"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </label>
                          <div class="first-block__tab3-tab4-dropdown-body">
                            <div class="first-block__tab2-body-text">
                              Гипердиагностика СД встречается реже, однако, для
                              адекватной интерпретации лабораторных показателей
                              необходимо соблюдать правила проведения
                              обследования. Так, например, при первичной
                              диагностике СД, целесообразно измерение уровня
                              гликемии в венозной плазме на базе клинической
                              лаборатории, а не использование портативного
                              глюкометра. Кроме того, если речь идет об
                              относительной гипергликемии, необходимо убедиться,
                              что исследование было проведено натощак и
                              повторить его<sup>1,7,9</sup>.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ml-8 ml-xs-0 mt-md-8">
                        <img
                          class="ml-md-auto ml-xs-0 d-none d-md-block"
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-4.jpg
                          `"
                        />
                        <img
                          class="ml-md-auto ml-xs-0 d-md-none"
                          style="width: 100%"
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/first-block__tab3-tab4-4.jpg
                          `"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="second-block">
      <div class="content">
        <div class="second-block__row">
          <div class="second-block__img">
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block_mob.png`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block_tab.png`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block.png`"
                media="(min-width: 1220px)"
              />
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block.png`" />
            </picture>
          </div>
          <div class="second-block__text">
            <div class="second-block__title">
              Мониторинг при сахарном диабете
            </div>
            <div class="second-block__text">
              Регулярное измерение уровня глюкозы важно для коррекции лечения и
              достижения компенсации сахарного диабета<sup>1</sup>. Снижение
              <b style="color: #d0006f">вариабельности гликемии </b>
              <span class="second-block__text-tip">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_104_4247)">
                    <path
                      d="M10.9995 0C4.92521 0 0 4.92521 0 10.9995C0 17.0739 4.92521 22 10.9995 22C17.0739 22 22 17.0739 22 10.9995C22 4.92521 17.0739 0 10.9995 0ZM13.2894 17.0478C12.7232 17.2713 12.2725 17.4408 11.9345 17.5581C11.5974 17.6754 11.2053 17.7341 10.7593 17.7341C10.0739 17.7341 9.54032 17.5665 9.16038 17.2322C8.78044 16.8979 8.59141 16.4742 8.59141 15.9592C8.59141 15.759 8.60538 15.5541 8.63331 15.3455C8.66218 15.1369 8.70781 14.9023 8.7702 14.6387L9.47886 12.1356C9.54125 11.8954 9.59526 11.6672 9.6381 11.4549C9.68093 11.2407 9.70142 11.0442 9.70142 10.8654C9.70142 10.547 9.6353 10.3235 9.504 10.1978C9.37084 10.072 9.12034 10.0106 8.74692 10.0106C8.5644 10.0106 8.3763 10.0376 8.18353 10.0944C7.99264 10.1531 7.82688 10.2061 7.69092 10.2583L7.8781 9.48724C8.34184 9.2982 8.78603 9.13617 9.20974 9.00207C9.63344 8.86612 10.0339 8.79907 10.411 8.79907C11.0917 8.79907 11.6169 8.96483 11.9866 9.29261C12.3545 9.62133 12.5398 10.0488 12.5398 10.574C12.5398 10.6829 12.5267 10.8748 12.5016 11.1485C12.4764 11.4232 12.429 11.6737 12.36 11.9037L11.6551 14.3994C11.5974 14.5996 11.5462 14.8287 11.4996 15.0848C11.454 15.3409 11.4316 15.5364 11.4316 15.6677C11.4316 15.9992 11.5052 16.2255 11.6542 16.3457C11.8013 16.4658 12.0593 16.5263 12.4243 16.5263C12.5966 16.5263 12.7893 16.4956 13.0072 16.436C13.2233 16.3764 13.3797 16.3233 13.4784 16.2777L13.2894 17.0478ZM13.1646 6.91801C12.8359 7.22345 12.4401 7.37617 11.9773 7.37617C11.5154 7.37617 11.1169 7.22345 10.7854 6.91801C10.4557 6.61257 10.289 6.24102 10.289 5.80707C10.289 5.37405 10.4566 5.00157 10.7854 4.69333C11.1169 4.38417 11.5154 4.23052 11.9773 4.23052C12.4401 4.23052 12.8368 4.38417 13.1646 4.69333C13.4933 5.00157 13.6582 5.37405 13.6582 5.80707C13.6582 6.24195 13.4933 6.61257 13.1646 6.91801Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_104_4247">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div class="second-block__text-tip-content">
                  <b>Вариабельность гликемии</b> – показатель частоты,
                  продолжительности и амплитуды изменений уровня глюкозы в крови
                  за определенный период времени<sup>4</sup>
                </div>
              </span>
              является ключевой целью терапии, сопровождающейся снижением риска
              развития гипогликемии и долгосрочных, в т.ч. сердечно-сосудистых,
              осложнений СД<sup>28-30</sup>.
            </div>
            <div class="second-block__text mt-10 mt-md-5">
              <b
                ><span style="color: #d0006f">Неконтролируемая гликемия</span>
                (гипер- и гипогликемические состояния) является независимым
                фактором риска общей и сердечно-сосудистой смертности:</b
              >
            </div>
            <div class="second-block__line d-none d-xl-block">
              <div class="second-block__line-item">
                <div class="second-block__line-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon1.svg`"
                    />
                  </span>
                </div>
                <div class="second-block__line-item-text">
                  <div class="second-block__line-item-text">
                    У лиц с СД со средним уровнем глюкозы выше 7,8 ммоль/л на
                    50% выше риск смерти, чем у тех, у кого уровень средней
                    глюкозы был ниже этого значения<sup>19</sup>.
                  </div>
                </div>
              </div>
              <div class="second-block__line-item">
                <div class="second-block__line-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon2.svg`"
                    />
                  </span>
                </div>
                <div class="second-block__line-item-text">
                  <div class="second-block__line-item-text">
                    Среди пациентов с СД и эпизодами тяжелой гипогликемии
                    отмечается рост смертности до 3,4 раз чаще по сравнению с
                    пациентами с СД без эпизодов тяжелой
                    гипогликемии<sup>21-23</sup>.
                  </div>
                </div>
              </div>
              <div class="second-block__line-item">
                <div class="second-block__line-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon3.svg`"
                    />
                  </span>
                </div>
                <div class="second-block__line-item-text">
                  <div class="second-block__line-item-text">
                    60 лет и более – средняя продолжительность жизни заболевших
                    сахарным диабетом 1 типа в детстве при компенсированном и
                    контролируемом течении<sup>20</sup>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="second-block__line d-xl-none">
          <div class="second-block__line-item">
            <div class="second-block__line-item-index">
              <span>
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon1.svg`"
                />
              </span>
            </div>
            <div class="second-block__line-item-text">
              <div class="second-block__line-item-text">
                У лиц с СД со средним уровнем глюкозы выше 7,8 ммоль/л на 50%
                выше риск смерти, чем у тех, у кого уровень средней глюкозы был
                ниже этого значения<sup>19</sup>.
              </div>
            </div>
          </div>
          <div class="second-block__line-item">
            <div class="second-block__line-item-index">
              <span>
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon2.svg`"
                />
              </span>
            </div>
            <div class="second-block__line-item-text">
              <div class="second-block__line-item-text">
                Среди пациентов с СД и эпизодами тяжелой гипогликемии отмечается
                рост смертности до 3,4 раз чаще по сравнению с пациентами с СД
                без эпизодов тяжелой гипогликемии<sup>21-23</sup>.
              </div>
            </div>
          </div>
          <div class="second-block__line-item">
            <div class="second-block__line-item-index">
              <span>
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__icon3.svg`"
                />
              </span>
            </div>
            <div class="second-block__line-item-text">
              <div class="second-block__line-item-text">
                60 лет и более – средняя продолжительность жизни заболевших
                сахарным диабетом 1 типа в детстве при компенсированном и
                контролируемом течении<sup>20</sup>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="second-block pt-0 tab-pinned" id="hba1c">
      <div class="content">
        <div class="second-block__subtitle">
          Варианты мониторинга и контроля состояния пациента с сахарным диабетом
          должны сочетаться в зависимости от потребностей пациента и не
          исключают друг друга<sup>1,3-8</sup>
        </div>
        <div class="tap-hint mb-4 mt-4">
          <div class="tap-hint__icon ml-auto mr-2">
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tap.svg`" />
          </div>
          <div class="tap-hint__text">Выберите вариант мониторинга</div>
        </div>
      </div>
      <div class="fixed-tabs sticky">
        <div class="content">
          <div class="second-block__tabs">
            <div
              class="second-block__arrow second-block__arrow_left"
              @click="
                choiceSecondTab(
                  secondTabsActive > 0 ? secondTabsActive - 1 : secondTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="second-block__tabs-row">
              <div
                class="second-block__tab"
                @click="choiceSecondTab(0)"
                :class="{ 'second-block__tab_active': secondTabsActive === 0 }"
              >
                <img
                  class="second-block__tab-back"
                  v-if="secondTabsActive === 0"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="second-block__tab-icon">
                  <img
                    v-if="secondTabsActive === 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-1_active.svg`"
                  />
                  <img
                    v-if="secondTabsActive !== 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-1.svg`"
                  />
                </div>
                <div class="second-block__tab-text">
                  Непрерывный мониторинг гликемии
                </div>
              </div>
              <div
                class="second-block__tab"
                @click="choiceSecondTab(1)"
                :class="{ 'second-block__tab_active': secondTabsActive === 1 }"
              >
                <img
                  class="second-block__tab-back"
                  v-if="secondTabsActive === 1"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="second-block__tab-icon">
                  <img
                    v-if="secondTabsActive === 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-2_active.svg`"
                  />
                  <img
                    v-if="secondTabsActive !== 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-2.svg`"
                  />
                </div>
                <div class="second-block__tab-text">
                  Контроль с помощью глюкометра
                </div>
              </div>
              <div
                class="second-block__tab"
                @click="choiceSecondTab(2)"
                :class="{ 'second-block__tab_active': secondTabsActive === 2 }"
              >
                <img
                  class="second-block__tab-back"
                  v-if="secondTabsActive === 2"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back1.svg`"
                />
                <div class="second-block__tab-icon">
                  <img
                    v-if="secondTabsActive === 2"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-3_active.svg`"
                  />
                  <img
                    v-if="secondTabsActive !== 2"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs2-3.svg`"
                  />
                </div>
                <div class="second-block__tab-text">
                  Гликированный гемоглобин
                </div>
              </div>
            </div>

            <div
              class="second-block__arrow second-block__arrow_right"
              @click="
                choiceSecondTab(
                  secondTabsActive < 2 ? secondTabsActive + 1 : secondTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="second-block__tabs-body">
          <div v-if="secondTabsActive === 0">
            <div class="fs-23 fw-sb ff-r mb-6">
              <b class="fw-b" style="color: #d0006f"
                >Непрерывный мониторинг гликемии (НМГ)</b
              >
              – надежный и эффективный метод, позволяющий корректировать лечение
              для достижения целевых показателей гликемии и снижать риски
              развития гипогликемии<sup>1</sup>.
            </div>
            <div class="fs-18 ff-r mb-17">
              Врач должен проинформировать пациента, что показания НМГ могут
              отличаться от реальных измерений уровня глюкозы в крови, особенно
              при ее быстрых колебаниях, например, после приема пищи или во
              время физической нагрузки<sup>3-8</sup>. Хотя системы становятся
              все более надежными, пациентам может потребоваться проверка
              показаний датчиков с помощью глюкометра перед введением инсулина.
            </div>
            <div
              class="fs-28 ff-rs fw-sb mb-5"
              style="color: #d0006f; text-align: center"
            >
              Механизм работы НМГ
            </div>
            <div class="fs-18 ff-r fw-b mb-5" style="text-align: center">
              НМГ фиксируются в подкожную клетчатку и измеряют уровень глюкозы в
              интерстициальной жидкости
            </div>
            <div
              class="px-12 px-md-8 py-6 px-xs-5 py-xs-5"
              style="
                background-color: #ffffff;
                border: 1px solid #d2d2d2;
                border-radius: 20px;
              "
            >
              <div
                class="fs-18 ff-r fw-b mb-6"
                style="color: #d0006f; text-align: center"
              >
                Схема устройства непрерывного мониторинга глюкозы
              </div>
              <div class="d-xl-flex">
                <div
                  style="flex-basis: 330px; flex-shrink: 0"
                  class="mr-12 mr-md-0 mr-xs-0"
                >
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        class="d-none d-md-block"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                      <img
                        style="width: 20px"
                        class="d-md-none"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Монитор (ридер) для отображения информации (в некоторых
                      случаях это мобильное устройство пациента).
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        class="d-none d-md-block"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                      <img
                        style="width: 20px"
                        class="d-md-none"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Датчик (сенсор) – обычно вводится в подкожную жировую
                      клетчатку. Продолжительность работы большинства сенсоров
                      составляет 10–14 дней.
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        class="d-none d-md-block"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                      <img
                        style="width: 20px"
                        class="d-md-none"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Передатчик (трансмиттер) – передает данные с сенсора на
                      монитор.
                    </div>
                  </div>
                </div>
                <div>
                  <div class="full-screen">
                    <div
                      class="full-screen__trigger d-md-none"
                      :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1.jpg
                      `"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <picture>
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1_mob.jpg
                        `"
                        media="(max-width: 767px)"
                      />
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1_tab.jpg
                        `"
                        media="(max-width: 1219px)"
                      />
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1.jpg
                        `"
                        media="(min-width: 1220px)"
                      />
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1.jpg
                        `"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            <div class="ff-r fs-18_s mt-4 mb-10" style="color: #9c9c9c">
              Примечание: ПЖК – подкожная жировая клетчатка.
            </div>
            <div
              class="px-12 px-md-8 py-6"
              style="
                background-color: #ffffff;
                border: 1px solid #d2d2d2;
                border-radius: 20px;
              "
            >
              <div
                class="fs-18 ff-r fw-b mb-6"
                style="color: #d0006f; text-align: center"
              >
                Сравнение показателей глюкозы глюкометра и НМГ
              </div>
              <div class="fs-18 ff-r mb-5" style="text-align: center">
                <b class="fw-b" style="color: #d0006f">Важно</b> помнить, что
                показатели глюкозы в интерстициальной жидкости ПЖК
                <b class="fw-b">на 5‒10</b> мин отстают от уровня глюкозы в
                крови. Данное обстоятельство имеет значение особенно для
                пациентов, склонных к стремительному развитию гипогликемии
                (например, дети маленького возраста)<sup>3-8</sup>.
              </div>
              <div class="d-xl-flex mb-8">
                <div style="flex-basis: 50%" class="mr-8 mr-md-0 mb-md-8">
                  <picture>
                    <source
                      :srcset="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-1_mob.jpg
                      `"
                      media="(max-width: 767px)"
                    />
                    <source
                      :srcset="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-1_tab.png
                      `"
                      media="(max-width: 1219px)"
                    />
                    <source
                      :srcset="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-1.jpg
                      `"
                      media="(min-width: 1220px)"
                    />
                    <img
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-1.jpg
                      `"
                    />
                  </picture>
                </div>
                <div style="flex-basis: 50%">
                  <div class="full-screen">
                    <div
                      class="full-screen__trigger d-md-none"
                      :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block1.jpg
                      `"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <picture>
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-2_mob.jpg
                        `"
                        media="(max-width: 767px)"
                      />
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-2.jpg
                        `"
                        media="(max-width: 1219px)"
                      />
                      <source
                        :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-2.jpg
                        `"
                        media="(min-width: 1220px)"
                      />
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__block2-2.jpg
                        `"
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div class="fs-18 ff-r mb-4" style="text-align: center">
                Для понимания точности измерения с помощью НМГ используют
                показатель приемлемой абсолютной величины относительной
                погрешности <b class="fw-b">(MARD)</b>.
              </div>
              <div class="fs-18 ff-r mb-4" style="text-align: center">
                <b class="fw-b" style="color: #d0006f">MARD</b> – это средняя
                абсолютная относительная разница
                <b class="fw-b">(Mean Absolute Relative Difference)</b> между
                измерениями уровня глюкозы, измеренными глюкометром и значениями
                глюкозы, полученными датчиком НМГ, выраженная в процентах.
              </div>
              <div class="fs-18 ff-r" style="text-align: center">
                <span style="color: #d0006f"
                  >Чем <b class="fw-b">ниже процент</b> MARD, тем
                  <b class="fw-b">выше точность</b> измерения глюкозы у систем
                  НМГ.<sup>66-70</sup></span
                >
              </div>
            </div>
            <div class="ff-r fs-18_s mt-4 mb-10" style="color: #9c9c9c">
              Примечание: НМГ – непрерывный мониторинг гликемии; ПЖК – подкожная
              жировая клетчатка.
            </div>
            <div class="fs-18 ff-r mb-6">
              Индивидуальные изменения строения капиллярной стенки могут
              искажать информативность результатов. Ввиду этого большинство НМГ
              требуют калибровки по значениям уровня глюкозы в крови с
              использованием анализа гликемии по глюкометру<sup>35</sup>.
            </div>
            <div class="fs-23 fw-b">
              <b class="fw-b" style="color: #d0006f">Рекомендуется</b> измерение
              гликемии глюкометром не менее 4 раз в сутки при использовании НМГ
              в реальном времени и не менее 2 раз в сутки при использовании
              флеш-мониторинга глюкозы (ФМГ) у пациентов с СД1 с целью оценки
              точности данных и/или калибровки<sup>4</sup>.
            </div>
            <input
              type="checkbox"
              style="display: none"
              id="second-block__tab1-additional"
              class="second-block__tab1-additional-trigger"
            />
            <div class="d-xl-flex mt-10">
              <label
                for="second-block__tab1-additional"
                class="
                  d-none d-xl-flex
                  second-block__tab1-button
                  button button_empty-pink-light
                  mr-4 mr-md-0
                  mb-md-4
                  ml-auto ml-md-0
                  py-4
                "
              >
              </label>
              <a
                :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%9C%D0%B5%D1%85%D0%B0%D0%BD%D0%B8%D0%B7%D0%BC%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B%20%D0%9D%D0%9C%D0%93.pdf`"
                download="механизм работы НМГ.pdf"
                target="_blank"
                class="button button_pink-light py-4 mr-auto ml-md-auto px-12"
              >
                Скачать механизм работы НМГ
                <svg
                  class="ml-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                    fill="white"
                  />
                  <path
                    d="M12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V13.59L7.71 10.29C7.5217 10.1017 7.2663 9.99591 7 9.99591C6.7337 9.99591 6.47831 10.1017 6.29 10.29C6.1017 10.4783 5.99591 10.7337 5.99591 11C5.99591 11.2663 6.1017 11.5217 6.29 11.71L11.29 16.71C11.383 16.8037 11.4936 16.8781 11.6154 16.9289C11.7373 16.9797 11.868 17.0058 12 17.0058C12.132 17.0058 12.2627 16.9797 12.3846 16.9289C12.5064 16.8781 12.617 16.8037 12.71 16.71L17.71 11.71C17.8032 11.6168 17.8772 11.5061 17.9277 11.3843C17.9781 11.2624 18.0041 11.1319 18.0041 11C18.0041 10.8681 17.9781 10.7376 17.9277 10.6158C17.8772 10.4939 17.8032 10.3832 17.71 10.29C17.6168 10.1968 17.5061 10.1228 17.3843 10.0723C17.2624 10.0219 17.1319 9.99591 17 9.99591C16.8681 9.99591 16.7376 10.0219 16.6158 10.0723C16.4939 10.1228 16.3832 10.1968 16.29 10.29L13 13.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2Z"
                    fill="white"
                  />
                </svg>
              </a>
              <label
                for="second-block__tab1-additional"
                class="
                  d-xl-none
                  second-block__tab1-button
                  button button_empty-pink-light
                  mr-4 mr-md-0
                  mt-md-4
                  ml-auto
                  mr-md-auto
                  py-4
                "
              >
              </label>
            </div>

            <div class="second-block__tab1-additional mt-10">
              <div class="fs-28 ff-rs fw-sb mb-5" style="text-align: center">
                Принципы калибровки систем НМГ
              </div>
              <div class="second-block__tab1-line mb-10">
                <div class="second-block__tab1-line-item">
                  <div class="second-block__tab1-line-item-index">
                    <span>
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse.png`"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-item-text my-auto">
                    <div class="second-block__tab1-line-item-text">
                      Необходимо выбрать в течение дня 2–3 интервала времени с
                      минимально возможными колебаниями концентрации глюкозы в
                      крови (диапазон не менее 30 мин).
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-item">
                  <div class="second-block__tab1-line-item-index">
                    <span>
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse.png`"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-item-text my-auto">
                    <div class="second-block__tab1-line-item-text">
                      Стрелка «тренда» не должна быть направлена вверх\вниз.
                      Лучшие периоды: натощак или через 2–3 часа после еды.
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-item">
                  <div class="second-block__tab1-line-item-index">
                    <span>
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse.png`"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-item-text my-auto">
                    <div class="second-block__tab1-line-item-text">
                      Калибровка должна выполняться не ранее чем через 2 часа
                      после установки системы НМГ (ввиду того что происходит
                      заполнение сенсора ИСЖ, устранение влияние микротравмы
                      травмы и других факторов).
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-item">
                  <div class="second-block__tab1-line-item-index">
                    <span>
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse.png`"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-item-text my-auto">
                    <div class="second-block__tab1-line-item-text">
                      Точность глюкометра должна быть максимально высокой: важно
                      не использовать просроченные тест полоски, мыть руки перед
                      контролем гликемии и использовать другие правила
                      пользованию глюкометром.
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-item">
                  <div class="second-block__tab1-line-item-index">
                    <span>
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse.png`"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-item-text my-auto">
                    <div class="second-block__tab1-line-item-text">
                      Вводить данные калибровки без опозданий, сразу после
                      измерения гликемии по глюкометру.
                    </div>
                  </div>
                </div>
              </div>
              <div class="block-with-rect">
                <div class="fs-18 fw-b ff-r">
                  Пороговые уровни глюкозы для срабатывания сигналов тревоги
                  должны быть установлены таким образом, чтобы обеспечить
                  максимальную комплаентность пациента, не забывая о том, что
                  чувствительность НМГ в гипогликемическом диапазоне снижается.
                </div>
              </div>
            </div>

            <div
              class="mt-20 mb-8 fs-28 fw-sb ff-rs"
              style="color: #3e1876; text-align: center"
            >
              Преимущества НМГ <sup>3-8,24,25,31-34</sup>
            </div>

            <div class="second-block__tab1-blocks-grid d-none d-xl-grid">
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block1.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Простой и удобный способ анализа уровня гликемии у детей и
                    взрослых*
                  </div>
                  <ul class="second-block__tab1-blocks-grid-block-list">
                    <li>
                      Большинство систем НМГ влагоустойчивые, что не
                      ограничивает пациентов в их использовании при посещении
                      бассейна, поездках на море и т.д.
                    </li>
                    <li>
                      Относительно небольшой размер устройства позволяет
                      устанавливать на разных частях тела (например, в область
                      плеча, бедра или поясницы) и скрывать под одеждой, что
                      является важным для людей, активно занимающихся спортом
                      или физическим трудом, для пациентов младшего возраста,
                      которые могут в игре или в силу непонимания предназначения
                      оторвать датчик, а также для пациентов, желающих скрыть от
                      посторонних глаз наличие устройства на теле.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block3.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Обеспечение немедленной связи в реальном времени**
                  </div>
                </div>
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block4.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Прогнозирование надвигающейся гипогликемии**
                  </div>
                </div>
              </div>
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block6.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Улучшение компенсация СД при использовании НМГ
                  </div>
                </div>
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block7.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Прогнозирование резких колебаний уровня глюкозы**
                  </div>
                </div>
              </div>
            </div>
            <div class="second-block__tab1-blocks-grid d-none d-xl-grid mt-8">
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block2.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Повышает частоту диагностики эпизодов гипогликемии и
                    гипергликемии
                  </div>
                  <ul class="second-block__tab1-blocks-grid-block-list">
                    <li>
                      НМГ позволяет идентифицировать периоды скрытых ночных
                      гипогликемий, постпрандиальных гипергликемий
                    </li>
                  </ul>
                </div>
              </div>
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block5.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Сохранение данных об уровне гликемии и возможность
                    ретроспективной оценки информации
                  </div>
                </div>
              </div>
              <div class="second-block__tab1-blocks-grid-col">
                <div class="second-block__tab1-blocks-grid-block">
                  <div class="second-block__tab1-blocks-grid-block-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block8.svg`"
                    />
                  </div>
                  <div class="second-block__tab1-blocks-grid-block-title">
                    Инструменты поддержки принятия решений для пациента и врача
                    в коррекции терапии
                  </div>
                </div>
              </div>
            </div>
            <swiper
              class="d-xl-none second-block__tab1-blocks-grid-carousel"
              v-bind="swiperSettings"
            >
              <swiper-slide>
                <div class="second-block__tab1-blocks-grid-col">
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block1.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Простой и удобный способ анализа уровня гликемии у детей и
                      взрослых*
                    </div>
                    <ul class="second-block__tab1-blocks-grid-block-list">
                      <li>
                        Большинство систем НМГ влагоустойчивые, что не
                        ограничивает пациентов в их использовании при посещении
                        бассейна, поездках на море и т.д.
                      </li>
                      <li>
                        Относительно небольшой размер устройства позволяет
                        устанавливать на разных частях тела (например, в область
                        плеча, бедра или поясницы) и скрывать под одеждой, что
                        является важным для людей, активно занимающихся спортом
                        или физическим трудом, для пациентов младшего возраста,
                        которые могут в игре или в силу непонимания
                        предназначения оторвать датчик, а также для пациентов,
                        желающих скрыть от посторонних глаз наличие устройства
                        на теле.
                      </li>
                    </ul>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="second-block__tab1-blocks-grid-col">
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block3.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Обеспечение немедленной связи в реальном времени**
                    </div>
                  </div>
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block4.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Прогнозирование надвигающейся гипогликемии**
                    </div>
                  </div>
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block5.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Сохранение данных об уровне гликемии и возможность
                      ретроспективной оценки информации
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="second-block__tab1-blocks-grid-col">
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block6.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Улучшение компенсация СД при использовании НМГ
                    </div>
                  </div>
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block7.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Прогнозирование резких колебаний уровня глюкозы**
                    </div>
                  </div>
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block8.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Инструменты поддержки принятия решений для пациента и
                      врача в коррекции терапии
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="second-block__tab1-blocks-grid-col">
                  <div class="second-block__tab1-blocks-grid-block">
                    <div class="second-block__tab1-blocks-grid-block-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/blocks-grid-block2.svg
                        `"
                      />
                    </div>
                    <div class="second-block__tab1-blocks-grid-block-title">
                      Повышает частоту диагностики эпизодов гипогликемии и
                      гипергликемии
                    </div>
                    <ul class="second-block__tab1-blocks-grid-block-list">
                      <li>
                        НМГ позволяет идентифицировать периоды скрытых ночных
                        гипогликемий, постпрандиальных гипергликемий
                      </li>
                    </ul>
                  </div>
                </div>
              </swiper-slide>
              <div slot="container-end">
                <div class="slider-dots">
                  <div class="slider-dots__row"></div>
                  <div class="slider-dots__icon">
                    <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/swipe-left.svg`" />
                  </div>
                </div>
              </div>
            </swiper>

            <div class="fs-18_s mt-5 mb-10" style="color: #9c9c9c">
              Примечание: * – С 2016 г. FDA одобрило использование нескольких
              систем НМГ как основного метода самоконтроля гликемии.<br />
              ** – Для некоторых устройств возможно предупреждающие о
              низком/высоком/падающем/повышающемся уровне глюкозы, отображая тем
              самым наличие «трендов» (повторяющихся эпизодов) по данным анализа
              суточных профилей.
            </div>
            <div class="fs-18 mb-6">
              Внедрение метода НМГ в клиническую практику породило новые
              критерии коррекционных мероприятий инсулинотерапии –
              <b class="fw-b" style="color: #d0006f">тренды</b> гликемии.
            </div>
            <div class="fs-23 fw-sb mb-6" style="font-style: italic">
              <b style="color: #d0006f">Тренд гликемии</b> – это изменение
              скорости и направления (вверх/вниз) кривой гликемии, которые
              наблюдаются на экране мониторинга, смартфона и другого монитора.
            </div>
            <div class="fs-18 mb-6">
              Обычно тренд гликемии отображается в виде стрелок, направление и
              число которых зависит от скорости и интенсивности изменений
              гликемии. Использование и визуализация данных технологий анализа
              колебаний гликемии, по данным ретроспективных исследований
              помогает людям с сахарным диабетом более точно подобрать
              инсулинотерапию и привести к улучшению среднесуточной гликемии, в
              рамках снижения риска вторичных осложнений<sup>26,27</sup>.
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <picture>
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table1_tab.png`"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table1_tab.png`"
                    media="(max-width: 1219px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table1.png`"
                    media="(min-width: 1220px)"
                  />
                  <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table1.png`" />
                </picture>
              </div>
            </div>
            <div id="kinds"></div>
            <div
              class="mt-22 mb-8 fs-28 ff-rs fw-sb"
              style="text-align: center"
            >
              Виды систем непрерывного мониторинга <sup>3,5-7,28,54</sup>
            </div>
            <div class="tap-hint mt-4 d-md-none">
              <div class="tap-hint__icon ml-auto mr-2">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tap.svg`" />
              </div>
              <div class="tap-hint__text">Выберите вид мониторинга</div>
            </div>
            <div class="second-block__tab1-kinds">
              <div class="second-block__tab1-kinds-tabs">
                <div class="second-block__tab1-kinds-tabs-list">
                  <div
                    class="second-block__tab1-kinds-tabs-tab"
                    @click="changeBlock2Tabs1(0)"
                    :class="{
                      'second-block__tab1-kinds-tabs-tab_active':
                        block2Tab1ActiveTab === 0,
                    }"
                  >
                    НМГ в реальном времени
                  </div>
                  <div
                    class="second-block__tab1-kinds-tabs-tab"
                    @click="changeBlock2Tabs1(1)"
                    :class="{
                      'second-block__tab1-kinds-tabs-tab_active':
                        block2Tab1ActiveTab === 1,
                    }"
                  >
                    Flash-мониторинг
                  </div>
                  <div
                    class="second-block__tab1-kinds-tabs-tab"
                    @click="changeBlock2Tabs1(2)"
                    :class="{
                      'second-block__tab1-kinds-tabs-tab_active':
                        block2Tab1ActiveTab === 2,
                    }"
                  >
                    НМГ в «слепом» режиме
                  </div>
                </div>
                <div
                  class="second-block__tab1-kinds-tabs-arrows d-none d-xl-flex"
                >
                  <div
                    @click="
                      changeBlock2Tabs1(
                        block2Tab1ActiveTab > 0
                          ? block2Tab1ActiveTab - 1
                          : block2Tab1ActiveTab
                      )
                    "
                    class="
                      second-block__tab1-kinds-tabs-arrow
                      second-block__tab1-kinds-tabs-arrow_left
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.25 4.5L3.75 12L11.25 19.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <path
                        d="M5.25 12L20.25 12"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                  <div
                    @click="
                      changeBlock2Tabs1(
                        block2Tab1ActiveTab < 2
                          ? block2Tab1ActiveTab + 1
                          : block2Tab1ActiveTab
                      )
                    "
                    class="
                      second-block__tab1-kinds-tabs-arrow
                      second-block__tab1-kinds-tabs-arrow_right
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.75 4.5L20.25 12L12.75 19.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <path
                        d="M18.75 12L3.75 12"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="second-block__tab1-kinds-tabs-body">
                <div v-if="block2Tab1ActiveTab === 0">
                  <div class="d-flex">
                    <div>
                      <div class="fs-18 mb-8">
                        Системы
                        <b class="fw-b" style="color: #d0006f"
                          >НМГ в реальном времени</b
                        >
                        имеют сигналы тревоги, которые активируются при
                        достижении гликемией пороговых значений, также возможно
                        прогнозировании этих значений и скорости изменения
                        гликемии. Некоторые модели систем НМГ в «реальном»
                        времени передают данные об уровне глюкозы
                        непосредственно на смартфон, откуда они могут быть
                        сохранены на сервере в сети Интернет и использованы для
                        удаленного мониторинга<sup>4</sup>.
                      </div>
                      <div
                        class="mx-auto mb-10 d-md-none"
                        style="width: 180px; flex-shrink: 0"
                      >
                        <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds1.png`" />
                      </div>
                      <div class="fs-18 mb-5">
                        <b class="fw-b"
                          >Данные НМГ позволяют врачам оценить и
                          проанализировать следующие параметры:</b
                        >
                      </div>
                      <div class="d-xl-flex d-none mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          <div class="fw-b mb-2">
                            Общие данные о контроле гликемии
                          </div>
                          • Средний уровень глюкозы <br />
                          • Вариабельность гликемии
                        </div>
                      </div>
                      <div class="d-xl-flex d-none mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          <div class="fw-b mb-2">
                            Особенности колебаний гликемии в течение дня
                          </div>
                          • В зависимости от биоритмов (феномен утренней зари и
                          др.)
                          <br />
                          • Зависимость колебаний гликемии от физической
                          нагрузки <br />
                          • Выявления индивидуальных постпрандиальных профилей
                          гликемии (ускоренное или замедленное всасывание
                          углеводов из ЖКТ) <br />
                          • Иные тренды (разница в показателях гликемии в
                          рабочие и будние дни, во время менструального цикла и
                          т.д.)
                        </div>
                      </div>
                      <div class="d-xl-none d-md-flex">
                        <div>
                          <div class="d-flex mb-5">
                            <div class="mr-4" style="flex-shrink: 0">
                              <img
                                class="d-none d-md-block"
                                :src="`
                                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg
                                `"
                              />
                              <img
                                style="width: 20px"
                                class="d-md-none"
                                :src="`
                                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg
                                `"
                              />
                            </div>
                            <div class="fs-18 ff-r">
                              <div class="fw-b mb-2">
                                Общие данные о контроле гликемии
                              </div>
                              • Средний уровень глюкозы <br />
                              • Вариабельность гликемии
                            </div>
                          </div>
                          <div class="d-flex mb-5">
                            <div class="mr-4" style="flex-shrink: 0">
                              <img
                                class="d-none d-md-block"
                                :src="`
                                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg
                                `"
                              />
                              <img
                                style="width: 20px"
                                class="d-md-none"
                                :src="`
                                  https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg
                                `"
                              />
                            </div>
                            <div class="fs-18 ff-r">
                              <div class="fw-b mb-2">
                                Особенности колебаний гликемии в течение дня
                              </div>
                              • В зависимости от биоритмов (феномен утренней
                              зари и др.)
                            </div>
                          </div>
                        </div>
                        <div
                          class="ml-8 d-none d-md-block"
                          style="flex-basis: 280px; flex-shrink: 0"
                        >
                          <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds1.png`" />
                        </div>
                      </div>
                      <div class="d-xl-none d-flex mb-5 mt-n-3">
                        <div class="mr-4" style="flex-shrink: 0; opacity: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          • Зависимость колебаний гликемии от физической
                          нагрузки <br />
                          • Выявления индивидуальных постпрандиальных профилей
                          гликемии (ускоренное или замедленное всасывание
                          углеводов из ЖКТ) <br />
                          • Иные тренды (разница в показателях гликемии в
                          рабочие и будние дни, во время менструального цикла и
                          т.д.)
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          <div class="fw-b mb-2">Эпизоды гипогликемии</div>
                          • Скрытые (бессимптомные гипогликемии)
                          <br />
                          • Провоцирующие факторы (время суток, физическая
                          нагрузка и др)
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-19 d-none d-xl-block"
                      style="flex-basis: 384px; flex-shrink: 0"
                    >
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds1.png`" />
                    </div>
                  </div>
                  <div class="fs-18">
                    Рекомендуется применение НМГ в реальном времени (с функцией
                    сигналов тревоги) у детей с СД 1 типа младше 4-х лет, у
                    детей, которые не могут сообщить о симптомах гипо- или
                    гипергликемии и у детей с нарушением восприятия гипогликемии
                    для снижения риска гипогликемии<sup>4</sup>.
                  </div>
                </div>
                <div v-if="block2Tab1ActiveTab === 1">
                  <div class="d-flex">
                    <div>
                      <div class="fs-23 fw-sb mb-6">
                        Периодически сканируемое/просматриваемое НМГ (или
                        Flash-мониторинг гликемии – ФМГ)
                      </div>
                      <div class="fs-18">
                        Система ФМГ не отображает данные об уровне глюкозы
                        автоматически, а только при «сканировании» ‒ приближении
                        на короткое расстояние сканера к трансмиттеру. ФМГ
                        предоставляет информацию о текущем уровне глюкозы,
                        тенденции (направления и скорости) изменения глюкозы, а
                        также график колебаний гликемии за время использования
                        сенсора<sup>4</sup>.
                        <br />
                        <br />
                        <span class="d-md-none d-inline d-xl-inline">
                          ФМГ может быть использован вместо глюкометра для
                          принятия клинических решений за исключением отдельных
                          случаев (гипогликемии, быстрого изменения гликемии или
                          если симптомы не соответствуют показателям
                          системы)<sup>4</sup>.
                        </span>
                      </div>
                      <div
                        class="mx-auto mt-6 d-md-none"
                        style="width: 180px; flex-shrink: 0"
                      >
                        <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds2.png`" />
                      </div>
                    </div>
                    <div
                      class="ml-19 d-none d-xl-block"
                      style="flex-basis: 384px; flex-shrink: 0"
                    >
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds2.png`" />
                    </div>
                  </div>
                  <div class="d-none d-md-flex d-xl-none">
                    <div>
                      <div class="fs-18">
                        ФМГ может быть использован вместо глюкометра для
                        принятия клинических решений за исключением отдельных
                        случаев (гипогликемии, быстрого изменения гликемии или
                        если симптомы не соответствуют показателям
                        системы)<sup>4</sup>.
                      </div>
                    </div>
                    <div class="ml-8" style="flex-basis: 280px; flex-shrink: 0">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds2.png`" />
                    </div>
                  </div>
                  <div
                    class="fs-28 fw-sb ff-rs mb-5 mt-12"
                    style="color: #3e1876; text-align: center"
                  >
                    Непрерывное мониторирование гликемии в «реальном времени»
                    <span style="color: #d0006f">vs</span
                    >&nbsp;Flash&#8209;мониторинг
                  </div>
                  <div class="overflow">
                    <div class="overflow__body">
                      <picture>
                        <source
                          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2_tab.png`"
                          media="(max-width: 767px)"
                        />
                        <source
                          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2_tab.png`"
                          media="(max-width: 1219px)"
                        />
                        <source
                          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2.png`"
                          media="(min-width: 1220px)"
                        />
                        <img
                          class="mx-auto d-none d-md-none d-xl-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2.png`"
                          style="max-width: 1000px"
                        />
                        <img
                          class="mx-auto d-none d-md-block d-xl-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2.png`"
                          style="max-width: 100%; width: 100%"
                        />
                        <img
                          class="mx-auto d-block d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table2.png`"
                          style="width: 500px"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div v-if="block2Tab1ActiveTab == 2">
                  <div class="fs-23 fw-sb mb-6">
                    <b class="fw-b" style="color: #d0006f"
                      >НМГ в «слепом» режиме</b
                    >
                    позволяет осуществлять ретроспективную оценку данных
                    получаемых за короткий промежуток времени (несколько дней).
                  </div>
                  <div
                    class="mx-auto mb-6 d-md-none"
                    style="width: 180px; flex-shrink: 0"
                  >
                    <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds3.png`" />
                  </div>
                  <div class="fs-18 mb-5 d-none d-md-block d-xl-none">
                    <b class="fw-b"
                      >Основным назначением данного метода являются:</b
                    >
                  </div>
                  <div class="d-xl-flex d-md-none">
                    <div>
                      <div class="fs-18 mb-5 d-block d-md-none d-xl-block">
                        <b class="fw-b"
                          >Основным назначением данного метода являются:</b
                        >
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Объективная оценка гликемического профиля в
                          повседневной жизни пациента (в т.ч. ввиду отсутствия
                          сигналов тревоги и монитора происходит исключение
                          влияния пациента в виде «повышенной мотивации» и
                          «ложной компенсации» углеводного обмена в период
                          исследования)
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Объективная оценка эффективности проводимого лечения
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Выявление скрытых эпизодов гипо-/ гипергликемий с
                          целью коррекции проводимого лечения
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Обучение пациентов. Данные системы применяются, как
                          правило, в условиях стационара<sup>4,71</sup>.
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-19 d-none d-xl-block"
                      style="flex-basis: 384px; flex-shrink: 0"
                    >
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds3.png`" />
                    </div>
                  </div>
                  <div class="d-md-flex d-none d-xl-none">
                    <div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Объективная оценка гликемического профиля в
                          повседневной жизни пациента (в т.ч. ввиду отсутствия
                          сигналов тревоги и монитора происходит исключение
                          влияния пациента в виде «повышенной мотивации» и
                          «ложной компенсации» углеводного обмена в период
                          исследования)
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div class="mr-4" style="flex-shrink: 0">
                          <img
                            class="d-none d-md-block"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                          <img
                            style="width: 20px"
                            class="d-md-none"
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                          />
                        </div>
                        <div class="fs-18 ff-r">
                          Объективная оценка эффективности проводимого лечения
                        </div>
                      </div>
                    </div>
                    <div class="ml-8" style="flex-basis: 280px; flex-shrink: 0">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/kinds3.png`" />
                    </div>
                  </div>
                  <div class="d-none d-md-block d-xl-none">
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Выявление скрытых эпизодов гипо-/ гипергликемий с целью
                        коррекции проводимого лечения
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Обучение пациентов. Данные системы применяются, как
                        правило, в условиях стационара<sup>4,71</sup>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-22 mt-xs-15 d-xl-flex d-md-block">
              <div style="flex-basis: 50%" class="mr-4 mr-md-0">
                <div
                  class="fs-28 fw-sb ff-rs mb-6"
                  style="text-align: center; color: #3e1876"
                >
                  Целевые показатели НМГ <sup>46</sup>
                </div>
                <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="mx-auto d-none d-xl-block"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table3.png`"
                    />
                    <img
                      class="mx-auto d-none d-md-block d-xl-none"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table3.png`"
                      style="max-width: 100%; width: 100%"
                    />
                    <img
                      class="mx-auto d-block d-md-none"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table3.png`"
                      style="width: 500px"
                    />
                  </div>
                </div>
              </div>
              <div style="flex-basis: 50%" class="ml-4 ml-md-0 mt-md-15">
                <div
                  class="fs-28 fw-sb ff-rs mb-6"
                  style="text-align: center; color: #d0006f"
                >
                  Показания к НМГ <sup>3-8</sup>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">Асимптоматические гипогликемии</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Более 1 случая тяжелых гипогликемий в анамнезе
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    С целью выявления «скрытых» гипогликемий
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    С целью оптимизации контроля гликемии в период физических
                    нагрузок
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    При смене режимов инсулинотерапии
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    При фобиях гипогликемии, заставляющий неоправданно часто
                    измерять гликемию с помощью глюкометра
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Подбор терапии у пациентов с гастропарезом
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Наличие гемоглобинопатий (HbA1c не может быть использован
                    для адекватной оценки)
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Период беременность у женщин, для наиболее тщательного
                    контроля с учетом более узкого целевого диапазона гликемии
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    При расхождении результатов самоконтроля гликемии и
                    результатов оценки гликированного гемоглобина (HbA1c)
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Для более тщательной оценки гликемического профиля,
                    верификации повышения уровня глюкозы утром (феномена
                    «утренней зари»)
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      style="width: 20px"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Профессиональный спорт и занятия физической активностью
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                mt-17
                px-12 px-xs-5
                py-8 py-xs-5
                second-block__tab1-hidden-block
              "
              :class="{
                'second-block__tab1-hidden-block_visible':
                  secondBlockPinkBlockVisible,
              }"
              style="border-radius: 20px; background-color: #f0c6dc"
            >
              <div class="mb-8 d-md-flex">
                <div class="mr-6" style="flex-basis: 100px; flex-shrink: 0">
                  <img
                    class="d-none d-md-block"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`"
                  />
                  <img
                    class="d-block d-md-none"
                    style="width: 50px"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`"
                  />
                </div>
                <div class="fs-28 fw-sb ff-rs">
                  <b style="color: #d0006f">
                    Противопоказаний к НМГ при сахарном диабете не существует.
                    Возможно отсутствие показаний и ограничения к
                    использованию.</b
                  >
                </div>
              </div>
              <div class="d-xl-flex">
                <div style="flex-basis: 50%" class="mr-4 mr-md-0">
                  <div
                    class="px-8 px-xs-5 py-5"
                    style="
                      background-color: #ffffff;
                      box-shadow: 0px 30px 50px -30px #00000059;
                      border-radius: 20px;
                    "
                  >
                    <div class="d-flex mb-5">
                      <div
                        class="mr-8 d-none d-xl-block"
                        style="flex-basis: 70px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon1.svg
                          `"
                        />
                      </div>
                      <div
                        class="mr-5 d-xl-none"
                        style="flex-basis: 50px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon1.svg
                          `"
                        />
                      </div>
                      <div class="fs-23 fw-sb my-auto">
                        Ограничения к применению НМГ<sup>3-8</sup>:
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Отсутствие участков кожи для установки сенсора
                        (например, генерализованные липодистрофии при
                        липоатрофическом диабете, или другие заболевания
                        подкожно-жировой клетчатки)
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Неадекватное поведение больного
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Аллергические и кожные побочные реакции на местах
                        установки НМГ
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Отсутствие собственного желания проводить регулярный
                        самоконтроль или использование НМГ &lt;60% времени
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">Возраст младше 2-х лет</div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Пациент не обладает достаточными навыками
                        интенсифицированной инсулинотерапии, не обучен принципам
                        самоконтроля
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Не рекомендуется к установке НМГ лицам на перитонеальном
                        диализе, т.к. мальтоза, которая используется в ходе
                        процедуры, может ошибочно быть распознана подкожным
                        сенсором как глюкоза и искажать результаты
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Люди, принимающие лекарства, содержащие ацетаминофен или
                        получающие гидроксимочевину или высокие дозы витамина С
                        при использовании ряда устройств НМГ, могут наблюдаться
                        ложно повышенные значения уровня глюкозы в крови. Это
                        дозозависимый эффект (зависит от уровня ацетаминофена в
                        тканях), который возникает в результате окисления
                        ацетаминофена электродами CGM<sup>36</sup>.
                      </div>
                    </div>
                  </div>
                </div>
                <div style="flex-basis: 50%" class="ml-4 ml-md-0 mt-md-15">
                  <div class="fs-23 fw-sb mb-6">
                    Факторы, которые могут снижать точность измерений систем
                    мониторирования, основанных на принципах глюкозооксидантной
                    или глюкозодегидрогеназной системы анализа данных
                  </div>
                  <div
                    class="px-8 px-xs-5 py-5 mb-5"
                    style="
                      background-color: #ffffff;
                      box-shadow: 0px 30px 50px -30px #00000059;
                      border-radius: 20px;
                    "
                  >
                    <div class="d-flex mb-5">
                      <div
                        class="mr-8 d-none d-xl-block"
                        style="flex-basis: 70px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon2.svg
                          `"
                        />
                      </div>
                      <div
                        class="mr-5 d-xl-none"
                        style="flex-basis: 50px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon2.svg
                          `"
                        />
                      </div>
                      <div class="fs-23 fw-sb my-auto">
                        Чувствительность к содержанию кислорода<sup>37</sup>
                      </div>
                    </div>
                    <div class="fs-18">
                      Глюкозооксидазные сенсоры чувствительны к поступлению
                      кислорода. Все анализа по точности систем проводились у
                      людей с нормальным насыщением крови кислородом.
                      <br />
                      <br />
                      Форсированное поступление кислорода (например, кислородная
                      терапия) может привести к ложному снижению уровня глюкозы,
                      а низкое содержание кислорода (например, на большой
                      высоте, при гипоксии) может привести к ложному повышению
                      уровня глюкозы.
                      <br />
                      <br />
                      Мониторы на основе глюкозодегидрогеназы, как правило,
                      нечувствительны к кислороду.
                    </div>
                  </div>
                  <div
                    class="px-8 px-xs-5 py-5 mb-5"
                    style="
                      background-color: #ffffff;
                      box-shadow: 0px 30px 50px -30px #00000059;
                      border-radius: 20px;
                    "
                  >
                    <div class="d-flex mb-5">
                      <div
                        class="mr-8 d-none d-xl-block"
                        style="flex-basis: 70px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon3.svg
                          `"
                        />
                      </div>
                      <div
                        class="mr-5 d-xl-none"
                        style="flex-basis: 50px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon3.svg
                          `"
                        />
                      </div>
                      <div class="fs-23 fw-sb my-auto">
                        Температурные факторы<sup>38</sup>
                      </div>
                    </div>
                    <div class="fs-18">
                      Поскольку реакция чувствительна к температуре, все сенсоры
                      имеют приемлемый температурный диапазон. Большинство из
                      них выдают ошибочные данные, если температура неприемлема,
                      но некоторые выдают показания и сообщение о том, что
                      значение может быть неверным.
                    </div>
                  </div>
                  <div
                    class="px-8 px-xs-5 py-5 mb-n-25 mb-md-n-0"
                    style="
                      background-color: #ffffff;
                      box-shadow: 0px 30px 50px -30px #00000059;
                      border-radius: 20px;
                    "
                  >
                    <div class="d-flex mb-5">
                      <div
                        class="mr-8 d-none d-xl-block"
                        style="flex-basis: 70px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon4.svg
                          `"
                        />
                      </div>
                      <div
                        class="mr-5 d-xl-none"
                        style="flex-basis: 50px; flex-shrink: 0"
                      >
                        <img
                          :src="`
                            https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon4.svg
                          `"
                        />
                      </div>
                      <div class="fs-23 fw-sb my-auto">
                        Влажность и высота над уровнем моря
                      </div>
                    </div>
                    <div class="fs-18">
                      также могут влиять на показатели глюкозы при контроле с
                      помощью НМГ<sup>45</sup>.
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-xl-none second-block__tab1-hidden-block-trigger"
                @click="
                  secondBlockPinkBlockVisible = !secondBlockPinkBlockVisible
                "
              >
                {{
                  secondBlockPinkBlockVisible ? "Скрыть" : "Показать полностью"
                }}
              </div>
            </div>
            <input
              type="checkbox"
              style="display: none"
              id="second-block__tab1-additional2"
              class="second-block__tab1-additional2-trigger"
            />
            <label
              for="second-block__tab1-additional2"
              class="
                second-block__tab1-additional2-button
                button button_pink-light
                mt-30 mt-md-10
                py-4
                px-8
                mx-auto
              "
            >
            </label>
            <div class="second-block__tab1-additional2 mt-10">
              <div class="fs-18 mb-8">
                В настоящий момент на рынке также представлены
                <b class="fw-b" style="color: #d0006f"
                  >имплантируемые системы НМГ</b
                >, использующие флуоресцентную технологию для передачи
                показателей гликемии через перезаряжаемый трансмиттер,
                расположенный непосредственно над кожей, в приложение для
                смартфона. Продолжительность работы сенсора в данном случае выше
                и составляет 180 дней. Этот вариант может быть особенно полезен
                для пациентов с проблемами конфиденциальности, фобией иглы,
                аллергией или другими трудностями с прикреплением аппарата НМГ,
                а также для тех, кто занимается деятельностью, которые могут
                препятствовать внешнему ношению датчика<sup>56</sup>.
              </div>
              <picture>
                <source
                  :srcset="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__scheme1_mob.png
                  `"
                  media="(max-width: 767px)"
                />
                <source
                  :srcset="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__scheme1_tab.png
                  `"
                  media="(max-width: 1219px)"
                />
                <source
                  :srcset="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__scheme1.png
                  `"
                  media="(min-width: 1220px)"
                />
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__scheme1.png`"
                />
              </picture>
              <div class="fs-18_s mt-5" style="color: #9c9c9c">
                А — определение уровня глюкозы посредством флуоресцентной пробы.
                Взаимодействие глюкозы с флуорофором приводит к флуоресценции, а
                при отсутствии глюкозы флуоресценция подавляется.
                <br />
                Б — подкожный имплантируемый оптический датчик НГМ Eversense.
                <br />
                В — передатчик Eversense на плече. <br />
                Примечание: ПММА — поли(метилметакрилат).
              </div>
              <div class="fs-18 mt-12">
                Кроме того, существует ряд
                <b class="fw-b" style="color: #d0006f"
                  >неинвазивных систем мониторинга</b
                >
                гликемии. Принцип определения глюкозы в этих устройствах
                построен на различных методиках, в частности электрических,
                тепловых, оптических и др. Данные системы контроля гликемии не
                задействуют биологические жидкости (кровь, интерстициальная
                жидкость и т.д.), а крепятся на поверхности кож (например,
                клипса на мочке уха). Минусом данных технологий является
                недостаточная точность измерения гликемии в меняющихся условиях
                (изменение температуры тела, освещения, особенности
                кровоснабжения и т.д.)<sup>57</sup>.
                <br />
                <br />
                Системы НМГ вкупе с современными мобильными технологиями
                предоставляют возможности для сбора показателей за разные
                временные промежутки (от суток до нескольких месяцев), анализа
                данных и передачи результатов медицинским работникам, а также
                способствует применению цифровых или дистанционных клинических
                моделей лечения<sup>4-8</sup>.
                <br />
                <br />
                Также существует множество автономных приложений для смартфонов,
                поддерживающих системы НМГ. Большинство из них предоставляют
                информацию и отслеживают данные, некоторые позволяют вести
                документацию по инсулину или углеводам, облегчают подсчет
                углеводов или калорий, способствуют снижению веса, отслеживают
                или поощряют физическую активность, повышают приверженность к
                терапии, используют мотивационные подходы, а некоторые также
                имеют в своем арсенале калькулятор дозировки инсулина.
                <br />
                <br />
                Использование специализированного программного обеспечения для
                анализа данных НМГ повышает эффективность работы диабетологов и
                помогает оптимизировать терапию. Программное обеспечение
                предоставляет графики или диаграммы и в некоторых случаях может
                давать рекомендации по дозировке препаратов как врачам, так и
                непосредственно для пациента<sup>58</sup>.
                <br />
                <br />
                <b class="fw-b" style="color: #d0006f"
                  >Интеграция НМГ и инсулиновой помпы</b
                >
                в единое устройство позволяет автоматически изменять подачу
                инсулина по данным сенсора – технология так же называемая
                «помповой инсулинотерапией с закрытым контуром». Возможны
                различные варианты управления введением инсулина по данным НМГ,
                которые могут использоваться отдельно или дополнять друг друга:
                остановка подачи инсулина непосредственно при достижении
                гипогликемического порога, предиктивная остановка подачи
                инсулина до достижения гипогликемического порога, автоматическое
                дозирование базального инсулина, автоматическое дозирование
                болюсного инсулина («микроболюсы») и др.<sup>59</sup>
              </div>
              <div
                class="fs-23 fw-sb mt-8"
                style="color: #d0006f; font-style: italic"
              >
                По результатам различных исследований использование НМГ в
                сочетании с инсулиновой помпой приводит к значимому снижению
                показателей HbА1С у пациентов с СД1<sup>60</sup>.
              </div>
              <div
                class="px-10 px-xs-5 py-8 py-xs-5 mt-10"
                style="
                  background-color: #ffffff;
                  box-shadow: 0px 30px 50px -30px #00000059;
                  border-radius: 20px;
                  border: 1px solid #d2d2d2;
                "
              >
                <div class="d-xl-flex">
                  <div class="mr-4 mr-md-0 mb-md-6" style="flex-basis: 40%">
                    <div class="fs-18 fw-sb">
                      Были совершены революционные шаги на пути создания так
                      называемой искусственной поджелудочной железы.
                      <br />
                      <br />
                      <b class="fw-b" style="color: #d0006f"
                        >Искусственная поджелудочная железа</b
                      >
                      подразумевает собой полностью автономную систему контроля
                      гликемии и подачи инсулина и состоит из:
                    </div>
                  </div>
                  <div
                    class="ml-4 ml-md-0"
                    style="flex-basis: calc(60% - 20px)"
                  >
                    <div class="d-flex mb-4">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r my-auto">
                        Автоматического и непрерывного монитора глюкозы;
                      </div>
                    </div>
                    <div class="d-flex mb-4">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r my-auto">
                        Имплантированной системы непрерывной подачи инсулина
                        (помпа);
                      </div>
                    </div>
                    <div class="d-flex mb-4">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r my-auto">
                        Блока управления с математическим алгоритмом;
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          class="d-none d-md-block"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                        <img
                          style="width: 20px"
                          class="d-md-none"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r my-auto">Трансмиттера.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fs-18 mt-10">
                Данные, передаваемые с сенсора, анализируются алгоритмом,
                который автоматически, с учетом ранее введенного инсулина,
                предыдущих показателей глюкозы и другой информации, рассчитывает
                режим подачи инсулина и передает сигнал на помпу<sup>61</sup>.
                <br />
                <br />
                <b class="fw-b" style="color: #d0006f">Ограничениями</b> для
                создания данной системы и полного ее внедрения на данный момент
                являются неточность показаний НМГ, время запаздывания сенсора,
                неадекватное начало и смещение действия имеющихся в настоящее
                время аналогов инсулина быстрого действия, а также изменения
                чувствительности к инсулину, связанные с циркадными ритмами,
                физическими нагрузками, менструальными циклами и сопутствующими
                заболеваниями<sup>61</sup>.
                <br />
                <br />
                Тем не менее, создан целый ряд полуавтономных систем, которые
                существенно улучшают компенсацию СД, не усложняя при этом
                процесс принятия решений о коррекции терапии
                пациентом<sup>61</sup>.
              </div>
              <div class="second-block__tab1-line-violet mt-12">
                <div class="second-block__tab1-line-violet-item">
                  <div class="second-block__tab1-line-violet-item-index">
                    <span>
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png
                        `"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text my-auto">
                    <div class="second-block__tab1-line-violet-item-title">
                      Помпы с гибридным замкнутым контуром:
                    </div>
                    <div class="second-block__tab1-line-violet-item-text">
                      Подача инсулина увеличивается или уменьшается в
                      зависимости от значения глюкозы на датчике, но при этом
                      все равно требуются болюсы инсулина для приема пищи.
                      Данные системы активно используются в настоящий момент и
                      демонстрируют положительные результаты в отношении как
                      безопасности, так и эффективности для компенсации СД.
                      <br />
                      <br />
                      <b class="fw-b" style="color: #3e1876"
                        >Использование систем автоматического введения инсулина
                        не избавляет от рутинного контроля диабета!</b
                      >
                      <br />
                      <br />
                      Пользователю необходимо контролировать работу устройства,
                      регулярно менять инфузионные системы, считать углеводы и
                      вводить инсулин на еду (или информировать алгоритм о
                      приеме пищи) и многое другое. Поэтому даже при
                      использовании таких инсулиновых помп потребуются хорошие
                      знания и умения по сахарному диабету, помповой
                      инсулинотерапии и НМГ<sup>61</sup>.
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-violet-item">
                  <div class="second-block__tab1-line-violet-item-index">
                    <span>
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png
                        `"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text my-auto">
                    <div class="second-block__tab1-line-violet-item-title">
                      Помпы с полностью замкнутым контуром управления:
                    </div>
                    <div class="second-block__tab1-line-violet-item-text">
                      Подача инсулина происходит автономно в зависимости от
                      уровня гликемии, считываемого с НМГ без необходимости
                      введения болюсов. Данные системы требуют разработки и
                      внедрения алгоритмов корректировки подачи инсулина, что
                      является весьма сложной задачей в условиях малоуправляемых
                      событий (физическая нагрузка, стресс и тд). Большинство
                      рандомизированных исследований по помпам с закрытым
                      контуром были небольшими и сообщали только о краткосрочных
                      результатах, часто в контролируемых условиях<sup>61</sup>.
                    </div>
                  </div>
                </div>
                <div class="second-block__tab1-line-violet-item">
                  <div class="second-block__tab1-line-violet-item-index">
                    <span>
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png
                        `"
                      />
                    </span>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text my-auto">
                    <div class="second-block__tab1-line-violet-item-title">
                      Помпы с возможность введения двух гормональных препаратов:
                    </div>
                    <div class="second-block__tab1-line-violet-item-text">
                      Это системы с гибридным или полным замкнутым контуром, в
                      которых помимо инсулина также используется глюкагон или
                      другие пептиды (например, амилин), чтобы более точно
                      имитировать физиологию поджелудочной железы в зависимости
                      от колебаний гликемии<sup>61</sup>.
                    </div>
                  </div>
                </div>
              </div>
              <div class="fs-18 mt-10">
                Долгосрочная безопасность, эффективность и рентабельность
                искусственной поджелудочной железы на данный момент остаются до
                конца не изученными. Так, на основе краудсорсинга были
                разработано программное обеспечение с открытым исходным кодом,
                например, Open Artificial Pancreas System и Loop. Некоторые из
                этих систем получили одобрение FDA<sup>62,63</sup>. Тем не
                менее, пока системы с открытым исходным кодом вызывают опасения
                с точки зрения безопасности их применения, особенно среди менее
                технически подкованных пациентов. При желании пациентов
                использовать данные технологии, им должна быть предоставлена
                возможность прохождения специализированных школ по применению
                «помп с искусственным интеллектом».
              </div>
              <label
                for="second-block__tab1-additional2"
                class="button button_pink-light px-8 py-4 mx-auto mt-10"
              >
                Скрыть
              </label>
            </div>
          </div>
          <div v-if="secondTabsActive === 1">
            <div class="fs-18 mb-8">
              Периодический самоконтроль уровня глюкозы в крови с помощью
              глюкометра предоставляет дополнительную информацию для принятия
              решений о лечении<sup>1</sup>. Однако не рекомендуется
              исследование уровня глюкозы в крови с помощью глюкометров для
              <b class="fw-b" style="color: #d0006f">диагностики</b> СД, так как
              глюкометры не обладают достаточной точностью для убедительной
              постановки диагноза и могут привести к ошибкам<sup>3-6</sup>.
              <br />
              <br />
              Индивидуальные глюкометры должны соответствовать ГОСТ Р ИСО
              15197–2015 по аналитической и клинической точности<sup>4</sup>.
              При уровне глюкозы плазмы крови &lt;5,6 ммоль/л 95% измерений
              должны отклоняться от эталонного анализатора не более чем на ± 0,8
              ммоль/л, при уровне глюкозы плазмы крови ≥5,6 ммоль/л 95%
              измерений должны отклоняться от эталонного анализатора не более
              чем на ±15%<sup>4</sup>.
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <picture>
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph_mob.jpg
                    `"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph_tab.png
                    `"
                    media="(max-width: 1219px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph.png
                    `"
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mx-auto d-none d-xl-block"
                    style="max-width: 100%; width: 800px"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph.png
                    `"
                  />
                  <img
                    class="mx-auto d-none d-md-block d-xl-none"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph.png
                    `"
                    style="max-width: 100%; width: 100%"
                  />
                  <img
                    class="mx-auto d-block d-md-none"
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-graph.png
                    `"
                    style="width: 500px"
                  />
                </picture>
              </div>
              <div
                class="d-md-none pa-5 mt-5 mb-10"
                style="
                  border: 1px solid #d0006f;
                  box-shadow: 0px 30px 40px -20px #00000059;
                  background-color: #fff;
                  border-radius: 20px;
                "
              >
                <div class="fs-18 ff-rs fw-b mb-2" style="color: #d0006f">
                  Важно понимать!
                </div>
                <div class="fs-18 fw-b">
                  Мониторинг с помощью глюкометра проводится дискретно
                  (выборочно в разное время дня) и может быть упущена важная
                  информация о динамике уровня глюкозы<sup>3-8</sup>.
                </div>
              </div>
            </div>
            <div class="d-xl-flex mb-8">
              <div style="flex-basis: 60%" class="mr-4 mr-md-0">
                <div class="fw-b fs-18 mb-5">
                  Тщательный контроль гликемии подразумевает измерение с помощью
                  глюкометра не менее 6 раз в сутки<sup>3-6</sup>:
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Перед основными приемами пищи и через 2–3 часа после еды
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Перед сном, при пробуждении и ночью
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    При физических нагрузках (перед тренировками)
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    При подозрении на гипогликемию и после ее купирования
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Во время интеркуррентных заболеваний
                  </div>
                </div>
              </div>
              <div style="flex-basis: 40%" class="ml-4 ml-md-0 mt-md-8">
                <picture>
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-img_mob.png
                    `"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-img_tab.png
                    `"
                    media="(max-width: 1219px)"
                  />
                  <source
                    :srcset="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-img.png
                    `"
                    media="(min-width: 1220px)"
                  />
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab2-img.png
                    `"
                  />
                </picture>
              </div>
            </div>
            <div class="fs-18">
              Частота измерений гликемии может варьироваться в зависимости от
              индивидуальных потребностей. Например, пациенты с СД 2 типа,
              находящиеся на диетотерапии без сахароснижающих препаратов могут
              минимизировать контроль гликемии по согласованию с врачом, ввиду
              отсутствия рисков гипогликемических состояний в целом<sup>5</sup>.
              Однако в случае коррекции терапии и более интенсивного подхода к
              лечению, например, при инсулинотерапии, контроль с помощью
              глюкометра становится более интенсивным (не менее 6 раз в
              день)<sup>5</sup>.
              <br />
              Тщательный контроль позволяет ознакомиться с гликемической
              реакцией пациента на различные виды продуктов питания,
              реагирование на физические упражнения и стресс<sup>5</sup>.
            </div>
            <div
              class="mt-15 ff-rs fw-sb fs-28 mb-8"
              style="text-align: center; color: #d0006f"
            >
              Ограничения дискретного способа
            </div>
            <div class="d-xl-flex">
              <div style="flex-basis: 60%" class="mr-4 mr-md-0">
                <div class="block-with-rect">
                  <div class="fs-18 ff-r">
                    Гликемия меняется непрерывно в течение дня, поэтому даже
                    многократность самостоятельных исследований глюкометром до
                    10 раз в сутки не позволяет дать полную оценку изменений
                    гликемии и дискретные/случайные исследования дают лишь
                    приблизительную картину колебаний гликемии у
                    пациента<sup>1</sup>.
                    <br />
                    <br />
                    Снижение комплаентности приводит к ухудшению течения
                    заболевания и в долгосрочном перспективе повышению рисков
                    микро- и макрососулистых осложнений<sup>39</sup>.
                  </div>
                </div>
              </div>
              <div style="flex-basis: 40%" class="ml-4 ml-md-0 mt-md-8">
                <div class="fw-b fs-18 mb-5">
                  <span style="color: #d0006f">Ухудшение контроля</span>
                  дискретным способом (с помощью глюкометра) происходит из-за
                  <sup>39-42</sup>:
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">Инвазивности процедуры</div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Болезненных ощущений от прокола пальцев
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Прогрессии инфекционных заболеваний
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Психологическое выгорания
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fs-28 fw-sb ff-rs mb-5 mt-12"
              style="color: #3e1876; text-align: center"
            >
              Глюкометр
              <span style="color: #d0006f">vs</span> НМГ
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <picture>
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4_tab.png`"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4_tab.png`"
                    media="(max-width: 1219px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4.png`"
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mx-auto d-none d-md-none d-xl-block"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4.png`"
                    style="max-width: 1000px"
                  />
                  <img
                    class="mx-auto d-none d-md-block d-xl-none"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4.png`"
                    style="max-width: 100%; width: 100%"
                  />
                  <img
                    class="mx-auto d-block d-md-none"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table4.png`"
                    style="width: 500px"
                  />
                </picture>
              </div>
            </div>
          </div>
          <div v-if="secondTabsActive === 2">
            <div class="fs-18">
              Одной из основных задач в терапии СД является достижение целевых
              показателей гликемии, что значимо снижает риски развития и
              прогрессирования осложнений<sup>1</sup>.
            </div>
            <div
              class="mt-15 ff-rs fw-sb fs-28 mb-8"
              style="text-align: center; color: #d0006f"
            >
              Гликированный гемоглобин (HbA1C)
            </div>
            <div class="d-xl-flex">
              <div style="flex-basis: 60%" class="mr-4 mr-md-0">
                <div class="block-with-rect">
                  <div class="fs-18 ff-r">
                    В соответствие клиническими рекомендациями, одним из
                    ключевых лабораторных маркеров тяжести течения и
                    эффективности терапии СД считается уровень гликированного
                    гемоглобина (HbA1C), который отражает средний уровень
                    глюкозы в крови за предыдущие 60‒90 дней<sup>3-8</sup>.
                  </div>
                </div>
              </div>
              <div style="flex-basis: 40%" class="ml-4 ml-md-0 mt-md-8">
                <div class="fw-b fs-18 mb-5">
                  <span style="color: #d0006f">HbA1c </span>
                  является важным инструментом для
                  <sup>43</sup>:
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Более точного контроля гликемии
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Общей оценки эффективности терапии
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="mr-4" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-none d-md-block"
                    /><img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_purple.svg`"
                      class="d-md-none"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Прогнозирования риска поздних вторичных осложнений
                  </div>
                </div>
              </div>
            </div>
            <div class="d-xl-flex mt-15">
              <div style="flex-basis: 55%" class="mr-4 mr-md-0">
                <div class="fs-28 fw-sb mb-8">
                  На уровень гликированного гемоглобина (HbA1c) могут оказывать
                  влияние следующие факторы <sup>1,7,44</sup>
                </div>
                <div
                  class="px-8 px-xs-5 py-5"
                  style="
                    background-color: #ffffff;
                    box-shadow: 0px 30px 50px -30px #00000059;
                    border-radius: 20px;
                  "
                >
                  <div class="d-flex mb-5">
                    <div
                      class="mr-8 d-none d-xl-block"
                      style="flex-basis: 70px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon5.svg`"
                      />
                    </div>
                    <div
                      class="mr-5 d-xl-none"
                      style="flex-basis: 50px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon5.svg`"
                      />
                    </div>
                    <div class="fs-23 fw-sb my-auto">Понижают</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Беременность (II и III триместры)
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Гемолитические анемии</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Прием препаратов железа, витамина В12, эритропоэтинов,
                      витамина Е, С и других антиоксидантов в больших дозах
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Антиретровирусная терапия, лечение рибавирином и
                      интерфероном-альфа
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Острая кровопотеря, переливание крови или эритроцитарной
                      массы
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Ретикулоцитоз</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Ревматоидный артрит</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Хронические заболевания печени</div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_dark-green.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Выраженная гипертриглицеридемия
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex-basis: 45%" class="ml-4 ml-md-0 mt-md-5">
                <div
                  class="px-8 px-xs-5 py-5 mb-5"
                  style="
                    background-color: #ffffff;
                    box-shadow: 0px 30px 50px -30px #00000059;
                    border-radius: 20px;
                  "
                >
                  <div class="d-flex mb-5">
                    <div
                      class="mr-8 d-none d-xl-block"
                      style="flex-basis: 70px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon6.svg`"
                      />
                    </div>
                    <div
                      class="mr-5 d-xl-none"
                      style="flex-basis: 50px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon6.svg`"
                      />
                    </div>
                    <div class="fs-23 fw-sb my-auto">Повышают</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_red.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Анемии (дефицит железа, витамина В12, фолиевой кислоты)
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_red.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Употребление алкоголя</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_red.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Прием салицилатов, опиодов</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_red.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Выраженная гипербилирубинемия</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_red.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Курение</div>
                  </div>
                </div>
                <div
                  class="px-8 px-xs-5 py-5 mb-n-25 mb-md-n-0"
                  style="
                    background-color: #ffffff;
                    box-shadow: 0px 30px 50px -30px #00000059;
                    border-radius: 20px;
                  "
                >
                  <div class="d-flex mb-5">
                    <div
                      class="mr-8 d-none d-xl-block"
                      style="flex-basis: 70px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon7.svg`"
                      />
                    </div>
                    <div
                      class="mr-5 d-xl-none"
                      style="flex-basis: 50px; flex-shrink: 0"
                    >
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/pink-block__icon7.svg`"
                      />
                    </div>
                    <div class="fs-23 fw-sb my-auto">Понижают или повышают</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_gray.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Гемоглобинопатии</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_gray.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Терминальная почечная недостаточность
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_gray.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Генетические факторы</div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_gray.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Фетальный гемоглобин, метгемоглобин
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4 mt-1" style="flex-shrink: 0">
                      <img
                        style="width: 20px"
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_gray.svg`"
                      />
                    </div>
                    <div class="fs-18 ff-r">Перенесенная спленэктомия</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-15 fs-18">
              Уровень гликированного гемоглобина (HbA1c) ≤6,0% считается
              нормальным (ВОЗ).
              <br />
              <br />
              Целевому уровню
              <b class="fw-b" style="color: #d0006f">HbA1c</b> &lt;7%
              соответствуют значения глюкозы в крови от 4 до 8 ммоль/л перед
              приемом пищи и от 4 до 10 ммоль/л в целом.
            </div>
            <div
              class="fs-23 fw-sb mt-6"
              style="color: #d0006f; font-style: italic"
            >
              При этом целевые показатели HbA1c необходимо определять
              индивидуально для каждого пациента<sup>3-8</sup>
            </div>
            <div class="mt-6 fs-18">
              Согласно рекомендациям ISPAD и ADA для большинства детей и
              подростков рекомендовано достижение целевого уровня HbA1c &lt;7%.
              Данные значения обеспечивают снижение риска микро-и
              макрососудистых осложнений СД, в частности развития ретинопатии. В
              опубликованных ранее клинических рекомендациях ADA устанавливала
              более высокие целевые показатели HbA1c для детей раннего возраста
              из-за опасений риска эпизодов гипогликемии и ее неблагоприятного
              воздействие на развитие нервной системы.
              <br />
              <br />
              Однако, в последние годы было показано, что при более низких
              показателях HbA1c, регистрируются определенные дополнительные
              преимущества. При этом целевой показатель HbA1c &lt;7% существенно
              не увеличивает риск тяжелой гипогликемии, а значения HbA1c
              &lt;6,5% могут быть безопасно достигнуты при соблюдении ряда
              условий<sup>46-51</sup>.
            </div>
            <div class="second-block__tab1-line-violet mt-12">
              <div class="second-block__tab1-line-violet-item">
                <div class="second-block__tab1-line-violet-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png`"
                    />
                  </span>
                </div>
                <div class="second-block__tab1-line-violet-item-text my-auto">
                  <div class="second-block__tab1-line-violet-item-title">
                    Целевые показатели HbA1C &lt;6,5%<sup>52-54</sup>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text">
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Для всех детей в период «медового месяца» (этап после
                        манифестации СД и инициации инсулинотерапии,
                        проявляющийся в виде временной стабилизации уровня
                        глюкозы в крови).
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        В случае, когда данный уровень может быть достигнут без
                        риска тяжелой гипогликемии или применения чрезмерной
                        терапии.
                      </div>
                    </div>
                    Более низкие уровни
                    <b class="fw-b" style="color: #d0006f">HbA1C</b> часто
                    достижимы при использовании современных технологий, включая
                    новые инсулины, интеллектуальные помпы, датчики непрерывного
                    уровня глюкозы и контролируемую алгоритмом доставку
                    инсулина.
                  </div>
                </div>
              </div>
              <div class="second-block__tab1-line-violet-item">
                <div class="second-block__tab1-line-violet-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png`"
                    />
                  </span>
                </div>
                <div class="second-block__tab1-line-violet-item-text my-auto">
                  <div class="second-block__tab1-line-violet-item-title">
                    Целевые показатели HbA1C 6,5%–7%<sup>52-54</sup>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text">
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Для маленьких детей, с ограниченным восприятием
                        симптомов гипогликемии.
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        У больных, которых нет доступа к инструментам для
                        оптимальной доставки инсулина (аналоговые препараты
                        инсулина, средства для регулярного контроля концентрации
                        глюкозы в капиллярной крови, CGM или инсулиновые помпы).
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        В случаях, когда образ жизни или особенности
                        психического развития, могут препятствовать или
                        усугубляться на фоне достижения более низких целевых
                        показателей.
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="mr-4 mt-1" style="flex-shrink: 0">
                        <img
                          style="width: 20px"
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        Для тех, у кого уровень
                        <b class="fw-b" style="color: #d0006f">HA1C</b> не
                        отражает общее состояние контроля течения заболевания
                        (например, у пациентов с «высоким уровнем гликемии»).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="second-block__tab1-line-violet-item">
                <div class="second-block__tab1-line-violet-item-index">
                  <span>
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/line-ellipse-violet.png`"
                    />
                  </span>
                </div>
                <div class="second-block__tab1-line-violet-item-text my-auto">
                  <div class="second-block__tab1-line-violet-item-title">
                    Целевые показатели HbA1C &lt;8%<sup>1</sup>
                  </div>
                  <div class="second-block__tab1-line-violet-item-text">
                    Может быть целесообразным для пациентов с тяжелой
                    гипогликемией в анамнезе, ограниченной ожидаемой
                    продолжительностью жизни или выраженной коморбидностью.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fs-23 fw-sb mt-6"
              style="color: #d0006f; font-style: italic"
            >
              Выбор целевого показателя HbA1c необходимо всегда рассматривать,
              как компромисс между риском развития гипо- и гипергликемии и их
              последствиями, ожидаемой пользой снижения риска в отношении
              обусловленных СД острых и хронических осложнений, предпочтений
              пациента и приверженности терапии<sup>1</sup>.
            </div>
            <div
              class="fs-28 fw-sb ff-rs mt-15 mb-8"
              style="text-align: center"
            >
              Алгоритм индивидуализированного выбора целей терапии по HbA1С
              <sup>1-13</sup>
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table5.png`" />
              </div>
            </div>
            <a
              :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC_%D0%B8%D0%BD%D0%B4%D0%B8%D0%B2%D0%B8%D0%B4%D1%83%D0%B0%D0%BB%D0%B8%D0%B7%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE_%D0%B2%D1%8B%D0%B1%D0%BE%D1%80%D0%B0_%D1%86%D0%B5%D0%BB%D0%B5%D0%B9_%D1%82%D0%B5%D1%80%D0%B0%D0%BF%D0%B8%D0%B8_%D0%BF%D0%BE_HbA1%D0%A1.pdf`"
              download="Алгоритм_индивидуализированного_выбора_целей_терапии_по_HbA1С.pdf"
              target="_blank"
              class="button button_pink-light py-4 mx-auto mt-8"
            >
              <span style="white-space: normal">
                Скачать алгоритм индивидуализированного выбора
                целей&nbsp;терапии&nbsp;по&nbsp;HbA1C&nbsp;<svg
                  style="vertical-align: middle"
                  class="d-inline"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                    fill="white"
                  />
                  <path
                    d="M12.0002 2C11.735 2 11.4806 2.10536 11.2931 2.29289C11.1055 2.48043 11.0002 2.73478 11.0002 3V13.59L7.71019 10.29C7.52188 10.1017 7.26649 9.99591 7.00019 9.99591C6.73388 9.99591 6.47849 10.1017 6.29019 10.29C6.10188 10.4783 5.99609 10.7337 5.99609 11C5.99609 11.2663 6.10188 11.5217 6.29019 11.71L11.2902 16.71C11.3831 16.8037 11.4937 16.8781 11.6156 16.9289C11.7375 16.9797 11.8682 17.0058 12.0002 17.0058C12.1322 17.0058 12.2629 16.9797 12.3848 16.9289C12.5066 16.8781 12.6172 16.8037 12.7102 16.71L17.7102 11.71C17.8034 11.6168 17.8774 11.5061 17.9278 11.3843C17.9783 11.2624 18.0043 11.1319 18.0043 11C18.0043 10.8681 17.9783 10.7376 17.9278 10.6158C17.8774 10.4939 17.8034 10.3832 17.7102 10.29C17.6169 10.1968 17.5063 10.1228 17.3844 10.0723C17.2626 10.0219 17.132 9.99591 17.0002 9.99591C16.8683 9.99591 16.7378 10.0219 16.6159 10.0723C16.4941 10.1228 16.3834 10.1968 16.2902 10.29L13.0002 13.59V3C13.0002 2.73478 12.8948 2.48043 12.7073 2.29289C12.5198 2.10536 12.2654 2 12.0002 2Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
            <div class="fs-18 mt-12">
              Помимо <b class="fw-b" style="color: #d0006f">HbA1C</b> для оценки
              компенсации СД используются показатели гликемического профиля, а
              также стандартизированные показатели
              <b class="fw-b" style="color: #d0006f"
                >непрерывного мониторирования гликемии (НМГ)</b
              >
              (время в целевом диапазоне, время выше целевого диапазона, время
              ниже целевого диапазона). Целевыми уровнями гликемии для
              большинства пациентов с СД 1 типа являются 3,9–10 ммоль/л. При
              этом важно учитывать не только процент времени нахождения в
              целевом диапазоне, но и вариабельность гликемии<sup>1,3,4</sup>.
              <br />
              <br />
              В отличие от HbA1C, целевой уровень которого может быть ложно
              достигнут на фоне частых гипогликемий при сохранении значимых
              гипергликемий, данные НМГ позволяют регистрировать и анализировать
              вариабельность уровня глюкозы и своевременно проводить коррекцию
              терапии<sup>3,4</sup>.
            </div>
            <div class="overflow mt-8">
              <div class="overflow__body">
                <img
                  style="width: 800px"
                  class="mx-auto d-block d-md-none d-xl-block"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab3-graph.jpg
                  `"
                />
                <img
                  style="width: 100%"
                  class="mx-auto d-none d-md-block d-xl-none"
                  :src="`
                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/second-block__tab3-graph.jpg
                  `"
                />
              </div>
            </div>
            <div class="mt-8 fs-18">
              Таким образом, для проведения полноценного анализа гликемического
              контроля у пациентов в СД 1 типа, в дополнение к HbA1C, необходимы
              последовательные измерения уровня глюкозы в крови, для чего
              наиболее рационально применять системы непрерывного мониторинга
              гликемии (НМГ)<sup>55</sup>.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="third-block">
      <div class="content">
        <div class="third-block__title mb-10 mb-md-8 mb-sm-5">
          Основные принципы <br class="d-xl-none" />
          терапии <br class="d-none d-xl-block" />
          сахарного диабета
        </div>
        <div class="fs-23 fw-b ff-rs mb-10">
          Выбор терапии зависит от типа диабета<sup>1-8</sup> и включает в себя
          основные принципы:
        </div>
        <div class="third-block__blocks d-none d-xl-grid">
          <div class="third-block__block">
            <div class="third-block__block-icon">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block1.svg`" />
            </div>
            <div class="third-block__block-text">
              Обучение самоконтролю и проведение <br />его в домашних условиях
            </div>
          </div>
          <div class="third-block__block">
            <div class="third-block__block-icon">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block2.svg`" />
            </div>
            <div class="third-block__block-text">
              Медикаментозная <br />терапия
            </div>
          </div>
          <div class="third-block__block">
            <div class="third-block__block-icon">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block3.svg`" />
            </div>
            <div class="third-block__block-text">Питание</div>
          </div>
          <div class="third-block__block">
            <div class="third-block__block-icon">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block4.svg`" />
            </div>
            <div class="third-block__block-text">Физические <br />нагрузки</div>
          </div>
          <div class="third-block__block">
            <div class="third-block__block-icon">
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block5.svg`" />
            </div>
            <div class="third-block__block-text">
              Психологическая <br />помощь
            </div>
          </div>
        </div>
        <swiper
          class="d-xl-none third-block__blocks-carousel"
          v-bind="swiperSettings2"
        >
          <swiper-slide>
            <div class="third-block__block">
              <div class="third-block__block-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block1.svg`"
                />
              </div>
              <div class="third-block__block-text">
                Обучение самоконтролю <br />и проведение его в домашних условиях
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="third-block__block">
              <div class="third-block__block-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block2.svg`"
                />
              </div>
              <div class="third-block__block-text">
                Медикаментозная <br />терапия
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="third-block__block">
              <div class="third-block__block-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block3.svg`"
                />
              </div>
              <div class="third-block__block-text">Питание</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="third-block__block">
              <div class="third-block__block-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block4.svg`"
                />
              </div>
              <div class="third-block__block-text">
                Физические <br />нагрузки
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="third-block__block">
              <div class="third-block__block-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__block5.svg`"
                />
              </div>
              <div class="third-block__block-text">
                Психологическая <br />помощь
              </div>
            </div>
          </swiper-slide>

          <div slot="container-end">
            <div class="slider-dots">
              <div class="slider-dots__row"></div>
              <div class="slider-dots__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/swipe-left_violet.svg`" />
              </div>
            </div>
          </div>
        </swiper>
        <div class="third-block__tabs">
          <div class="third-block__tabs-list">
            <div
              class="third-block__tabs-tab"
              @click="changeBlock3Tabs(0)"
              :class="{
                'third-block__tabs-tab_active': block3ActiveTab === 0,
              }"
            >
              СД 1 типа
            </div>
            <div
              class="third-block__tabs-tab"
              @click="changeBlock3Tabs(1)"
              :class="{
                'third-block__tabs-tab_active': block3ActiveTab === 1,
              }"
            >
              СД 2 типа
            </div>
          </div>
          <div class="third-block__tabs-arrows d-none d-xl-flex">
            <div
              @click="
                changeBlock3Tabs(
                  block3ActiveTab > 0 ? block3ActiveTab - 1 : block3ActiveTab
                )
              "
              class="third-block__tabs-arrow third-block__tabs-arrow_left"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
            <div
              @click="
                changeBlock3Tabs(
                  block3ActiveTab < 1 ? block3ActiveTab + 1 : block3ActiveTab
                )
              "
              class="third-block__tabs-arrow third-block__tabs-arrow_right"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="third-block__tabs-body">
          <div v-if="block3ActiveTab === 0">
            <div class="d-flex">
              <div class="mr-4 mr-md-0" style="flex-basis: 100%">
                <form>
                  <div
                    class="third-block__dropdown third-block__dropdown_white"
                  >
                    <input
                      type="radio"
                      name="dropdown_third-block"
                      id="dropdown_third-block-1"
                    />
                    <div class="third-block__dropdown-head-wrap">
                      <input type="reset" class="third-block__dropdown-clear" />
                      <label
                        for="dropdown_third-block-1"
                        class="third-block__dropdown-head"
                      >
                        <div class="third-block__dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M30.6493 13.1584L29.1741 14.6069L25.4828 10.9529L29.5725 6.84569C29.2343 6.50369 28.7325 6.15149 28.5979 5.67132C28.4244 5.05209 28.8327 4.4596 29.43 4.30137C30.2703 4.20623 30.815 5.04639 31.3666 5.5992L34.9392 9.14137C35.1848 9.38194 35.4689 9.61294 35.6898 9.87494C36.3001 10.5983 35.7191 11.5244 34.8682 11.5927C34.1845 11.6476 33.7472 10.9388 33.2733 10.5402L30.6493 13.1584Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8.77003 33.0335L8.71659 32.9822C8.65184 32.9395 8.52453 33.1133 8.47478 33.1598L5.50989 36.0928C5.37896 36.222 5.24919 36.3573 5.0931 36.4565C4.1163 37.0776 2.96053 35.9064 3.60036 34.9545C3.70452 34.7996 3.8453 34.6838 3.98048 34.5576L7.07653 31.507C6.56684 30.7908 6.53984 30.2881 7.18684 29.6399L8.73716 28.0575C8.24172 26.7547 8.16409 25.2266 8.92678 24.0023C9.21359 23.5418 9.65903 23.1837 10.04 22.8029L19.8299 13.0432C20.235 12.6442 20.652 12.2397 21.0177 11.8044C20.7397 11.508 20.4516 11.2278 20.3808 10.8099C20.2635 10.1177 20.7508 9.56504 21.418 9.45791L21.582 9.4541C22.079 9.46791 22.4211 9.8686 22.7445 10.1943L30.239 17.7297C31.299 18.8915 29.6088 20.6626 28.2957 19.1644C27.5558 19.7475 26.9392 20.4742 26.2738 21.1365L18.0329 29.344C17.5348 29.8484 17.0481 30.4016 16.5054 30.857C15.3352 31.8392 13.5294 31.9792 12.1633 31.353C12.0697 31.3101 11.069 32.338 10.8863 32.5202C10.2336 33.171 9.69434 33.7194 8.77003 33.0335ZM22.4718 13.377C22.2028 13.6705 21.8798 13.9224 21.6402 14.2398C22.0303 14.5984 22.5795 14.9971 22.6047 15.5735C22.6551 16.723 21.398 17.1929 20.5743 16.4189L20.0478 15.8781L18.7728 17.1359C19.2513 17.7082 20.2247 18.2839 20.1486 19.0781C20.0852 19.7397 19.5387 20.2522 18.8647 20.1857C18.3722 20.1371 18.0408 19.7749 17.7102 19.4442L17.0805 18.807L15.875 19.992L16.4575 20.5698C16.8204 20.9223 17.2007 21.2554 17.1933 21.8025C17.1834 22.5319 16.5563 23.0416 15.852 23.0057C15.3709 22.9811 15.0707 22.654 14.7637 22.3256L14.2073 21.7172L11.2373 24.6332L11.9145 25.2599L15.491 28.8394L26.7459 17.6313L22.4718 13.377Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="third-block__dropdown-head-title">
                          Заместительная инсулинотерапия
                        </div>
                        <div class="third-block__dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                    </div>

                    <div class="third-block__dropdown-body">
                      <div class="fs-18 mb-5">
                        Заместительная инсулинотерапия является основным и
                        неотъемлемым компонентом и единственным медикаментозным
                        методом лечения СД 1 типа<sup>3,4</sup>.
                      </div>
                      <blockquote class="mb-5">
                        <div class="fs-18 fw-b">
                          Основная цель инсулинотерапии во всех возрастных
                          группах ‒ достижение и поддержание близкого к
                          физиологическому уровня инсулинемии и оптимальный
                          гликемический контроль<sup>3,4</sup>.
                        </div>
                      </blockquote>
                      <div class="fs-18 mb-5">
                        Дозы препаратов инсулина и схема введения подбирается
                        индивидуально для каждого пациента, исходя из возраста,
                        профиля действия инсулина, индивидуальной переносимости,
                        а также на основании данных гликемического контроля
                        (показателей гликемии и уровня НbА1с)<sup>3,4</sup>.
                      </div>
                      <div class="fs-18 mb-5">
                        Дозы препаратов инсулина и схема их введения могут
                        значительно варьировать у разных пациентов с СД 1 типа.
                        В период ремиссии дозы препаратов инсулина могут быть
                        минимальными, вплоть до полной отмены на определенное
                        время под динамическим контролем<sup>3,4</sup>.
                      </div>
                      <blockquote class="mb-5">
                        <div class="fs-18 fw-b">
                          Окончательное решение о схеме и дозах инсулина строго
                          зависит от показателей гликемии и уровня
                          НbА1с<sup>3,4</sup>.
                        </div>
                      </blockquote>
                      <div class="fs-18">
                        Существуют различные схемы инсулинотерапии, однако в
                        последние годы, все больше предпочтение отдается
                        применению инсулиновых помп. Помповая инсулинотерапия
                        (НППИ) позволяет лучше всего имитировать физиологический
                        профиль инсулинемии. В режиме НПИИ инсулин вводится
                        подкожно, непрерывно путем предварительно
                        запрограммированной базальной скорости и введением
                        отдельных болюсов на приемы пищи и в случае
                        гипергликемии<sup>3,4</sup>.
                      </div>
                    </div>
                  </div>
                  <div
                    class="third-block__dropdown third-block__dropdown_white"
                  >
                    <input
                      type="radio"
                      name="dropdown_third-block"
                      id="dropdown_third-block-2"
                    />
                    <div class="third-block__dropdown-head-wrap">
                      <input type="reset" class="third-block__dropdown-clear" />
                      <label
                        for="dropdown_third-block-2"
                        class="third-block__dropdown-head"
                      >
                        <div class="third-block__dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M34.6862 4.24902H18.87C17.0397 4.24902 15.5563 5.73273 15.5563 7.56277V15.3535C16.3923 15.769 17.2957 16.2184 18.1389 16.6375L19.429 14.0562C20.2101 12.4954 22.1143 11.8612 23.6746 12.6409C25.2383 13.4234 25.8719 15.3224 25.0891 16.8863L22.7894 21.4868C22.8504 22.0958 22.7963 22.7096 22.6246 23.3037H24.1429L20.1384 34.3153C19.9008 34.9684 20.36 35.7306 21.1299 35.7306C21.5603 35.7306 21.9651 35.465 22.1206 35.036L26.3871 23.3037H27.1263L31.4362 35.0396C31.593 35.4664 31.9968 35.7306 32.4269 35.7306C33.1462 35.7306 33.6736 35.0121 33.4162 34.3126L29.3732 23.3037H34.6862C36.5163 23.3037 38 21.82 38 19.99V7.56277C38 5.73273 36.5163 4.24902 34.6862 4.24902ZM32.4263 18.7853H27.6942C27.112 18.7853 26.6396 18.3129 26.6396 17.7306C26.6396 17.1478 27.112 16.6759 27.6942 16.6759H32.4263C33.0092 16.6759 33.481 17.1478 33.481 17.7306C33.481 18.3129 33.0092 18.7853 32.4263 18.7853ZM32.4263 14.8311H27.6942C27.112 14.8311 26.6396 14.3592 26.6396 13.7764C26.6396 13.1941 27.112 12.7217 27.6942 12.7217H32.4263C33.0092 12.7217 33.481 13.1941 33.481 13.7764C33.481 14.3592 33.0092 14.8311 32.4263 14.8311ZM32.4263 10.8773H21.1299C20.5471 10.8773 20.0752 10.4049 20.0752 9.82266C20.0752 9.24039 20.5471 8.76797 21.1299 8.76797H32.4263C33.0092 8.76797 33.481 9.24039 33.481 9.82266C33.481 10.4049 33.0092 10.8773 32.4263 10.8773Z"
                              fill="currentColor"
                            />
                            <path
                              d="M20.557 22.8513C20.7476 22.293 20.7462 21.7096 20.5853 21.1773L23.2028 15.9429C23.4632 15.4218 23.2522 14.7882 22.7312 14.5278C22.2104 14.2672 21.5768 14.4784 21.3162 14.9994L19.0837 19.4637C17.7495 18.7993 15.7222 17.7913 14.0891 16.9792C12.5697 16.2236 11.4853 15.546 9.63522 15.546H8.70331C10.1439 15.546 11.4296 14.883 12.2711 13.8453C12.9133 13.0557 13.2972 12.0493 13.2972 10.9526C13.2972 8.41504 11.2406 6.3584 8.70276 6.3584C6.16602 6.3584 4.10938 8.41504 4.10938 10.9526C4.10938 12.1713 4.58398 13.2787 5.35825 14.1005C6.1954 14.9906 7.3844 15.546 8.70248 15.5463C6.93533 15.5463 5.20773 16.2623 3.9649 17.5084C2.69791 18.7721 2 20.4558 2 22.2493V25.6383C2 27.0973 2.9473 28.3387 4.25934 28.7804V34.6757C4.25934 35.2582 4.73148 35.7303 5.31403 35.7303H12.092C12.6746 35.7303 13.1467 35.2582 13.1467 34.6757V22.8255C14.1468 23.3254 15.4604 23.9821 16.5234 24.5139C17.2685 24.8863 18.1392 24.9198 18.9156 24.6039C19.6899 24.2853 20.2867 23.6512 20.5529 22.8637C20.5543 22.8596 20.5556 22.8555 20.557 22.8513Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="third-block__dropdown-head-title">
                          Обучение пациента
                        </div>
                        <div class="third-block__dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                    </div>

                    <div class="third-block__dropdown-body">
                      <blockquote class="mb-5">
                        <div class="fs-18 fw-b">
                          Обучение пациента является необходимым элементом
                          успешного лечения и контроля СД, которое должно быть
                          доступно для всех пациентов с СД1 и их родителей, в
                          случае если заболевание манифестировало в детском
                          возрасте<sup>3,4</sup>.
                        </div>
                      </blockquote>
                      <div class="fs-18">
                        Цели и задачи обучения должны быть конкретизированы в
                        соответствии с актуальным состоянием пациента. Обучение
                        должно быть адаптировано для каждого человека в
                        соответствии с возрастом, длительностью СД, образом
                        жизни, способностью к обучению и др. Обучение должно
                        быть непрерывным, в «Школу для пациентов с сахарным
                        диабетом» направляются пациенты, не проходившие обучения
                        (первичный цикл), или уже прошедшие обучение (повторные
                        циклы), для поддержания уровня знаний и мотивации или
                        при появлении новых терапевтических целей и
                        технологий<sup>3,4</sup>.
                      </div>
                    </div>
                  </div>
                  <div
                    class="third-block__dropdown third-block__dropdown_white"
                  >
                    <input
                      type="radio"
                      name="dropdown_third-block"
                      id="dropdown_third-block-3"
                    />
                    <div class="third-block__dropdown-head-wrap">
                      <input type="reset" class="third-block__dropdown-clear" />
                      <label
                        for="dropdown_third-block-3"
                        class="third-block__dropdown-head"
                      >
                        <div class="third-block__dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.6421 11.7502C20.1786 12.6221 20.5266 13.6086 20.6511 14.6345L25.3241 8.9975C26.0891 8.0743 27.1353 7.43234 28.2877 7.15813C27.2513 5.19008 25.187 3.86328 22.8125 3.86328C21.0454 3.87284 19.6174 4.30379 18.3477 5.19852C19.1696 6.20117 19.6941 7.39578 19.8777 8.69094C20.0246 9.73086 19.943 10.768 19.6421 11.7502Z"
                              fill="currentColor"
                            />
                            <path
                              d="M3.32521 16.9419H13.5009L11.3564 14.777H7.43779C6.86685 14.777 6.40419 14.3144 6.40419 13.7434C6.40419 13.1725 6.86685 12.7098 7.43779 12.7098H9.28912L5.81568 9.2357C5.41208 8.8321 5.41208 8.17749 5.81638 7.7739C6.21998 7.37031 6.87458 7.37031 7.27818 7.7739L10.7509 11.2473V9.39601C10.7509 8.82507 11.2136 8.36241 11.7845 8.36241C12.3554 8.36241 12.8188 8.82507 12.8188 9.39601V13.3152L16.5018 16.9419H18.3812C18.8882 15.2601 18.4797 13.3778 17.2358 12.0594C18.2729 10.1498 17.9622 7.74507 16.371 6.1539C14.7791 4.56132 12.3765 4.25054 10.4676 5.28835C8.57439 3.44286 5.46728 3.42584 3.56287 5.24687C2.57357 6.18273 2.0188 7.44765 2.00052 8.80679C1.98154 10.1406 2.48146 11.3964 3.40677 12.3491C2.6256 13.7863 2.60802 15.5027 3.32521 16.9419Z"
                              fill="currentColor"
                            />
                            <path
                              d="M26.9484 10.3435L21.4788 16.9416H33.3652L33.4643 16.8593C34.1948 16.2532 34.6392 15.3617 34.6835 14.4131C34.7228 13.5726 34.444 12.7485 33.9134 12.1044C35.2493 11.644 36.5512 12.0162 36.6355 12.0414C37.1905 12.2116 37.7792 11.9016 37.952 11.3468C38.1252 10.7907 37.8148 10.1995 37.2587 10.0262C37.1665 9.99751 35.7767 9.57979 34.124 9.87679C34.421 8.22388 34.0033 6.83401 33.9746 6.74176C33.8014 6.18566 33.2094 5.87256 32.6532 6.04581C32.0971 6.21899 31.7858 6.80765 31.959 7.36382C31.981 7.43729 32.3558 8.73533 31.9017 10.0719C31.2329 9.42594 30.3235 9.08022 29.3932 9.1236C28.4446 9.16853 27.5537 9.6129 26.9484 10.3435Z"
                              fill="currentColor"
                            />
                            <path
                              d="M36.9448 19.0508H3.05469C2.4725 19.0508 2 19.4837 2 20.0164C2 24.2268 3.79227 28.1844 7.04563 31.1615C7.3332 31.4247 7.62852 31.6768 7.93227 31.918H32.0671C32.3709 31.6768 32.6662 31.4247 32.9537 31.1615C36.2071 28.1844 37.9994 24.2268 37.9994 20.0158C37.9994 19.4831 37.5269 19.0508 36.9448 19.0508Z"
                              fill="currentColor"
                            />
                            <path
                              d="M33.0074 34.0273H6.99194C6.40976 34.0273 5.93726 34.4998 5.93726 35.082C5.93726 35.6642 6.40976 36.1367 6.99194 36.1367H33.0075C33.5897 36.1367 34.0622 35.6642 34.0622 35.082C34.0621 34.4998 33.5896 34.0273 33.0074 34.0273Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="third-block__dropdown-head-title">
                          Диетотерапия
                        </div>
                        <div class="third-block__dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                    </div>

                    <div class="third-block__dropdown-body">
                      <div class="fs-18">
                        Диетотерапия является одним из элементов лечения СД.
                        Диетические рекомендации должны быть основаны на
                        принципах здорового питания и подходить для всех
                        пациентов с СД и их семей с целью улучшения результатов
                        контроля заболевания и снижения сердечно-сосудистых
                        рисков. Пищевые рекомендации должны быть адаптированы к
                        культурным, этническим и семейным традициям, а также
                        учитывать когнитивные и психосоциальные аспекты
                        пациента<sup>3,4</sup>. Необходимо предоставлять
                        рекомендации по питанию в случаях управления как
                        регулярной, так и непредвиденной физической активностью,
                        и для достижения индивидуальных целей в соревновательных
                        видах спорта<sup>3,4</sup>. Обучение пациентов подсчету
                        количества углеводов в питании (хлебных единиц, ХЕ)
                        также является неотъемлемым компонентом компенсации
                        СД<sup>3,4</sup>.
                      </div>
                    </div>
                  </div>
                  <div
                    class="third-block__dropdown third-block__dropdown_white"
                  >
                    <input
                      type="radio"
                      name="dropdown_third-block"
                      id="dropdown_third-block-4"
                    />
                    <div class="third-block__dropdown-head-wrap">
                      <input type="reset" class="third-block__dropdown-clear" />
                      <label
                        for="dropdown_third-block-4"
                        class="third-block__dropdown-head"
                      >
                        <div class="third-block__dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M34.008 15.0704L36.286 14.2612C36.6396 14.132 36.8233 13.7444 36.7008 13.3908C36.5717 13.0372 36.1841 12.8536 35.8304 12.9828L31.0433 14.6828L29.1325 15.3628L26.256 16.3828C25.9025 16.512 25.7189 16.8996 25.8412 17.2532C25.9433 17.532 26.2017 17.7088 26.4805 17.7088C26.5552 17.7088 26.6368 17.6952 26.7117 17.668L28.6768 16.9676V25.454L22.8289 30.2004H6.40004C4.52326 30.2004 3 31.7236 3 33.6004C3 35.4704 4.52326 37.0004 6.40004 37.0004H36.32C36.6941 37.0004 37 36.6944 37 36.3204V25.7804C37 25.7124 36.9933 25.6512 36.9729 25.5968L34.008 15.0704ZM11.1396 34.2804H9.33084C8.95004 34.2804 8.65084 33.9744 8.65084 33.6004C8.65084 33.2196 8.95004 32.9204 9.33084 32.9204H11.1396C11.5204 32.9204 11.8196 33.2196 11.8196 33.6004C11.8196 33.9744 11.5204 34.2804 11.1396 34.2804ZM17.654 34.2804H15.8384C15.4644 34.2804 15.1584 33.9744 15.1584 33.6004C15.1584 33.2196 15.4644 32.9204 15.8384 32.9204H17.654C18.0281 32.9204 18.334 33.2196 18.334 33.6004C18.334 33.9744 18.0281 34.2804 17.654 34.2804ZM24.1617 34.2804H22.346C21.972 34.2804 21.666 33.9744 21.666 33.6004C21.666 33.2196 21.972 32.9204 22.346 32.9204H24.1617C24.5356 32.9204 24.8417 33.2196 24.8417 33.6004C24.8417 33.9744 24.5356 34.2804 24.1617 34.2804ZM30.6692 34.2804H28.8536C28.4797 34.2804 28.1736 33.9744 28.1736 33.6004C28.1736 33.2196 28.4797 32.9204 28.8536 32.9204H30.6692C31.0433 32.9204 31.3492 33.2196 31.3492 33.6004C31.3492 33.9744 31.0433 34.2804 30.6692 34.2804Z"
                              fill="currentColor"
                            />
                            <path
                              d="M15.6276 9.56878C17.4433 9.56878 18.9189 8.09999 18.9189 6.28437C18.9189 4.46879 17.4433 3 15.6276 3C13.8188 3 12.3433 4.46879 12.3433 6.28437C12.3433 8.09999 13.8188 9.56878 15.6276 9.56878Z"
                              fill="currentColor"
                            />
                            <path
                              d="M12.8261 28.8407L13.921 27.6031C14.0366 27.4671 14.0977 27.2835 14.0841 27.1067L13.8937 24.2915L16.3145 26.2907V28.8407H20.3945V24.8219C20.3945 24.6179 20.2993 24.4207 20.1429 24.2915L16.7497 21.5239L17.2597 18.1783C17.5521 18.2667 17.8513 18.3075 18.1505 18.3075C19.0482 18.3075 19.9253 17.9471 20.5645 17.2535L23.2029 14.4179C23.5769 14.0099 23.7741 13.4795 23.7469 12.9287C23.7198 12.3711 23.4749 11.8611 23.0601 11.4939C22.2305 10.7595 20.9793 10.8139 20.2177 11.6163L17.9261 14.0439L17.831 13.9963C17.7629 12.1671 16.2465 10.7051 14.3969 10.7051C12.6833 10.7051 11.2553 11.9359 10.9969 13.6291L9.62334 22.6595C9.60973 22.7139 9.60973 22.7751 9.61656 22.8295L9.98374 26.2907L8.05933 28.8407H12.8261Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="third-block__dropdown-head-title">
                          Физические нагрузки
                        </div>
                        <div class="third-block__dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                    </div>

                    <div class="third-block__dropdown-body">
                      <div class="fs-18 mb-5">
                        Рекомендации о необходимости регулярных физических
                        нагрузок являются неотъемлемой частью лечения СД1. К
                        преимуществам физических нагрузок, помимо положительного
                        влияния на HbA1c, также относится контроль веса,
                        снижение риска сердечно-сосудистых заболеваний и
                        улучшение самочувствия<sup>3,4</sup>.
                      </div>
                      <div class="fs-18">
                        Необходимо проводить постоянное обучение пациента и его
                        семьи поведению перед, во время и после физических
                        нагрузок в зависимости от уровня гликемии,
                        предшествующих доз введенного инсулина, предполагаемого
                        уровня и длительности физической нагрузки. Рекомендуемый
                        уровень физических нагрузок выбирается
                        <b class="fw-b">индивидуально</b><sup>3,4</sup>.
                      </div>
                    </div>
                  </div>
                  <div
                    class="third-block__dropdown third-block__dropdown_white"
                  >
                    <input
                      type="radio"
                      name="dropdown_third-block"
                      id="dropdown_third-block-5"
                    />
                    <div class="third-block__dropdown-head-wrap">
                      <input type="reset" class="third-block__dropdown-clear" />
                      <label
                        for="dropdown_third-block-5"
                        class="third-block__dropdown-head"
                      >
                        <div class="third-block__dropdown-head-icon">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.48005 32.012V35.5449C5.48005 36.3221 6.11596 36.958 6.89319 36.958H8.30632C9.08355 36.958 9.71946 36.3221 9.71946 35.5449V32.012H10.0727C10.3483 32.012 10.6027 31.9555 10.8429 31.8636V34.485C10.8429 35.1492 11.0973 35.771 11.5636 36.2373C12.0299 36.7036 12.6517 36.958 13.3159 36.958C14.6796 36.958 15.7889 35.8487 15.7889 34.485V26.3312C15.7889 25.8366 15.6617 25.3632 15.4215 24.9393H17.089C17.6895 24.9393 18.2477 24.7061 18.6787 24.2822C19.0956 23.8512 19.3217 23.293 19.3217 22.7065C19.3217 21.4771 18.3184 20.4738 17.089 20.4738H14.6442L11.6343 16.0577C11.2174 15.4359 10.638 14.9625 9.97383 14.6729C9.1966 15.1392 8.28513 15.4006 7.31006 15.4006C6.36326 15.4006 5.48005 15.1533 4.71696 14.7152C3.73483 15.2452 3.07066 16.2838 3.07066 17.4709V26.9601C3.07066 27.5183 3.28263 28.0199 3.61471 28.4085C3.24023 28.7901 3 29.3129 3 29.8923C3 31.0582 3.95387 32.012 5.1197 32.012H5.48005Z"
                              fill="currentColor"
                            />
                            <path
                              d="M35.3467 14.7152C34.5836 15.1533 33.7004 15.4006 32.7536 15.4006C31.7785 15.4006 30.8671 15.1392 30.0898 14.6729C29.4257 14.9625 28.8463 15.4359 28.4294 16.0577L25.4194 20.4738H22.9747C21.7453 20.4738 20.7419 21.4771 20.7419 22.7065C20.7419 23.293 20.968 23.8512 21.392 24.2892C21.8159 24.7061 22.3741 24.9393 22.9747 24.9393H24.6422C24.402 25.3562 24.2748 25.8366 24.2748 26.3312V34.485C24.2748 35.8487 25.3841 36.958 26.7478 36.958C27.4119 36.958 28.0337 36.7036 28.5001 36.2373C28.9664 35.771 29.2208 35.1492 29.2208 34.485V31.899C29.4257 31.9626 29.6376 32.012 29.8637 32.012H30.217V35.5449C30.217 36.3221 30.8529 36.958 31.6302 36.958H33.0433C33.8205 36.958 34.4564 36.3221 34.4564 35.5449V32.012H34.8097C35.9755 32.012 36.9294 31.0582 36.9294 29.8923C36.9294 29.3483 36.7174 28.8607 36.3854 28.4863C36.7598 28.0906 37.0001 27.5536 37.0001 26.9601V17.4709C36.993 16.2838 36.3288 15.2452 35.3467 14.7152Z"
                              fill="currentColor"
                            />
                            <path
                              d="M32.7536 13.9861C34.8945 13.9861 36.5761 12.2409 36.5761 10.1C36.5761 7.95909 34.8309 6.21387 32.69 6.21387C30.5491 6.21387 28.8674 7.95909 28.8674 10.1C28.8674 12.2409 30.436 13.9861 32.7536 13.9861Z"
                              fill="currentColor"
                            />
                            <path
                              d="M7.31703 13.9861C9.45793 13.9861 11.2032 12.2409 11.2032 10.1C11.2032 7.95909 9.39434 6.21387 7.25344 6.21387C5.11254 6.21387 3.43091 7.95909 3.43091 10.1C3.43091 12.2409 5.17613 13.9861 7.31703 13.9861Z"
                              fill="currentColor"
                            />
                            <path
                              d="M20.0354 12.9339C20.2968 12.9339 20.5512 12.9198 20.7844 12.8986L24.8471 14.9759C24.9531 15.0254 25.0591 15.0536 25.1722 15.0536C25.3205 15.0536 25.4618 15.0113 25.589 14.9194C25.801 14.764 25.9141 14.4955 25.8646 14.234L25.3841 11.2382C26.4934 10.3408 27.1011 9.20326 27.1011 7.98797C27.1011 5.26062 23.9286 3.04199 20.0354 3.04199C16.1422 3.04199 12.9697 5.26062 12.9697 7.98797C12.9697 10.7153 16.1422 12.9339 20.0354 12.9339ZM23.0525 7.2814H23.759C24.1476 7.2814 24.4656 7.59936 24.4656 7.98797C24.4656 8.37658 24.1476 8.69454 23.759 8.69454H23.0525C22.6638 8.69454 22.3459 8.37658 22.3459 7.98797C22.3459 7.59936 22.6568 7.2814 23.0525 7.2814ZM19.8729 7.2814H20.5795C20.9681 7.2814 21.286 7.59936 21.286 7.98797C21.286 8.37658 20.9681 8.69454 20.5795 8.69454H19.8729C19.4843 8.69454 19.1663 8.37658 19.1663 7.98797C19.1663 7.59936 19.4772 7.2814 19.8729 7.2814ZM16.5026 7.2814H17.2091C17.5977 7.2814 17.9157 7.59936 17.9157 7.98797C17.9157 8.37658 17.5977 8.69454 17.2091 8.69454H16.5026C16.114 8.69454 15.796 8.37658 15.796 7.98797C15.796 7.59936 16.1069 7.2814 16.5026 7.2814Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div class="third-block__dropdown-head-title">
                          Психосоциальная поддержка
                        </div>
                        <div class="third-block__dropdown-head-arrow">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </label>
                    </div>

                    <div class="third-block__dropdown-body">
                      <div class="fs-18">
                        Всем пациентам с впервые выявленным СД1 типа
                        рекомендуется прием (тестирование, консультация)
                        медицинского психолога для диагностики психологических
                        проблем, адаптации к заболеванию, достижения и
                        поддержания целей лечения. Молодые люди с впервые
                        диагностированным СД1 имеют большую частоту депрессии,
                        тревоги, психологических расстройств и расстройств
                        питания по сравнению со своими сверстниками без СД и
                        нуждаются в психологической поддержке<sup>3,4</sup>.
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="ml-4 d-none d-xl-block"
                style="flex-basis: 310px; flex-shrink: 0"
              >
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__img1.png`" />
              </div>
            </div>
          </div>
          <div v-if="block3ActiveTab === 1">
            <div class="d-flex">
              <div class="mr-4 mr-md-0" style="flex-basis: 100%">
                <div class="fs-23 fw-sb mb-5">
                  При лечении СД 2 необходимо придерживаться стратегии
                  <span style="color: #d0006f">многофакторного воздействия</span
                  ><sup>5-7</sup>:
                </div>
                <div class="third-block__line-violet mt-12">
                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        Адекватный контроль углеводного обмена.
                      </div>
                    </div>
                  </div>
                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        Достижение целевых показателей:
                        <br />
                        • артериального давления (АД); <br />
                        • липидного обмена.
                      </div>
                    </div>
                  </div>

                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        Использовать препараты, влияющие на снижение
                        сердечно-сосудистого риска.
                      </div>
                    </div>
                  </div>
                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        <b class="fw-b">Обучение</b> принципам управления
                        заболеванием и мотивация пациента должны начинаться
                        незамедлительно и сопровождать лечение на всем его
                        протяжении.
                      </div>
                    </div>
                  </div>
                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        Модификация образа жизни (включая физическую активность,
                        снижение массы тела при необходимости, отказ от курения
                        и др.). Изменение образа жизни является основой терапии
                        СД 2 и должно продолжаться на всем протяжении
                        заболевания.
                      </div>
                    </div>
                  </div>
                  <div class="third-block__line-violet-item">
                    <div class="third-block__line-violet-item-index">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      />
                    </div>
                    <div class="third-block__line-violet-item-text my-auto">
                      <div class="third-block__line-violet-item-text">
                        Психосоциальная поддержка.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="ml-4 d-none d-xl-block"
                style="flex-basis: 280px; flex-shrink: 0"
              >
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__img2.png`" />
              </div>
            </div>
            <div class="d-md-flex mt-8">
              <div
                class="mb-4 mx-auto d-block d-md-none d-xl-none"
                style="width: 200px"
              >
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__img2_mob.png`"
                />
              </div>
              <div style="flex-basis: 100%" class="mr-4 mr-xs-0">
                <div class="fs-18">
                  Многофакторные вмешательства могут не только значительно
                  снижать риск микрососудистых осложнений и сердечно-сосудистые
                  риски, но и, возможно, приводить к значимому снижению
                  смертности у пациентов с СД 2 <sup>5-7</sup>.
                </div>
              </div>
              <div
                class="ml-4 d-none d-md-block d-xl-none"
                style="flex-basis: 280px; flex-shrink: 0"
              >
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__img2_tab.png`"
                />
              </div>
            </div>
            <div class="ff-rs fs-23 fw-b mt-12 mb-5" style="color: #3e1876">
              Медикаментозная терапия СД 2 типа
            </div>
            <div class="fs-18 mb-12">
              Для медикаментозно лечения СД2 могут быть использованы различные
              группы гипогликемических препаратов<sup>5-7</sup>.
            </div>
            <div class="ff-rs fs-23 fw-b mb-5" style="color: #3e1876">
              Группы сахароснижающих препаратов и механизм их действия
              <sup>5-7</sup>
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <picture>
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6_tab.png`"
                    media="(max-width: 767px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6_tab.png`"
                    media="(max-width: 1219px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6.png`"
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mx-auto d-none d-md-none d-xl-block"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6.png`"
                    style="max-width: 800px"
                  />
                  <img
                    class="mx-auto d-none d-md-block d-xl-none"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6.png`"
                    style="max-width: 100%; width: 100%"
                  />
                  <img
                    class="mx-auto d-block d-md-none"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/table6.png`"
                    style="width: 600px"
                  />
                </picture>
              </div>
            </div>
            <div class="fs-18 mt-8 mb-8">
              Тактика медикаментозной терапии стратифицируется в зависимости от
              исходного уровня метаболического контроля. В зависимости от того,
              насколько исходный уровень превышает индивидуальный целевой
              показатель HbA1С конкретного пациента, на старте лечения могут
              быть избраны либо монотерапия, либо комбинированное лечение.
              Следует учитывать, что в некоторых клинических ситуациях (наличие
              АССЗ, ХСН, ХБП, ожирения, риск гипогликемий) определенные классы
              сахароснижающих средств (либо отдельные препараты) имеют
              доказанные преимущества<sup>5-7</sup>.
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__scheme1.jpg`"
                />
              </div>
            </div>
            <div class="fs-18 mt-8 mb-3">
              Индивидуальный подход к пациенту с определением индивидуального
              целевого уровня НbА1с должен являться основой выбора стратегии
              сахароснижающего лечения. Мониторинг эффективности терапии по
              уровню НbА1с рекомендуется осуществлять каждые 3 мес. Принятие
              решения об интенсификации не позже, чем через 6 мес. (у лиц с
              низким риском целесообразно не позже, чем через 3 мес.). Выбор
              препаратов в составе комбинаций или при непереносимости метформина
              проводится с учетом рекомендаций по персонализированному выбору.
              При использовании комбинаций следует учитывать рациональность
              сочетаний препаратов. Значительная часть сахароснижающих
              препаратов доступна в составе готовых комбинаций. Деинтенсификация
              и изменение терапии возможны на любом этапе лечения<sup>7</sup>.
            </div>
            <div class="fs-18_s mb-10" style="color: #69666b">
              Примечание: арГПП-1 – агонист рецепторов ГПП-1 (АТХ-классификация
              A10BJ аналоги глюкагоноподобного пептида-1); АССЗ –
              атеросклеротические сердечно-сосудистые заболевания; иНГЛТ-2 –
              ингибитор натрий-глюкозного котранспортера 2 типа
              (АТХ-классификация A10BK ингибиторы натрийзависимого переносчика
              глюкозы 2 типа); ХБП – хроническая болезнь почек; ХСН –
              хроническая сердечная недостаточность; HbA1c – гликированный
              гемоглобин.
            </div>
            <div class="overflow">
              <div class="overflow__body">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/third-block__scheme2.jpg`"
                />
              </div>
            </div>
            <div class="fs-18_s mt-5" style="color: #69666b">
              Примечание: арГПП-1 – агонист рецепторов ГПП-1 (АТХ-классификация
              A10BJ аналоги глюкагоноподобного пептида-1); АССЗ –
              атеросклеротические сердечно-сосудистые заболевания; иНГЛТ-2 –
              ингибитор натрий-глюкозного котранспортера 2 типа
              (АТХ-классификация A10BK ингибиторы натрийзависимого переносчика
              глюкозы 2 типа); иДПП-4 – ингибитор дипептидилпептидазы 4 типа
              (АТХ-классификация A10ВН ингибиторы дипептидилпептидазы-4); ТЗД –
              тиазолидиндионы (АТХ-классификация A10BGТиазолиндионы); ФВ ЛЖ –
              фракция выброса левого желудочка; ХБП – хроническая болезнь почек;
              ХСН – хроническая сердечная недостаточность.
              <br />
              * Указания на высокий риск АССЗ: возраст ≥55 лет с наличием 50%
              стеноза коронарных, каротидных артерий или артерий нижних
              конечностей или гипертрофия левого желудочка.
              <br />
              **Ингибиторы НГЛТ-2 были одобрены FDA в 2013 году для контроля
              гликемии при СД 2 типа и профилактики осложнений, в том числе у
              пациентов с ХБП<sup>65</sup>.
              <br />
              Адаптировано из: Алгоритмы специализированной медицинской помощи
              больным сахарным диабетом / Под редакцией И.И. Дедова, М.В.
              Шестаковой, А.Ю. Майорова. – 11-й выпуск. – М.; 2023. doi:
              <a href="" target="_blank">https://doi.org/10.14341/DM13042</a>
            </div>
            <a
              href="https://az-most.ru/therapeutic-areas/endocrinology/diabetes"
              target="_blank"
              class="mx-auto button button_pink-light mt-10 py-4 px-8"
            >
              <span style="white-space: normal; text-align: center"
                >Подробнее о лечении сахарного диабета 2 типа</span
              >
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="fourth-block" id="clin-cases">
      <div class="content">
        <div class="d-md-flex">
          <div>
            <div class="fourth-block__title">
              Клинические <br class="d-none d-xl-block" />ситуации*
            </div>
          </div>
          <div class="ml-auto mt-xs-4 mb-xs-16" v-if="false">
            <a href="#" class="button button_empty-pink-light py-4 px-8">
              Больше клинических ситуаций
            </a>
          </div>
        </div>

        <div class="tap-hint mb-4 mt-4 d-none d-md-flex">
          <div class="tap-hint__icon ml-auto mr-2">
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tap.svg`" />
          </div>
          <div class="tap-hint__text">Выберите клиническую ситуацию</div>
        </div>
      </div>
      <div class="fixed-tabs">
        <div class="content">
          <div class="fourth-block__tabs">
            <div
              class="fourth-block__arrow fourth-block__arrow_left d-xl-none"
              @click="
                choiceFourthTab(
                  fourthTabsActive > 0 ? fourthTabsActive - 1 : fourthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="fourth-block__tabs-row">
              <div
                class="fourth-block__tab"
                @click="choiceFourthTab(0)"
                :class="{
                  'fourth-block__tab_active': fourthTabsActive === 0,
                }"
              >
                <img
                  class="fourth-block__tab-back"
                  v-if="fourthTabsActive === 0"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back3.svg`"
                />
                <div class="fourth-block__tab-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__tab-icon1.png
                    `"
                  />
                </div>
                <div class="fourth-block__tab-text">Девочка, 6 лет</div>
              </div>
              <div
                class="fourth-block__tab"
                @click="choiceFourthTab(1)"
                :class="{
                  'fourth-block__tab_active fourth-block__tab_violet':
                    fourthTabsActive === 1,
                }"
              >
                <img
                  class="fourth-block__tab-back"
                  v-if="fourthTabsActive === 1"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back3.svg`"
                />
                <div class="fourth-block__tab-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__tab-icon2.png
                    `"
                  />
                </div>
                <div class="fourth-block__tab-text">
                  Мальчик подросток, 15 лет
                </div>
              </div>
              <div
                class="fourth-block__tab"
                @click="choiceFourthTab(2)"
                :class="{
                  'fourth-block__tab_active': fourthTabsActive === 2,
                }"
              >
                <img
                  class="fourth-block__tab-back"
                  v-if="fourthTabsActive === 2"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back3.svg`"
                />
                <div class="fourth-block__tab-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__tab-icon3.png
                    `"
                  />
                </div>
                <div class="fourth-block__tab-text">
                  Девочка подросток, 13 лет
                </div>
              </div>
            </div>

            <div
              class="fourth-block__arrow fourth-block__arrow_right d-xl-none"
              @click="
                choiceFourthTab(
                  fourthTabsActive < 2 ? fourthTabsActive + 1 : fourthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="fourth-block__tabs-body">
          <div v-if="fourthTabsActive === 0">
            <div class="fourth-block__case">
              <div class="fourth-block__case-row">
                <div class="fourth-block__case-child-block">
                  <div class="fourth-block__case-child">
                    <div
                      class="
                        fourth-block__case-child-text
                        px-8
                        pt-8
                        pb-md-8
                        px-md-0
                        pl-md-8
                        pa-xs-5
                      "
                    >
                      <div class="fs-18">
                        <b class="fw-b"
                          >В приемное отделение обратилась девочка Кристина 6
                          лет с мамой.</b
                        >
                        <br />
                        <br />
                        Жалобы на периодические головные боли
                        и&nbsp;головокружения, преимущественно в утренние часы.
                      </div>
                    </div>
                    <img
                      class="mt-8 mt-md-0"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__child1.png
                      `"
                    />
                  </div>
                  <div class="fourth-block__case-tip">
                    *гипотетический пациент. Источники литературы 1-13.
                  </div>
                </div>
                <div class="fourth-block__case-content">
                  <div class="fourth-block__case-tabs">
                    <div class="fourth-block__case-tabs-list">
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(0)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 0,
                        }"
                      >
                        Анамнез жизни
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(1)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 1,
                        }"
                      >
                        Семейный анамнез
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(2)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 2,
                        }"
                      >
                        Анамнез заболевания
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(3)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 3,
                        }"
                      >
                        Данные осмотра
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(4)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 4,
                        }"
                      >
                        Результаты обследования
                      </div>
                    </div>
                    <div class="fourth-block__case-tabs-arrows">
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab > 0
                              ? casesActiveTab - 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_left
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.25 4.5L3.75 12L11.25 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M5.25 12L20.25 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab < 4
                              ? casesActiveTab + 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_right
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 4.5L20.25 12L12.75 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M18.75 12L3.75 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="fourth-block__case-tabs-body">
                    <div v-if="casesActiveTab === 0">
                      <div class="fs-18">
                        Ребенок от 1й нормально протекавшей беременности. Роды в
                        срок.
                        <br />
                        <br />
                        При рождении: рост 50 см, вес 3300 г, Апгар 8/9.
                        Неонатальный период протекал без особенностей. Раннее
                        развитие по возрасту.
                        <br />
                        <br />
                        Перенесенные заболевания: ветряная оспа в 5 лет, ОРВИ
                        1–2 раза в год. С 4 лет наблюдается пульмонологом с
                        диагнозом бронхиальная астма, получает базисную терапию
                        (Будесонид). Обострения 1–2 раза в год. Аллергические
                        реакции на лекарственные препараты отрицает.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 1">
                      <div class="fs-18">
                        Родители здоровы, брак не близкородственный. У бабушки
                        по материнской линии СД 2 типа с 50 лет, артериальная
                        гипертензия. У Дедушки по отцовской линии ОИМ в 65 лет.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 2">
                      <div class="fs-18">
                        6 мес назад (в 5 лет 10 мес) после перенесенной ОРВИ
                        появились жалобы на повышенную жажду, учащенное
                        мочеиспускание. К врачам не обращались.
                        <br />
                        <br />
                        Спустя месяц жалоб обратили внимание на потерю веса,
                        девочка стала жаловаться на слабость, сонливость.
                        Консультирована педиатром в поликлинике. В общем анализе
                        крови без отклонений. В общем анализе мочи глюкозурия
                        +++, кетонурия ++. Гликемия 24 ммоль/л.
                        <br />
                        <br />
                        В экстренном порядке госпитализирована в
                        эндокринологическое отделение, где установлен диагноз СД
                        1 типа, впервые выявленный. КЩС при поступлении без
                        признаков ацидозе. Начата инсулинотерапия в
                        интенсифицированном режиме (Аспарт 0,5 ед на 1 ХЕ в
                        приемы пищи, Деглюдек 3 Ед в 9.00). В ходе
                        госпитализации проводился контроль гликемии глюкометром,
                        прошла школу СД, обучена подсчету ХЕ, технике измерения
                        гликемии и инъекций инсулина. Была выписана с
                        показателями гликемии 6–12 ммоль/л, липидный профиль –
                        без патологии.
                        <br />
                        <br />
                        Около месяца назад обратила внимание на эпизоды
                        слабости, стали выявляться гипогликемии 3,0–3,6 ммоль/л.
                        Самостоятельно скорректировала дозу инсулину, однако
                        эпизоды гипогликемии сохраняются, преимущественно утром
                        и после ужина. В последние дни использует НМГ в реальном
                        времени. На момент осмотра получает Деглюдек 2 Ед в
                        21.00 и Аспарт 0,3 ед на 1 ХЕ.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 3">
                      <div class="fs-18">
                        <div class="d-md-flex mb-8">
                          <div
                            style="flex-basis: 50%"
                            class="mr-4 mr-xs-0 mb-xs-5"
                          >
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Рост</div>
                              <div style="flex-basis: 50%; text-align: right">
                                113.4 см
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Скорость роста</div>
                              <div style="flex-basis: 50%; text-align: right">
                                4,3 см/год
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Вес</div>
                              <div style="flex-basis: 50%; text-align: right">
                                18.4 кг
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">ИМТ</div>
                              <div style="flex-basis: 50%; text-align: right">
                                14.3 кг/м&#178;
                              </div>
                            </div>
                          </div>
                          <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS роста</div>
                              <div style="flex-basis: 50%; text-align: right">
                                0.97
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS</div>
                              <div style="flex-basis: 50%; text-align: right">
                                1.82
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS веса</div>
                              <div style="flex-basis: 50%; text-align: right">
                                1.46
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS ИМТ</div>
                              <div style="flex-basis: 50%; text-align: right">
                                0.88
                              </div>
                            </div>
                          </div>
                        </div>
                        Состояние удовлетворительное. Телосложение
                        нормостеническое. Подкожно-жировая клетчатка развита
                        умеренно, распределена равномерно. Кожные покровы
                        чистые, умеренной влажности, без патологических
                        высыпаний. Слизистые нормальной окраски, чистые.
                        <br />
                        <br />
                        Костно-мышечная система патологии не выявлено.
                        Щитовидная железа расположена типично,
                        мягко-эластической консистенции, не увеличена, подвижна
                        при глотании, наличие узловых образований пальпаторно не
                        определяется, клинически эутиреоз. Симптомов нарушения
                        функции надпочечников нет.
                        <br />
                        <br />
                        <b class="fw-b">Сердечно-сосудистая система:</b> пульс
                        90 уд.\мин; артериальное давление 95 /65 мм.рт.ст.; тоны
                        сердца ясные, ритмичные; шум сердца не определяется.
                        <br />
                        <br />
                        <b class="fw-b">Дыхательная система:</b> грудная клетка
                        правильной формы; в акте дыхания вспомогательная
                        мускулатура не участвует; дистанционные хрипы не слышны;
                        дыхание везикулярное; хрипов нет.
                        <br />
                        <br />
                        <b class="fw-b">Система органов пищеварения:</b> язык
                        влажный, чистый, живот при поверхностной пальпации
                        мягкий, безболезненный; печень не увеличена; селезенка
                        не пальпируется; стул ежедневный.
                        <br />
                        <br />
                        <b class="fw-b">Мочеполовая система:</b> дизурических
                        явлений нет. Половые органы сформированы правильно, по
                        женскому типу, Таннер 1 (B 1, P 1) Me abs.
                      </div>
                    </div>

                    <div v-if="casesActiveTab === 4">
                      <div class="fs-18 mb-5">
                        Выберите методы первичного обследования в больнице при
                        поступлении и проанализируйте результаты.
                      </div>
                      <form>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-1"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-1"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Гликированный гемоглобин
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-1.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-2"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-2"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Клинический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-2.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-3"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-3"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Биохимические исследования крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-3.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-4"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-4"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Антитела к островковым клеткам
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-4.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-5"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-5"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Гормональный анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="fs-18">
                              С-пептид 0,01 нг/мл (норма 1,1-4,4).
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-6"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-6"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Гликемический профиль в стационаре
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-5.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-7"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-7"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Контроль гликированного гемоглобина (3 мес
                                назад)
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-6.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-8"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-8"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Данные НМГ
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow mb-5">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-7.jpg
                                  `"
                                />
                              </div>
                            </div>
                            <div class="overflow mb-5">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-8.jpg
                                  `"
                                />
                              </div>
                            </div>
                            <div class="fs-18_s mb-10" style="color: #9c9c9c">
                              Примечание: X – используйте клиническую оценку.
                            </div>
                            <div class="fs-23 fw-b ff-rs mb-5">
                              Сводка по отклонениям (ммоль/л/день)
                            </div>
                            <div class="overflow mb-5">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-9.jpg
                                  `"
                                />
                              </div>
                            </div>
                            <div class="fs-23 fw-b ff-rs mb-5">
                              Длительность распределения (чч:мм)
                            </div>
                            <div class="overflow mb-5">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_1-10.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="ff-rs fs-23 fw-b mt-6" style="color: #d0006f">
                    Вопросы
                  </div>
                  <Test
                    @refreshTest="refreshTest"
                    :key="testKey"
                    :questions="questions[0]"
                    :tips="tips[0]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="fourthTabsActive === 1">
            <div class="fourth-block__case">
              <div class="fourth-block__case-row">
                <div class="fourth-block__case-child-block">
                  <div
                    class="
                      fourth-block__case-child fourth-block__case-child_violet
                    "
                  >
                    <div
                      class="
                        fourth-block__case-child-text
                        px-8
                        pt-8
                        pb-md-8
                        px-md-0
                        pl-md-8
                        pa-xs-5
                      "
                    >
                      <div class="fs-18">
                        <b class="fw-b"
                          >На приеме мальчик Владислав 15 лет с родителями.</b
                        >
                        <br />
                        <br />
                        Наблюдается с диагнозом СД 1 типа, получает
                        инсулинотерапию. Жалобы на нестабильные показатели
                        гликемии.
                      </div>
                    </div>
                    <img
                      class="mt-8 mt-md-0"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__child2.png
                      `"
                    />
                  </div>
                  <div class="fourth-block__case-tip">
                    *гипотетический пациент. Источники литературы 1-13.
                  </div>
                </div>
                <div class="fourth-block__case-content">
                  <div class="fourth-block__case-tabs">
                    <div class="fourth-block__case-tabs-list">
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(0)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 0,
                        }"
                      >
                        Анамнез жизни
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(1)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 1,
                        }"
                      >
                        Семейный анамнез
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(2)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 2,
                        }"
                      >
                        Анамнез заболевания
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(3)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 3,
                        }"
                      >
                        Данные осмотра
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(4)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 4,
                        }"
                      >
                        Результаты обследования
                      </div>
                    </div>
                    <div class="fourth-block__case-tabs-arrows">
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab > 0
                              ? casesActiveTab - 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_left
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.25 4.5L3.75 12L11.25 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M5.25 12L20.25 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab < 4
                              ? casesActiveTab + 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_right
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 4.5L20.25 12L12.75 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M18.75 12L3.75 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="fourth-block__case-tabs-body">
                    <div v-if="casesActiveTab === 0">
                      <div class="fs-18">
                        Ребенок от 2-й беременности, родился на 36 неделе с
                        весом 1700 гр (SDS -2,8), рост 46 см. Апгар 7/8.
                        <br />
                        <br />
                        До 2-х недель находился в отделении патологии
                        новорожденных. Отмечалась умеренная прибавка в весе.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 1">
                      <div class="fs-18">
                        Родители здоровы, брак не близкородственный. Старший
                        брат (18 лет) здоров.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 2">
                      <div class="fs-18">
                        В возрасте 45 суток жизни появились жалобы на полиурию,
                        полифагию (все время был беспокойным и просил есть),
                        полидипсию (жадно пил воду). Было проведено обследование
                        – выявлена гипергликемия 30,6 ммоль/л. Госпитализирован
                        по месту жительства, где проводилась инсулинотерапия в/в
                        0,02–0,05 Ед/кг/час. Через неделю был переведен на п/к
                        введение инсулина 1,2 Ед/кг/сут. Потребность в инсулине
                        постепенно снижалась.
                        <br />
                        <br />
                        В возрасте 3х месяцев произведена полная отмена.
                        <br />
                        <br />
                        В дальнейшем проводился ежегодный контроль
                        гликированного гемоглобина и гликемии. Показатели стойко
                        были в пределах нормы.
                        <br />
                        <br />
                        В 14 лет при плановом обследовании гликемия выявлена
                        гипергликемия 11,3 ммоль/л, гликированный гемоглобин
                        8,2%. Анализ на диабетические антитела не проводился.
                        Установлен диагноз СД 1 типа. Начата инсулинотерапия
                        (Лантус 8 Ед/сут + Новорапид 2–4 Ед х 3 раза в день). На
                        этом фоне гликемия 7–11 ммоль/л, HbA1C 7,5–8,0%.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 3">
                      <div class="fs-18">
                        <div class="d-md-flex mb-8">
                          <div
                            style="flex-basis: 50%"
                            class="mr-4 mr-xs-0 mb-xs-5"
                          >
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Рост</div>
                              <div style="flex-basis: 50%; text-align: right">
                                175 см
                              </div>
                            </div>

                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Вес</div>
                              <div style="flex-basis: 50%; text-align: right">
                                63 кг
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">ИМТ</div>
                              <div style="flex-basis: 50%; text-align: right">
                                20.6 кг/м&#178;
                              </div>
                            </div>
                          </div>
                          <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS роста</div>
                              <div style="flex-basis: 50%; text-align: right">
                                1.3
                              </div>
                            </div>

                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS веса</div>
                              <div style="flex-basis: 50%; text-align: right">
                                0.7
                              </div>
                            </div>
                          </div>
                        </div>
                        Состояние удовлетворительное. Телосложение
                        нормостеническое. Подкожно-жировая клетчатка развита
                        умеренно, распределена равномерно. Кожные покровы
                        чистые, умеренной влажности, без патологических
                        высыпаний, единичные акнэ на лице и спине. Слизистые
                        нормальной окраски, чистые.
                        <br />
                        <br />
                        Костно-мышечная система патологии не выявлено.
                        Щитовидная железа расположена типично,
                        мягко-эластической консистенции, не увеличена, подвижна
                        при глотании, наличие узловых образований пальпаторно не
                        определяется, клинически эутиреоз. Симптомов нарушения
                        функции надпочечников нет.
                        <br />
                        <br />
                        <b class="fw-b">Сердечно-сосудистая система:</b> пульс
                        68 уд./мин; артериальное давление 115 /70 мм.рт.ст.;
                        тоны сердца ясные, ритмичные; шум сердца не
                        определяется.
                        <br />
                        <br />
                        <b class="fw-b">Дыхательная система:</b> грудная клетка
                        правильной формы; в акте дыхания вспомогательная
                        мускулатура не участвует; дистанционные хрипы не слышны;
                        дыхание везикулярное; хрипов нет.
                        <br />
                        <br />
                        <b class="fw-b">Система органов пищеварения:</b> язык
                        влажный, чистый, живот при поверхностной пальпации
                        мягкий, безболезненный; печень не увеличена; селезенка
                        не пальпируется; стул ежедневный.
                        <br />
                        <br />
                        <b class="fw-b">Мочеполовая система:</b> дизурических
                        явлений нет. Половые органы сформированы правильно, по
                        мужскому типу, Таннер 4 (G4, P2) Testes D=S=15 ml.
                      </div>
                    </div>

                    <div v-if="casesActiveTab === 4">
                      <div class="fs-18 mb-5">
                        Выберите методы первичного обследования в больнице при
                        поступлении и проанализируйте результаты.
                      </div>
                      <form>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_violet
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-1"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-1"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Гликированный гемоглобин
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_2-1.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_violet
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-2"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-2"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Биохимические исследования крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_2-2.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_violet
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-3"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-3"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Антитела к островковым клеткам
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_2-3.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_violet
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-4"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-4"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Данные НМГ
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_2-4.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="ff-rs fs-23 fw-b mt-6" style="color: #d0006f">
                    Вопросы
                  </div>
                  <Test
                    @refreshTest="refreshTest"
                    :key="testKey"
                    :questions="questions[1]"
                    :tips="tips[1]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="fourthTabsActive === 2">
            <div class="fourth-block__case">
              <div class="fourth-block__case-row">
                <div class="fourth-block__case-child-block">
                  <div class="fourth-block__case-child">
                    <div
                      class="
                        fourth-block__case-child-text
                        px-8
                        pt-8
                        pb-md-8
                        px-md-0
                        pl-md-8
                        pa-xs-5
                      "
                    >
                      <div class="fs-18">
                        <b class="fw-b">На приеме пациентка Ксения 13 лет.</b>
                        <br />
                        <br />
                        Направлена педиатром в связи со случайным выявлением
                        гипергликемии натощак (6,8 ммоль/л). Жалоб активно не
                        предъявляет.
                      </div>
                    </div>
                    <img
                      class="mt-8 mt-md-0"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/fourth-block__child3.png
                      `"
                    />
                  </div>
                  <div class="fourth-block__case-tip">
                    *гипотетический пациент. Источники литературы 1-13.
                  </div>
                </div>
                <div class="fourth-block__case-content">
                  <div class="fourth-block__case-tabs">
                    <div class="fourth-block__case-tabs-list">
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(0)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 0,
                        }"
                      >
                        Анамнез жизни
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(1)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 1,
                        }"
                      >
                        Семейный анамнез
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(2)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 2,
                        }"
                      >
                        Анамнез заболевания
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(3)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 3,
                        }"
                      >
                        Данные осмотра
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(4)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 4,
                        }"
                      >
                        Результаты обследования
                      </div>
                    </div>
                    <div class="fourth-block__case-tabs-arrows">
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab > 0
                              ? casesActiveTab - 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_left
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.25 4.5L3.75 12L11.25 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M5.25 12L20.25 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab < 4
                              ? casesActiveTab + 1
                              : casesActiveTab
                          )
                        "
                        class="
                          fourth-block__case-tabs-arrow
                          fourth-block__case-tabs-arrow_right
                        "
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 4.5L20.25 12L12.75 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M18.75 12L3.75 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="fourth-block__case-tabs-body">
                    <div v-if="casesActiveTab === 0">
                      <div class="fs-18">
                        Ребёнок от: 2 беременности.
                        <br />
                        <br />
                        Дети от предыдущих беременностей: здоровы.
                        <br />
                        <br />
                        Беременность протекала: на фоне токсикоза, угрозы
                        прерывания, мать носитель ЦМВ, ВПГ.
                        <br />
                        <br />
                        <b class="fw-b">Роды:</b> на 43 нед., Масса при
                        рождении: 4250 г, Рост при рождении: 57 см, По шкале
                        Апгар: 5/6.
                        <br />
                        <br />
                        При рождении диагностирована в/у инфекция, находилась в
                        стационаре 10 дней, получала антибактериальную терапию.
                        Отмечались эпизоды гипогликемии (минимально 2,4
                        ммоль/л).
                        <br />
                        <br />
                        <b class="fw-b">Перенесенные состояния:</b> ОРВИ, корь.
                        <br />
                        <br />
                        <b class="fw-b">Аллергологический анамнез:</b> без
                        особенностей, лекарственную и пищевую аллергию отрицает.
                        <br />
                        <br />
                        <b class="fw-b">Эпиданамнез:</b> Контакты с
                        инфекционными больными, tbc, гепатит, ВИЧ, вен.
                        заболевания, переливания крови отрицает.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 1">
                      <div class="fs-18">
                        Сибсы: старший брат, 12 лет, здоров; младший брат, 7
                        лет, здоров.
                        <br />
                        <br />
                        Брак не близкородственный.
                        <br />
                        <br />
                        У отца СД 2 типа с 24 лет, таблетированная терапия.
                        <br />
                        <br />
                        У бабушки по отцовской линии СД с 22 лет, получает
                        инсулинотерапию.
                        <br />
                        <br />
                        У бабушки по материнской линии СД с 49 лет, избыточный
                        вес, получает инсулинотерапию.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 2">
                      <div class="fs-18">
                        Около месяца назад перенесла ОРВИ с высокой
                        гипертермией, в период реконвалесценции отмечалась
                        астения, выраженная слабость, тошнота, сниженный
                        аппетит, потеряла в весе 2 кг. Родители самостоятельно
                        дома измерили уровень гликемии глюкометром натощак –
                        выявлено повышение 7,8 ммоль/л. Консультирована
                        педиатром. В венозной плазме показатели гликемии натощак
                        6,8 ммоль/л. Направлена на консультацию к эндокринологу.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 3">
                      <div class="fs-18">
                        <div class="d-md-flex mb-8">
                          <div
                            style="flex-basis: 50%"
                            class="mr-4 mr-xs-0 mb-xs-5"
                          >
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Рост</div>
                              <div style="flex-basis: 50%; text-align: right">
                                156 см
                              </div>
                            </div>
                            =
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">Вес</div>
                              <div style="flex-basis: 50%; text-align: right">
                                45 кг
                              </div>
                            </div>
                            <div
                              class="d-flex pb-2"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">ИМТ</div>
                              <div style="flex-basis: 50%; text-align: right">
                                19.3 кг/м&#178;
                              </div>
                            </div>
                          </div>
                          <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS роста</div>
                              <div style="flex-basis: 50%; text-align: right">
                                0.7
                              </div>
                            </div>

                            <div
                              class="d-flex pb-2 mb-4"
                              style="border-bottom: 1px solid #d2d2d2"
                            >
                              <div style="flex-basis: 50%">SDS веса</div>
                              <div style="flex-basis: 50%; text-align: right">
                                0.9
                              </div>
                            </div>
                          </div>
                        </div>
                        Состояние удовлетворительное. Телосложение
                        нормостеническое. Подкожно-жировая клетчатка развита
                        умеренно, распределена равномерно. Кожные покровы
                        чистые, умеренной влажности, без патологических
                        высыпаний. Слизистые нормальной окраски, чистые.
                        <br />
                        <br />
                        Костно-мышечная система патологии не выявлено.
                        Щитовидная железа расположена типично,
                        мягко-эластической консистенции, не увеличена, подвижна
                        при глотании, наличие узловых образований пальпаторно не
                        определяется, клинически эутиреоз. Симптомов нарушения
                        функции надпочечников нет.
                        <br />
                        <br />
                        <b class="fw-b">Сердечно-сосудистая система:</b> пульс
                        72 уд./мин; артериальное давление 110/70 мм рт.ст.; тоны
                        сердца ясные, ритмичные; шум сердца не определяется.
                        <br />
                        <br />
                        <b class="fw-b">Дыхательная система:</b> грудная клетка
                        правильной формы; в акте дыхания вспомогательная
                        мускулатура не участвует; дистанционные хрипы не слышны;
                        дыхание везикулярное; хрипов нет.
                        <br />
                        <br />
                        <b class="fw-b">Система органов пищеварения:</b> язык
                        влажный, чистый, живот при поверхностной пальпации
                        мягкий, безболезненный; печень не увеличена; селезенка
                        не пальпируется; стул ежедневный.
                        <br />
                        <br />
                        <b class="fw-b">Мочеполовая система:</b> дизурических
                        явлений нет. Половые органы сформированы правильно, по
                        женскому типу, Таннер 4 (B4, P2). Meнархе 6 мес назад,
                        цикл регулярный.
                      </div>
                    </div>

                    <div v-if="casesActiveTab === 4">
                      <div class="fs-18 mb-5">
                        Выберите методы первичного обследования в больнице при
                        поступлении и проанализируйте результаты.
                      </div>
                      <form>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-3-1"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-3-1"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Клинический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_3-1.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-3-2"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-3-2"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Биохимические исследования крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_3-2.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            fourth-block__dropdown fourth-block__dropdown_pink
                          "
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-3-3"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-3-3"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Общий анализ мочи
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/test-table_3-3.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="ff-rs fs-23 fw-b mt-6" style="color: #d0006f">
                    Вопросы
                  </div>
                  <Test
                    @refreshTest="refreshTest"
                    :key="testKey"
                    :questions="questions[2]"
                    :tips="tips[2]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="fifth-block">
      <div class="content">
        <div class="fifth-block__title">Видео</div>
        <div class="fifth-block__video-row d-none d-xl-flex">
          <div
            class="fifth-block__video-item"
            :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%941-min.mp4`"
          >
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video1.jpg`" />
            <div class="fifth-block__video-item-play">
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <div
            class="fifth-block__video-item"
            :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%94%201_2-min.mp4`"
          >
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video2.jpg`" />
            <div class="fifth-block__video-item-play">
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <div
            class="fifth-block__video-item"
            :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%94%201_3-min.mp4`"
          >
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video3.jpg`" />
            <div class="fifth-block__video-item-play">
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
        <swiper
          class="d-xl-none fifth-block__video-carousel"
          v-bind="swiperSettings3"
        >
          <swiper-slide>
            <div
              class="fifth-block__video-item"
              :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%941-min.mp4`"
            >
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video1.jpg`" />
              <div class="fifth-block__video-item-play">
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="fifth-block__video-item"
              :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%94%201_2-min.mp4`"
            >
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video2.jpg`" />
              <div class="fifth-block__video-item-play">
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="fifth-block__video-item"
              :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/sd2/%D0%A1%D0%94%201_3-min.mp4`"
            >
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/video3.jpg`" />
              <div class="fifth-block__video-item-play">
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4376 10.1842L5.85431 1.39238C5.34539 1.10154 4.76853 0.950696 4.18239 0.955175C3.59625 0.959654 3.02176 1.1193 2.51735 1.41788C2.02593 1.69893 1.61765 2.10505 1.334 2.59498C1.05034 3.0849 0.901418 3.64118 0.902348 4.20729V21.791C0.901418 22.3571 1.05034 22.9134 1.334 23.4033C1.61765 23.8932 2.02593 24.2993 2.51735 24.5804C3.02181 24.8788 3.59628 25.0384 4.18239 25.0429C4.7685 25.0473 5.34535 24.8966 5.85431 24.6059L21.4376 15.814C21.9402 15.5356 22.3591 15.1277 22.6508 14.6327C22.9424 14.1377 23.0963 13.5737 23.0963 12.9991C23.0963 12.4246 22.9424 11.8605 22.6508 11.3655C22.3591 10.8705 21.9402 10.4626 21.4376 10.1842Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </swiper-slide>

          <div slot="container-end">
            <div class="slider-dots">
              <div class="slider-dots__row"></div>
              <div class="slider-dots__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/swipe-left.svg`" />
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </section>
    <section class="sixth-block tab-pinned">
      <div class="content">
        <div class="sixth-block__title">Осложнения СД</div>
        <div class="sixth-block__description">
          СД может приводить как к острым осложнениям, требующим ургентного
          медицинского вмешательства, так и к целому ряду хронических
          инвалидизирующих заболеваний<sup>1-9</sup>
        </div>
        <div class="tap-hint mb-4 mt-4">
          <div class="tap-hint__icon ml-auto mr-2">
            <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tap.svg`" />
          </div>
          <div class="tap-hint__text">Выберите тип осложнения</div>
        </div>
      </div>
      <div class="fixed-tabs">
        <div class="content">
          <div class="sixth-block__tabs">
            <div
              class="sixth-block__arrow sixth-block__arrow_left"
              @click="
                choiceSixthTab(
                  sixthTabsActive > 0 ? sixthTabsActive - 1 : sixthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="sixth-block__tabs-row">
              <div
                class="sixth-block__tab"
                @click="choiceSixthTab(0)"
                :class="{ 'sixth-block__tab_active': sixthTabsActive === 0 }"
              >
                <img
                  class="sixth-block__tab-back"
                  v-if="sixthTabsActive === 0"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                />
                <div class="sixth-block__tab-icon">
                  <img
                    v-if="sixthTabsActive === 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs6-1_active.svg`"
                  />
                  <img
                    v-if="sixthTabsActive !== 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs6-1.svg`"
                  />
                </div>
                <div class="sixth-block__tab-text">
                  Острые осложнения сахарного диабета
                </div>
              </div>
              <div
                class="sixth-block__tab"
                @click="choiceSixthTab(1)"
                :class="{ 'sixth-block__tab_active': sixthTabsActive === 1 }"
              >
                <img
                  class="sixth-block__tab-back"
                  v-if="sixthTabsActive === 1"
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tab-back2.svg`"
                />
                <div class="sixth-block__tab-icon">
                  <img
                    v-if="sixthTabsActive === 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs6-2_active.svg`"
                  />
                  <img
                    v-if="sixthTabsActive !== 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/tabs6-2.svg`"
                  />
                </div>
                <div class="sixth-block__tab-text">
                  Хронические осложнения сахарного диабета
                </div>
              </div>
            </div>

            <div
              class="sixth-block__arrow sixth-block__arrow_right"
              @click="
                choiceSixthTab(
                  sixthTabsActive < 2 ? sixthTabsActive + 1 : sixthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="sixth-block__tabs-body">
          <div v-if="sixthTabsActive === 0">
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_1_1" />
              <label for="dropdown_6_1_1" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_violet
                    "
                  >
                    Диабетический кетоацидоз (ДКА)
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div class="d-xl-flex mb-8">
                  <div style="flex-basis: 50%" class="mr-4 mr-md-0 mb-md-12">
                    <div
                      class="
                        sixth-block__block-title sixth-block__block-title_violet
                        mb-10
                        d-none d-xl-block
                      "
                    >
                      Диабетический кетоацидоз (ДКА)
                    </div>
                    <div class="fs-23 fw-b mb-8">
                      <span style="color: #9ead00">ДКА</span> – требующая
                      экстренной госпитализации острая декомпенсация СД, которая
                      сопровождается:
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-none d-md-block"
                        /><img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-md-none"
                          style="width: 20px"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        <div class="fs-23 fw-b mb-2">Гипергликемией</div>
                        (> 13,9 ммоль/л у взрослых и >11 ммоль/л у детей)
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-none d-md-block"
                        /><img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-md-none"
                          style="width: 20px"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        <div class="fs-23 fw-b mb-2">Гиперкетонемией</div>
                        (> 5 ммоль/л)
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-none d-md-block"
                        /><img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-md-none"
                          style="width: 20px"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        <div class="fs-23 fw-b mb-2">Кетонурией</div>
                        (≥ ++)
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-none d-md-block"
                        /><img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-md-none"
                          style="width: 20px"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        <div class="fs-23 fw-b mb-2">
                          Метаболическим ацидозом
                        </div>
                        (рН &lt;7,3, уровень бикарбоната &lt;15 ммоль/л у
                        взрослых и &lt;18 ммоль/л у детей)
                      </div>
                    </div>
                    <div class="d-flex d-none mb-5">
                      <div class="mr-4" style="flex-shrink: 0">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-none d-md-block"
                        /><img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          class="d-md-none"
                          style="width: 20px"
                        />
                      </div>
                      <div class="fs-18 ff-r">
                        <div class="fs-23 fw-b mb-2">
                          Различной степенью нарушения
                          сознания&nbsp;или&nbsp;без<sup>3-8</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 50%" class="ml-4 ml-md-0">
                    <div class="d-md-flex mb-8 mb-xs-2">
                      <div style="flex-basis: 50%" class="mr-4 mr-xs-0 mb-xs-2">
                        <div
                          class="px-8 pb-8 pt-25 pt-md-8 pa-xs-5 d-flex"
                          :style="`
                        border-radius: 30px;
                        background: #D8CFDD url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block1.svg);
                        flex-direction: column;
                        background-size: cover;
                        background-position: top;
                        `"
                        >
                          <img
                            style="width: 50px"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon1.svg
                            `"
                            class="mt-auto mb-6"
                          />
                          <div class="fs-23 ff-rs fw-sb">
                            В основе лежит инсулиновая недостаточность
                          </div>
                        </div>
                      </div>
                      <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                        <div
                          class="px-8 pb-8 pt-25 pt-md-8 pa-xs-5 d-flex"
                          :style="`
                        border-radius: 30px;
                        background: #D8CFDD url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block2.svg);
                        flex-direction: column;
                        background-size: cover;
                        background-position: top;
                        `"
                        >
                          <img
                            style="width: 50px; opacity: 0"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon1.svg
                            `"
                            class="mt-auto mb-6"
                          />
                          <div class="fs-23 ff-rs fw-sb">
                            ДКА наиболее типичен для СД 1 типа
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      :style="`
                        border-radius: 30px;
                        background: #E7EF99;
                        background-size: cover;
                        background-position: top;
                        `"
                    >
                      <div
                        class="
                          fs-23
                          ff-rs
                          fw-sb
                          mt-auto
                          mb-8
                          ml-8
                          mr-n-12
                          my-md-auto
                          mr-md-n-16 mr-xs-n-2
                          my-xs-5
                          ml-xl-5
                        "
                      >
                        Может развиться как в манифестации заболевания, так и на
                        фоне нерационального лечения и&nbsp;недостаточного
                        самоконтроля
                      </div>
                      <div
                        style="flex-basis: 280px; flex-shrink: 0"
                        class="ml-n-12 ml-md-0 d-none d-md-block"
                      >
                        <picture>
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img_mob.png
                            `"
                            media="(max-width: 767px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img_tab.png
                            `"
                            media="(max-width: 1219px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img.png
                            `"
                            media="(min-width: 1220px)"
                          />
                          <img
                            class="ml-auto"
                            style="height: 100%"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img.png
                            `"
                          />
                        </picture>
                      </div>
                      <div
                        style="flex-basis: 100px; flex-shrink: 0"
                        class="ml-n-12 ml-md-0 d-block d-md-none"
                      >
                        <picture>
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img_mob.png
                            `"
                            media="(max-width: 767px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img_tab.png
                            `"
                            media="(max-width: 1219px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img.png
                            `"
                            media="(min-width: 1220px)"
                          />
                          <img
                            class="ml-auto"
                            style="height: 100%"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-img.png
                            `"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-xl-flex">
                  <div style="flex-basis: 68.5%" class="mr-4 mr-md-0 mb-md-5">
                    <div
                      class="pa-10 pa-xs-5"
                      style="border-radius: 30px; border: 1px solid #d2d2d2"
                    >
                      <div class="fs-23 fw-sb mb-6">
                        Провоцирующими факторами развития ДКА могут
                        стать<sup>3-4</sup>:
                      </div>
                      <div class="sixth-block__line sixth-block__line_violet">
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Интеркуррентные заболевания
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Операции и травмы
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Пропуск, отмена или снижение в потребности
                              инсулина у пациента
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Ошибки в технике инъекций инсулина
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Неисправность средств для введения инсулина
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Длительная терапия стероидами, атипичными
                              нейролептиками, некоторыми таргетными
                              противоопухолевыми препаратами, иНГЛТ-2 и др.
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Беременность
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Употребление психоактивных веществ (ПАВ)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 31.5%" class="ml-4 ml-md-0">
                    <div
                      class="pa-10 pa-xs-5"
                      style="
                        border-radius: 30px;
                        border: 1px solid #d2d2d2;
                        height: 100%;
                      "
                    >
                      <div class="fs-23 fw-sb mb-6">
                        <span style="color: #9ead00">Лечение ДКА</span> должно
                        проводиться незамедлительно и быть направлено
                        на<sup>3-4</sup>:
                      </div>
                      <div class="sixth-block__line sixth-block__line_green">
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Устранение инсулиновой недостаточности
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Борьбу с дегидратацией и гиповолемией
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Восстановление электролитного баланса и КЩС
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_1_2" />
              <label for="dropdown_6_1_2" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_blue
                    "
                  >
                    Гипергликемическое гиперосмолярное состояние (ГГС)
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div class="d-xl-flex mb-12">
                  <div style="flex-basis: 45%" class="mr-4 mr-md-0 mb-md-5">
                    <div
                      class="
                        sixth-block__block-title sixth-block__block-title_blue
                        mb-10
                        d-none d-xl-block
                      "
                    >
                      Гипергликемическое гиперосмолярное состояние (ГГС)
                    </div>
                    <div class="fs-23 fw-b mb-8">
                      <span style="color: #9ead00">ГГС</span> – острая
                      декомпенсация СД, с резко выраженной гипергликемией (как
                      правило, уровень глюкозы плазмы >35 ммоль/л), высокой
                      осмолярностью плазмы и резко выраженной дегидратацией, при
                      отсутствии кетоза и ацидоза<sup>3-7</sup>.
                    </div>
                  </div>
                  <div style="flex-basis: 55%" class="ml-4 ml-md-0">
                    <div class="d-md-flex mb-8">
                      <div style="flex-basis: 40%" class="mr-4 mr-xs-0 mb-xs-2">
                        <div
                          class="px-8 pb-8 pt-19 pt-md-8 pa-xs-5 d-flex"
                          :style="`
                          height: 100%;
                        border-radius: 30px;
                        background: #E1F6F9 url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block3.svg);
                        flex-direction: column;
                        background-size: cover;
                        background-position: top;
                        `"
                        >
                          <img
                            style="width: 50px"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon2.svg
                            `"
                            class="mt-auto mt-md-0 mb-6"
                          />
                          <div class="fs-18 ff-rs fw-sb">
                            Чаще встречается при СД 2 типа
                          </div>
                        </div>
                      </div>
                      <div style="flex-basis: 60%" class="ml-4 ml-xs-0">
                        <div
                          class="px-8 pb-8 pt-19 pt-md-8 pa-xs-5 d-flex"
                          :style="`
                          height: 100%;
                        border-radius: 30px;
                        background: #E1F6F9 url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block4.svg);
                        flex-direction: column;
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat
                        `"
                        >
                          <img
                            style="width: 50px"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon3.svg
                            `"
                            class="mt-auto mt-md-0 mb-6"
                          />
                          <div class="fs-18 ff-rs fw-sb">
                            В основе развития лежит выраженная относительная
                            инсулиновая недостаточность в сочетании с резкой
                            дегидратацией
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs-23 fw-sb mb-6">
                  Провоцирующие факторы<sup>3-7</sup>:
                </div>
                <div class="d-xl-flex d-none mb-10">
                  <div style="flex-basis: 50%" class="mr-4 mr-xs-0 mb-xs-5">
                    <div class="sixth-block__line sixth-block__line_green">
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Рвота, диарея, лихорадка
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Диализ, операции, травмы
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Тепловой и солнечный удар
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Прием глюкокортикоидов, половых гормонов, аналогов
                            соматостатина и т.д.
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Острые заболевания (инфаркт миокарда, тромбоэмболия
                            легочной артерии, инсульт, массивные кровотечения,
                            обширные ожоги, почечная недостаточность)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                    <div class="sixth-block__line sixth-block__line_green">
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Сопутствующий несахарный диабет
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Пожилой возраст
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Применение диуретиков
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Эндокринопатии (акромегалия, тиреотоксикоз, болезнь
                            Кушинга)
                          </div>
                        </div>
                      </div>
                      <div class="sixth-block__line-item">
                        <div class="sixth-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                          />
                        </div>
                        <div class="sixth-block__line-item-text my-auto">
                          <div class="sixth-block__line-item-text">
                            Ошибочные медицинские рекомендации (запрет
                            достаточного потребления жидкости при жажде)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    sixth-block__line sixth-block__line_green
                    d-xl-none
                    mb-10
                  "
                >
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Рвота, диарея, лихорадка
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Диализ, операции, травмы
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Тепловой и солнечный удар
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Прием глюкокортикоидов, половых гормонов, аналогов
                        соматостатина и т.д.
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Острые заболевания (инфаркт миокарда, тромбоэмболия
                        легочной артерии, инсульт, массивные кровотечения,
                        обширные ожоги, почечная недостаточность)
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Сопутствующий несахарный диабет
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Пожилой возраст
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Применение диуретиков
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Эндокринопатии (акромегалия, тиреотоксикоз, болезнь
                        Кушинга)
                      </div>
                    </div>
                  </div>
                  <div class="sixth-block__line-item">
                    <div class="sixth-block__line-item-index">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`" />
                    </div>
                    <div class="sixth-block__line-item-text my-auto">
                      <div class="sixth-block__line-item-text">
                        Ошибочные медицинские рекомендации (запрет достаточного
                        потребления жидкости при жажде)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs-18 mb-4">
                  <b class="fw-b"
                    >Особенность клиники ГГС – полиморфная неврологическая
                    симптоматика:</b
                  >
                  судороги, дизартрия, двусторонний спонтанный нистагм, гипер-
                  или гипотонус мышц, парезы и параличи; гемианопсия,
                  вестибулярные нарушения и др. Симптомы не укладываются в
                  какой-либо четкий синдром, клиническая картина изменчива и
                  исчезает при нормализации осмолярности<sup>3-7</sup>.
                </div>
                <div class="fs-18 fw-b mb-4" style="color: #5ecad8">
                  Лечение должно быть направлено на восстановление
                  электролитного балланса и выявления и лечения причин,
                  спровоцировавших развитие ГГС.
                </div>
                <div class="fs-18">
                  Крайне важен дифференциальный диагноз с отеком мозга во
                  избежание ОШИБОЧНОГО назначения мочегонных ВМЕСТО РЕГИДРАТАЦИИ
                  <sup>3-7</sup>.
                </div>
              </div>
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_1_3" />
              <label for="dropdown_6_1_3" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_violet
                    "
                  >
                    Лактатацидоз
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div
                  class="
                    sixth-block__block-title sixth-block__block-title_violet
                    mb-10
                    d-none d-xl-block
                  "
                >
                  Лактатацидоз
                </div>
                <div class="fs-23 fw-b mb-6">
                  <span style="color: #9ead00">Лактатацидоз</span> –
                  метаболический ацидоз с большой анионной разницей (≥10
                  ммоль/л) и уровнем молочной кислоты в крови >4 ммоль/л<sup
                    >3-7</sup
                  >
                </div>
                <div class="fs-18 mb-8">
                  <b class="fw-b">Основная причина</b> – повышенное образование
                  и снижение утилизации лактата и гипоксия.
                </div>
                <div class="d-xl-flex">
                  <div style="flex-basis: 68.5%" class="mr-4 mr-md-0 mb-md-5">
                    <div
                      class="pa-10 pa-xs-5"
                      style="
                        border-radius: 30px;
                        border: 1px solid #d2d2d2;
                        background-color: #f8f8f8;
                        height: 100%;
                      "
                    >
                      <div class="fs-23 fw-sb mb-6">
                        Провоцирующие факторы<sup>3-7</sup>:
                      </div>
                      <div class="sixth-block__line sixth-block__line_violet">
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Прием бигуанидов (метформин)
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Выраженная декомпенсация СД
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Любой ацидоз, включая ДКА
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Почечная или печеночная недостаточность
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Злоупотребление алкоголем
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Тканевая гипоксия (ХСН, кардиогенный шок,
                              гиповолемический шок, синдром сдавления, ожоги,
                              травмы и пр)
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Выраженные поздние осложнения СД
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Старческий возраст
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Тяжелое общее состояние
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Запущенные стадии злокачественных новообразований
                              и гемобластозов
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`
                                https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg
                              `"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Беременность
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 31.5%" class="ml-4 ml-md-0">
                    <div
                      class="pa-10 pa-xs-5"
                      style="
                        border-radius: 30px;
                        border: 1px solid #d2d2d2;
                        background-color: #f8f8f8;
                        height: 100%;
                      "
                    >
                      <div class="fs-23 fw-sb mb-6">Клиническая картина:</div>
                      <div class="sixth-block__line sixth-block__line_green">
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Миалгии, не купирующиеся анальгетиками
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Боли в сердце, не купирующиеся антиангинальными
                              средствами
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Боли в животе
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Боли в животе
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Головные боли
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Тошнота, рвота
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Слабость, адинамия
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Артериальная гипотензия
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Тахикардия
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Одышка, впоследствии дыхание Куссмауля
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Нарушение сознания от сонливости до комы
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_1_4" />
              <label for="dropdown_6_1_4" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_blue
                    "
                  >
                    Гипогликемия и гипогликемическая кома
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div
                  class="
                    sixth-block__block-title sixth-block__block-title_blue
                    mb-10
                    d-none d-xl-block
                  "
                >
                  Гипогликемия и гипогликемическая кома
                </div>
                <div class="fs-23 fw-b mb-6">
                  <span style="color: #9ead00">Гипогликемия</span> – снижение
                  уровня глюкозы в крови ниже 3 ммоль/л<sup>3-7</sup>
                </div>
                <div class="fs-18 mb-8">
                  <b class="fw-b">Основная причина</b> – избыток инсулина в
                  организме по отношению к поступлению углеводов извне (с пищей)
                  или из эндогенных источников (продукция глюкозы печенью), а
                  также при ускоренной утилизации углеводов (например, мышечная
                  работа).
                </div>
                <div class="d-xl-flex">
                  <div style="flex-basis: 60%" class="mr-4 mr-md-0 mb-md-5">
                    <div
                      class="pa-10 pa-xs-5"
                      style="border-radius: 30px; background-color: #e1f6f9"
                    >
                      <div class="fs-23 fw-sb mb-6">
                        Провоцирующими факторами могут стать:
                      </div>
                      <div class="sixth-block__line sixth-block__line_green">
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Передозировка инсулина или сахаросниждающих
                              препаратов (ошибка пациента, ошибка функции помпы,
                              намеренная передозировка)
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Смена препарата
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Почечная и печеночная недостаточность
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Длительная физическая нагрузка
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Неадекватное питание (пропуск приема пищи или
                              недостаточное количество углеводов)
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Прием алкоголя
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Ограничение питания для снижения массы тела (без
                              соответствующего уменьшения дозы сахароснижающих
                              препаратов)
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Замедление опорожнения желудка (при автономной
                              нейропатии), рвота, синдром мальабсорбции
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Первый триместр беременности
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 40%" class="ml-4 ml-md-0">
                    <div>
                      <div class="d-flex mb-5 mt-8">
                        <div
                          style="flex-basis: 40px; flex-shrink: 0"
                          class="mr-3 ml-n-2 ml-md-n-0"
                        >
                          <img
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon4.svg
                            `"
                          />
                        </div>
                        <div style="flex-basis: 100%" class="my-auto">
                          <div class="fs-23 fw-sb">Клинически</div>
                        </div>
                      </div>

                      <div
                        class="sixth-block__line sixth-block__line_green mb-11"
                      >
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Гипогликемия проявляется вегетативной
                              симптоматикой (учащенное сердцебиение, дрожь,
                              бледность кожи, потливость, мидриаз, тошнота,
                              сильный голод, беспокойство, тревога, агрессия).
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              Симптомы нейрогликопении (слабость, нарушение
                              концентрации внимания, головная боль,
                              головокружение, сонливость, парестезии, нарушения
                              зрения, дезориентация, дизартрия, нарушение
                              координации движений, спутанность сознания, кома).
                            </div>
                          </div>
                        </div>
                        <div class="sixth-block__line-item">
                          <div class="sixth-block__line-item-index">
                            <img
                              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_green.svg`"
                            />
                          </div>
                          <div class="sixth-block__line-item-text my-auto">
                            <div class="sixth-block__line-item-text">
                              У детей могут быстро развиться судороги и другие
                              неврологические симптомы.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mb-5">
                        <div
                          style="flex-basis: 40px; flex-shrink: 0"
                          class="mr-3"
                        >
                          <img
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__block-icon5.svg
                            `"
                          />
                        </div>
                        <div style="flex-basis: 100%" class="my-auto">
                          <div class="fs-23 fw-sb">Лечение</div>
                        </div>
                      </div>
                      <div class="fs-18">
                        Направлено на незамедлительную нормализацию уровня
                        глюкозы в крови (пероральное или парентеральное введение
                        глюкозы, применение препаратов глюкагона).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="sixthTabsActive === 1">
            <div class="fw-sb fs-18 mb-4" style="color: #3e1876">
              По данным ВОЗ сахарный диабет является одной из ведущих причин
              потери зрения, почечной недостаточности, ишемических событий,
              инсульта и ампутации нижних конечностей<sup>1</sup>.
            </div>
            <div class="fs-18 mb-16">
              Хронические осложнения СД принято делить на состояния,
              ассоциированные с поражением мелких сосудов (микроангиопатии) и
              крупных сосудов (макроангиопатии)<sup>1-8</sup>.
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_2_1" />
              <label for="dropdown_6_2_1" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_violet
                    "
                  >
                    Микроангиопатии
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div
                  class="
                    sixth-block__block-title sixth-block__block-title_violet
                    mb-10
                    d-none d-xl-block
                  "
                >
                  Микроангиопатии
                </div>
                <div class="d-xl-flex mb-10">
                  <div style="flex-basis: 60%" class="mr-4 mr-md-0 mb-md-12">
                    <div class="d-md-flex">
                      <div style="flex-basis: 50%" class="mr-4 mr-xs-0 mb-xs-8">
                        <div class="fs-18">
                          <b class="fw-b" style="color: #9ead00"
                            >Диабетическая ретинопатия (ДР)</b
                          >
                          ‒ это специфичное позднее нейромикрососудистое
                          осложнение СД, развивающееся, как правило,
                          последовательно от изменений, связанных с повышенной
                          проницаемостью и окклюзией ретинальных сосудов до
                          появления новообразованных сосудов и фиброглиальной
                          ткани<sup>1-8</sup>.
                        </div>
                      </div>
                      <div style="flex-basis: 50%" class="ml-4 ml-xs-0">
                        <picture>
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img3_mob.jpg
                            `"
                            media="(max-width: 767px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img3_tab.jpg
                            `"
                            media="(max-width: 1219px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img3.jpg
                            `"
                            media="(min-width: 1220px)"
                          />
                          <img
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img3.jpg
                            `"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div style="flex-basis: 40%" class="ml-4 ml-md-0">
                    <div class="fs-18">
                      <b class="fw-b" style="color: #9ead00"
                        >Диабетическая нефропатия (ДН)</b
                      >
                      — или диабетическая болезнь почек (ДБП) — это
                      специфическое поражение почек при СД под воздействием
                      метаболических, гемодинамических, воспалительных,
                      генетических, эпигенетических факторов, приводящее к
                      развитию терминальной почечной недостаточности (ТПН),
                      требующей проведения заместительной почечной терапии
                      (ЗПТ): диализа, трансплантации. При ДН на фоне хронической
                      гипергликемии и изменения гемодинамики развивается
                      нарушение фильтрации крови в канальцах и клубочках нефрона
                      и замещение паренхимы почек соединительной
                      тканью<sup>1-8</sup>.
                    </div>
                  </div>
                </div>
                <div class="fs-18 mb-8">
                  Факторы риска развития микроангиопатий при СД разделяют на
                  немодифицируемые (стаж диабета, генетическая
                  предрасположенность) и модифицируемые (гипергликемия,
                  повышение артериального давления, дислипилемия)<sup>1-8</sup>.
                </div>
                <blockquote class="mb-8">
                  <div class="fs-18 fw-b">
                    Обучение больных методам самоконтроля гликемии и
                    артериального давления – обязательное условие профилактики и
                    успешного лечения осложнений СД<sup>1-8</sup>
                  </div>
                </blockquote>
                <div class="fs-18 mb-10">
                  Скрининг микроваскулярных осложнений у пациентов с СД должен
                  проводиться регулярно, в соответствие с актуальными
                  клиническими рекомендациями<sup>1-8</sup>.
                </div>
                <div class="sixth-block__scheme">
                  <input id="sixth-block__scheme" type="checkbox" />
                  <label
                    for="sixth-block__scheme"
                    class="
                      sixth-block__scheme-button
                      button button_empty-violet
                      px-8
                      py-4
                      mx-auto
                    "
                  >
                    <span style="white-space: normal"></span>
                  </label>
                  <div class="sixth-block__scheme-body mt-17">
                    <div class="fs-28 fw-sb mb-8" style="text-align: center">
                      Скрининг микрососудистых осложнений СД<sup>1-8</sup>
                    </div>
                    <div class="overflow">
                      <div class="overflow__body">
                        <picture>
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__table1_tab.jpg
                            `"
                            media="(max-width: 767px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__table1_tab.jpg
                            `"
                            media="(max-width: 1219px)"
                          />
                          <source
                            :srcset="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__table1.jpg
                            `"
                            media="(min-width: 1220px)"
                          />
                          <img
                            class="mx-auto d-none d-md-none d-xl-block"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__table1.jpg
                            `"
                          />
                          <img
                            class="mx-auto d-block d-xl-none"
                            :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__table1.jpg
                            `"
                            style="width: 1000px"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_2_2" />
              <label for="dropdown_6_2_2" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_blue
                    "
                  >
                    Макроангиопатии
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div
                  class="
                    sixth-block__block-title sixth-block__block-title_blue
                    mb-10
                    d-none d-xl-block
                  "
                >
                  Макроангиопатии
                </div>
                <div class="fs-18 mb-10">
                  <b class="fw-b" style="color: #9ead00">Макроангиопатии</b> ‒
                  атеросклеротическое поражение крупных и средних артерий. СД
                  является одним из основных факторов, влияющих на раннее
                  развитие и быстрое прогрессирование
                  атеросклероза<sup>5-7</sup>.
                </div>
                <div class="fw-b fs-18 mb-6">
                  К основным сердечно-сосудистым заболеваниям
                  атеросклеротического генеза относятся<sup>5-7</sup>:
                </div>
                <div class="sixth-block__diseases mb-10">
                  <div class="sixth-block__disease">
                    <div class="sixth-block__disease-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__disease-icon1.svg
                        `"
                      />
                    </div>
                    <div class="sixth-block__disease-text">
                      Ишемическая болезнь сердца (ИБС)
                    </div>
                  </div>
                  <div class="sixth-block__disease">
                    <div class="sixth-block__disease-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__disease-icon2.svg
                        `"
                      />
                    </div>
                    <div class="sixth-block__disease-text">
                      Цереброваскулярные заболевания
                    </div>
                  </div>
                  <div class="sixth-block__disease">
                    <div class="sixth-block__disease-icon">
                      <img
                        :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__disease-icon3.svg
                        `"
                      />
                    </div>
                    <div class="sixth-block__disease-text">
                      Заболевания артерий нижних конечностей
                    </div>
                  </div>
                </div>

                <blockquote class="mb-8">
                  <div class="d-md-flex">
                    <div
                      class="mr-6 d-none d-md-block"
                      style="flex-basis: 100px; flex-shrink: 0"
                    >
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`" />
                    </div>
                    <div class="mb-2 mt-n-10 d-md-none" style="width: 50px">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning.png`" />
                    </div>
                    <div class="fs-18 fw-sb" style="font-style: italic">
                      Перечисленные заболевания не являются непосредственно
                      осложнениями СД, однако наличие СД приводит к раннему
                      развитию указанных состояний, увеличивает тяжесть,
                      ухудшает течение, видоизменяет клинические проявления этих
                      заболеваний.
                      <br />
                      <br />
                      <b class="fw-b"
                        >Наличие СД является независимым фактором
                        сердечно-сосудистого риска!<sup>1-8</sup></b
                      >
                    </div>
                  </div>
                </blockquote>
              </div>
            </div>
            <div class="sixth-block__dropdown">
              <input type="checkbox" id="dropdown_6_2_3" />
              <label for="dropdown_6_2_3" class="sixth-block__dropdown-head">
                <div class="sixth-block__dropdown-head-title">
                  <div
                    class="
                      sixth-block__block-title sixth-block__block-title_violet
                    "
                  >
                    Диабетическая нейропатия
                  </div>
                </div>
                <div class="sixth-block__dropdown-head-arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </label>
              <div class="sixth-block__dropdown-body">
                <div class="d-xl-flex mb-12">
                  <div style="flex-basis: 100%" class="mr-4 mr-md-0 mb-md-5">
                    <div
                      class="
                        sixth-block__block-title sixth-block__block-title_violet
                        mb-8
                        d-none d-xl-block
                      "
                    >
                      Диабетическая нейропатия
                    </div>
                    <div class="fs-18">
                      <b class="fw-b" style="color: #9ead00"
                        >Диабетическая нейропатия</b
                      >
                      ‒ комплекс клинических и субклинических синдромов, каждый
                      из которых характеризуется диффузным или очаговым
                      поражением периферических и/или автономных нервных волокон
                      в результате СД. Обследования, направленные на раннее
                      выявление диабетической нейропатии должны проводиться
                      пациентам с СД 1 типа и стажем диабета более 5 лет и всем
                      пациентам с СД 2 типа, независимо от длительности
                      заболевания<sup>1-8</sup>.
                    </div>
                  </div>
                  <div
                    style="flex-basis: 305px; flex-shrink: 0"
                    class="ml-4 ml-md-0"
                  >
                    <img
                      class="d-none d-xl-block"
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img4.jpg`"
                    />
                    <img
                      style="width: auto"
                      class="d-none d-md-block d-xl-none ml-auto"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img4_tab.jpg
                      `"
                    />
                    <img
                      style="width: auto"
                      class="d-block d-md-none ml-auto"
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/sixth-block__img4_mob.jpg
                      `"
                    />
                  </div>
                </div>

                <blockquote class="mb-8">
                  <div class="d-md-flex mb-3">
                    <div
                      class="mr-6 d-none d-md-block"
                      style="flex-basis: 100px; flex-shrink: 0"
                    >
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning2.png`" />
                    </div>
                    <div class="mb-2 mt-n-10 d-md-none" style="width: 50px">
                      <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/warning2.png`" />
                    </div>
                    <div class="fs-23 fw-b my-auto">
                      Частыми ошибками в ведении пациентов могут
                      стать<sup>1</sup>:
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-none d-md-block"
                      /><img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-md-none"
                        style="width: 20px"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Нерегулярный осмотр нижних конечностей (особенно стоп),
                      что приводит к отсрочке диагностики осложнений и развитию
                      запущенных форм диабетической стопы.
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-none d-md-block"
                      /><img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-md-none"
                        style="width: 20px"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Недостаточное обучение самоконтролю гликемии и
                      нерегулярный контроль параметров компенсации диабета.
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-none d-md-block"
                      /><img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-md-none"
                        style="width: 20px"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Отсутствие своевременного назначения терапии, направленной
                      на коррекцию Артериальной гипертензии и дислипидемии.
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-4" style="flex-shrink: 0">
                      <img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-none d-md-block"
                      /><img
                        :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                        class="d-md-none"
                        style="width: 20px"
                      />
                    </div>
                    <div class="fs-18 ff-r">
                      Отсутствие мультидисциплинарного подхода к лечению больных
                      с СД.
                    </div>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sources">
      <div class="content">
        <div class="sources__title">Источники</div>
        <input type="checkbox" id="sources" />
        <ol class="sources__list">
          <li>
            Дедов И.И., Шестакова М.В., Викулова О.К., Железнякова А.В., Исаков
            М.А., Сазонова Д.В., Мокрышева Н.Г. Сахарный диабет в Российской
            Федерации: динамика эпидемиологических показателей по данным
            Федерального регистра сахарного диабета за период 2010 – 2022 гг.
            Сахарный диабет. 2023;26(2):104-123.
            <a href="https://doi.org/10.14341/DM13035" target="_blank"
              >https://doi.org/10.14341/DM13035</a
            >
            )
          </li>
          <li>
            International Diabetes Federation. IDF Diabetes Atlas, 10th edn.
            Brussels, Belgium: International Diabetes Federation, 2021
          </li>
          <li>
            Клинические рекомендации. Сахарный диабет 1 типа у взрослых. 2022.
            Российская ассоциация эндокринологов.
            <a href="https://cr.minzdrav.gov.ru/recomend/286_2" target="_blank"
              >https://cr.minzdrav.gov.ru/recomend/286_2</a
            >
            (дата доступа 01.07.2024)
          </li>
          <li>
            Клинические рекомендации. Сахарный диабет 1 типа у детей. 2022.
            Российская ассоциация эндокринологов.
            <a href="https://cr.minzdrav.gov.ru/recomend/287_2" target="_blank"
              >https://cr.minzdrav.gov.ru/recomend/287_2</a
            >
            (дата доступа 01.07.2024)
          </li>
          <li>
            Клинические рекомендации. Сахарный диабет 2 типа у детей. 2022.
            Российская ассоциация эндокринологов.
            <a href="https://cr.minzdrav.gov.ru/recomend/622_4" target="_blank"
              >https://cr.minzdrav.gov.ru/recomend/622_4</a
            >
            (дата доступа 01.07.2024)
          </li>
          <li>
            Дедов И.И., Шестакова М.В., Майоров А.Ю., Викулова О.К., Галстян
            Г.Р., Кураева Т.Л., Петеркова В.А., Смирнова О.М., Старостина Е.Г.,
            Суркова Е.В., Сухарева О.Ю., Токмакова А.Ю., Шамхалова М.Ш.,
            Ярек-Мартынова И.Р., Артемова Е.В., Бешлиева Д.Д., Бондаренко О.Н.,
            Волеводз Н.Н., Григорян О.Р., Гомова И.С., Джемилова З.Н., Есаян
            Р.М., Ибрагимова Л.И., Калашников В.Ю., Кононенко И.В., Лаптев Д.Н.,
            Липатов Д.В., Мотовилин О.Г., Никонова Т.В., Роживанов Р.В.,
            Шестакова Е.А. «Алгоритмы специализированной медицинской помощи
            больным сахарным диабетом» Под редакцией И.И. Дедова, М.В.
            Шестаковой, А.Ю. Майорова 9-й выпуск. Сахарный диабет.
            2019;22(1S1):1-144.
          </li>
          <li>
            Клинические рекомендации. Сахарный диабет 2 типа у взрослых. 2022.
            Российская ассоциация эндокринологов.
            <a href="https://cr.minzdrav.gov.ru/recomend/290_2" target="_blank"
              >https://cr.minzdrav.gov.ru/recomend/290_2</a
            >
            (дата доступа 01.07.2024)
          </li>
          <li>
            Алгоритмы специализированной медицинской помощи больным сахарным
            диабетом / Под редакцией И.И. Дедова, М.В. Шестаковой, А.Ю.
            Майорова. – 11-й выпуск. – М.; 2023. doi:
            <a href="https://doi.org/10.14341/DM13042" target="_blank"
              >https://doi.org/10.14341/DM13042</a
            >
          </li>
          <li>
            Дедов И.И., Шестакова М.В., Майоров А.Ю., Шамхалова М.Ш., Сухарева
            О.Ю., Галстян Г.Р., Токмакова А.Ю., Никонова Т.В., Суркова Е.В.,
            Кононенко И.В., Егорова Д.Н., Ибрагимова Л.И., Шестакова Е.А.,
            Клефортова И.И., Скляник И.А., Ярек-Мартынова И.Я., Северина А.С.,
            Мартынов С.А., Викулова О.К., Калашников В.Ю., Бондаренко И.З.,
            Гомова И.С., Старостина Е.Г., Аметов А.С., Анциферов М.Б., Бардымова
            Т.П., Бондарь И.А., Валеева Ф.В., Демидова Т.Ю., Мкртумян А.М.,
            Петунина Н.А., Руяткина Л.А., Суплотова Л.А., Ушакова О.В., Халимов
            Ю.Ш. Сахарный диабет 2 типа у взрослых. Сахарный диабет.
            2020;23(2S):4-102.
            <a href="https://doi.org/10.14341/DM12507" target="_blank"
              >https://doi.org/10.14341/DM12507</a
            >
          </li>
          <li>
            Kear C, Fisher T, Westwood E, et al G194(P) Delayed diagnosis of
            Type 1 Diabetes Mellitus (T1DM)in children Archives of Disease in
            Childhood 2016;101:A103-A104.
          </li>
          <li>
            Emir Tas, Katrin Wooley, Vildan Tas, Yu-Chi Annie Wang, Delayed
            Management of Insulin-Dependent Diabetes Mellitus in Children,
            Journal of Pediatric Health Care, Volume 37, Issue 1, 2023, P 56-62,
            ISSN 0891-5245,
            <a
              href="https://doi.org/10.1016/j.pedhc.2022.07.004"
              target="_blank"
              >https://doi.org/10.1016/j.pedhc.2022.07.004</a
            >
            .
          </li>
          <li>
            Tosur M, Huang X, Inglis AS, Aguirre RS, Redondo MJ. Inaccurate
            diagnosis of diabetes type in youth: prevalence, characteristics,
            and implications. Sci Rep. 2024 Apr 17;14(1):8876. doi:
            10.1038/s41598-024-58927-6. PMID: 38632329; PMCID: PMC11024140
          </li>
          <li>
            Федеральные клинические рекомендации по диагностике и лечению
            моногенных форм сахарного диабета у детей и подростков. 2013 г.
            <a
              href="https://www.endocrincentr.ru/sites/default/files/specialists/science/clinic-recomendations/mno.pdf"
              target="_blank"
              >https://www.endocrincentr.ru/sites/default/files/specialists/science/clinic-recomendations/mno.pdf</a
            >
            (дата доступа 10.05.2024)
          </li>
          <li>
            Gopalan A, Mishra P, Alexeeff SE, Blatchins MA, Kim E, Man AH, Grant
            RW. Prevalence and predictors of delayed clinical diagnosis of Type
            2 diabetes: a longitudinal cohort study. Diabet Med. 2018
            Dec;35(12):1655-1662. doi: 10.1111/dme.13808. Epub 2018 Sep 21.
            PMID: 30175870; PMCID: PMC6481650.
          </li>
          <li>
            Harris MI, Klein R, Welborn TA, Knuiman MW. Onset of NIDDM occurs at
            least 4–7 yr before clinical diagnosis. Diabetes Care 1992; 15:
            815–819.
          </li>
          <li>
            Porta M, Curletto G, Cipullo D, Rigault de la Longrais R, Trento M,
            Passera P et al. Estimating the delay between onset and diagnosis of
            type 2 diabetes from the time course of retinopathy prevalence.
            Diabetes Care 2014; 37: 1668–1674.
          </li>
          <li>
            Holman RR, Paul SK, Bethel MA, Matthews DR, Neil HA. 10-year
            follow-up of intensive glucose control in type 2 diabetes. N Engl J
            Med2008; 359: 1577–1589.
          </li>
          <li>
            Голодников И.И., Русяева Н.В., Никонова Т.В., Кононенко И.В.,
            Шестакова М.В. Латентный аутоиммунный диабет взрослых — современное
            представление. Сахарный диабет. 2023;26(3):262-274.
            <a href="https://doi.org/10.14341/DM12994" target="_blank"
              >https://doi.org/10.14341/DM12994</a
            >
          </li>
          <li>
            Andersson DKG, Svärdsudd K. Long-term glycemic control relates to
            mortality in type II diabetes. Diabetes Care. 1995;18(12):1534-1543.
            <a href="https://doi.org/10.2337/diacare.18.12.1534" target="_blank"
              >https://doi.org/10.2337/diacare.18.12.1534</a
            >
          </li>
          <li>
            Distiller LA. Why do some patients with type 1 diabetes live so
            long? WJD. 2014;5(3):282. doi:0.4239/wjd.v5.i3.282
          </li>
          <li>
            McCoy RG, Van Houten HK, Ziegenfuss JY, Shah ND, Wermers RA, Smith
            SA. Increased mortality of patients with diabetes reporting severe
            hypoglycemia. Diabetes Care. 2012 Sep;35(9):1897-901. doi:
            10.2337/dc11-2054. Epub 2012 Jun 14. PMID: 22699297; PMCID:
            PMC3425008.
          </li>
          <li>
            Bonds DE, Miller ME, Bergenstal RM, Buse JB, Byington RP, Cutler JA,
            Dudl RJ, Ismail-Beigi F, Kimel AR, Hoogwerf B, Horowitz KR, Savage
            PJ, Seaquist ER, Simmons DL, Sivitz WI, Speril-Hillen JM, Sweeney
            ME. The association between symptomatic, severe hypoglycaemia and
            mortality in type 2 diabetes: retrospective epidemiological analysis
            of the ACCORD study. BMJ. 2010 Jan 8;340:b4909. doi:
            10.1136/bmj.b4909. PMID: 20061358; PMCID: PMC2803744.
          </li>
          <li>
            Zoungas S, Patel A, Chalmers J, de Galan BE, Li Q, Billot L,
            Woodward M, Ninomiya T, Neal B, MacMahon S, Grobbee DE, Kengne AP,
            Marre M, Heller S; ADVANCE Collaborative Group. Severe hypoglycemia
            and risks of vascular events and death. N Engl J Med. 2010 Oct
            7;363(15):1410-8. doi: 10.1056/NEJMoa1003795. PMID: 20925543.
          </li>
          <li>
            DeSalvo DJ, Miller KM, Hermann JM, Maahs DM, Hofer SE, Clements MA,
            Lilienthal E, Sherr JL, Tauschmann M, Holl RW. T1D Exchange and DPV
            Registries. Continuous glucose monitoring and glycemic control among
            youth with type 1 diabetes: International comparison from the T1D
            Exchange and DPV Initiative. Pediatr Diabetes. 2018;19(7):1271–1275.
          </li>
          <li>
            Karter AJ, Parker MM, Moffet HH, Gilliam LK, Dlott R. Association of
            Real-time Continuous Glucose Monitoring With Glycemic Control and
            Acute Metabolic Events Among Patients With Insulin-Treated Diabetes.
            JAMA. 2021 Jun 8;325(22):2273–2284.
          </li>
          <li>
            Kudva YC, Ahmann AJ, Bergenstal RM, et al. Approach to Using Trend
            Arrows in the FreeStyle Libre Flash Glucose Monitoring Systems in
            Adults. J Endocr Soc 2018; 2:1320.
          </li>
          <li>
            Aleppo G, Laffel LM, Ahmann AJ, et al. A Practical Approach to Using
            Trend Arrows on the Dexcom G5 CGM System for the Management of
            Adults With Diabetes. J Endocr Soc 2017; 1:1445.
          </li>
          <li>
            Yapanis M, James S, Craig ME, et al. Complications of Diabetes and
            Metrics of Glycemic Management Derived From Continuous Glucose
            Monitoring. J Clin Endocrinol Metab 2022; 107:e2221.
          </li>
          <li>
            Diabetes Control and Complications Trial Research Group, Nathan DM,
            Genuth S, et al. The effect of intensive treatment of diabetes on
            the development and progression of long-term complications in
            insulin-dependent diabetes mellitus. N Engl J Med 1993; 329:977.
          </li>
          <li>
            Nathan DM, Cleary PA, Backlund JY, et al. Intensive diabetes
            treatment and cardiovascular disease in patients with type 1
            diabetes. N Engl J Med 2005; 353:2643.
          </li>
          <li>
            Bruttomesso D, Costa S, Baritussio A. Continuous subcutaneous
            insulin infusion (CSII) 30 years later: still the best option for
            insulin therapy. Diabetes Metab Res Rev. 2009;25(2):99-111.
            <a href="https://doi.org/10.1002/dmrr.931" target="_blank"
              >https://doi.org/10.1002/dmrr.931</a
            >
          </li>
          <li>
            Laffel LM, Kanapka LG, Beck RW, et al. Effect of Continuous Glucose
            monitoring on glycemic control in adolescents and young adults with
            type 1 diabetes: A randomized clinical trial. JAMA.
            2020;323(23):2388-2396.
            <a href="https://doi.org/10.1001/jama.2020.6940" target="_blank"
              >https://doi.org/10.1001/jama.2020.6940</a
            >
          </li>
          <li>
            Riddlesworth T, Price D, Cohen N, Beck RW. Hypoglycemic event
            frequency and the effect of continuous glucose monitoring in adults
            with type 1 diabetes using multiple daily insulin injections.
            Diabetes Ther. 2017;8(4):947-951.
            <a href="https://doi.org/10.1007/s13300-017-0281-4" target="_blank"
              >https://doi.org/10.1007/s13300-017-0281-4</a
            >
          </li>
          <li>
            Irace C, Cutruzzolà A, Nuzzi A, et al. Clinical use of a 180-day
            implantable glucose sensor improves glycated haemoglobin and time in
            range in patients with type 1 diabetes. Diabetes Obes Metab.
            2020;22(7):1056-1061.
            <a href="https://doi.org/10.1111/dom.13993" target="_blank"
              >https://doi.org/10.1111/dom.13993</a
            >
          </li>
          <li>
            Rebrin K, Steil GM. Can interstitial glucose assessment replace
            blood glucose measurements? Diabetes Technol Ther.
            2000;2(3):461-472.
            <a href="https://doi.org/10.1089/15209150050194332" target="_blank"
              >https://doi.org/10.1089/15209150050194332</a
            >
          </li>
          <li>
            Maahs DM, DeSalvo D, Pyle L, et al. Effect of acetaminophen on CGM
            glucose in an outpatient setting. Diabetes Care 2015; 38:e158.
          </li>
          <li>
            Sai S, Urata M, Ogawa I. Evaluation of linearity and interference
            effect on SMBG and POCT devices, showing drastic high values, low
            values, or error messages. J Diabetes Sci Technol 2019;13:734–743
          </li>
          <li>
            Ginsberg BH. Factors affecting blood glucose monitoring: sources of
            errors in measurement. J Diabetes Sci Technol 2009;3:903–913
          </li>
          <li>
            da Rocha RB, Silva CS, Cardoso VS. Self-Care in Adults with Type 2
            Diabetes Mellitus: A Systematic Review. Curr Diabetes Rev.
            2020;16(6):598-607. doi: 10.2174/1573399815666190702161849. PMID:
            31267873.
          </li>
          <li>
            Cyranka K, Klupa T, Pilecki M, Sarna-Palacz D, Juryk A, Storman D,
            Dudek D, Malecki MT, Matejko B.Endocrine. Diabetes distress and
            diabetes burnout explored in various areas of life in patients with
            type 1 diabetes: effect of short-term psychological intervention.
            2024 Mar 6. doi: 10.1007/s12020-024-03760-0. Online ahead of print.
            PMID: 3844867
          </li>
          <li>
            Stewart SM, Rao U, Emslie GJ, et al. Depressive symptoms predict
            hospitalization for adolescents with type 1 diabetes mellitus.
            Pediatrics 2005; 115:1315.
          </li>
          <li>
            Lawrence JM, Standiford DA, Loots B, et al. Prevalence and
            correlates of depressed mood among youth with diabetes: the SEARCH
            for Diabetes in Youth study. Pediatrics 2006; 117:1348.
          </li>
          <li>
            Battelino T, Danne T, Bergenstal RM, et al. 2019;42(8):1593‐1603.
            doi:10.2337/dci19-0028
          </li>
          <li>
            ElSayed NA, Aleppo G, Aroda VR, et al. 2. Classification and
            Diagnosis of Diabetes: Standards of Care in Diabetes-2023 [published
            correction appears in Diabetes Care. 2023 Feb 01] [published
            correction appears in Diabetes Care. 2023 Sep 1;46(9):1715].
            Diabetes Care. 2023;46(Suppl 1):S19-S40. doi:10.2337/dc23-S002.
          </li>
          <li>
            System accuracy of blood glucose monitoring systems: impact of use
            by patients and ambient conditions. Schmid C, Haug C, Heinemann L,
            Freckmann G.Diabetes Technol Ther. 2013 Oct;15(10):889-96. doi:
            10.1089/dia.2013.0047. Epub 2013 Jul 24.
          </li>
          <li>
            American Diabetes Association Professional Practice Committee. 14.
            Children and Adolescents: Standards of Medical Care in
            Diabetes-2022. Diabetes Care 2022; 45:S208.
          </li>
          <li>
            de Bock M, Codner E, Craig ME, et al. ISPAD Clinical Practice
            Consensus Guidelines 2022: Glycemic targets and glucose monitoring
            for children, adolescents, and young people with diabetes. Pediatr
            Diabetes 2022; 23:1270.
          </li>
          <li>
            Chiang JL, Kirkman MS, Laffel LM, et al. Type 1 diabetes through the
            life span: a position statement of the American Diabetes
            Association. Diabetes Care 2014; 37:2034.
          </li>
          <li>
            Barnea-Goraly N, Raman M, Mazaika P, et al. Alterations in white
            matter structure in young children with type 1 diabetes. Diabetes
            Care 2014; 37:332.
          </li>
          <li>
            Writing Team for the Diabetes Control and Complications
            Trial/Epidemiology of Diabetes Interventions and Complications
            Research Group. Sustained effect of intensive treatment of type 1
            diabetes mellitus on development and progression of diabetic
            nephropathy: the Epidemiology of Diabetes Interventions and
            Complications (EDIC) study. JAMA 2003; 290:2159.
          </li>
          <li>
            Cengiz E, Danne T, Ahmad T, et al. ISPAD Clinical Practice Consensus
            Guidelines 2022: Insulin treatment in children and adolescents with
            diabetes. Pediatr Diabetes 2022; 23:1277.
          </li>
          <li>
            Karges B, Schwandt A, Heidtmann B, et al. Association of Insulin
            Pump Therapy vs Insulin Injection Therapy With Severe Hypoglycemia,
            Ketoacidosis, and Glycemic Control Among Children, Adolescents, and
            Young Adults With Type 1 Diabetes. JAMA 2017; 318:1358.
          </li>
          <li>
            Beck RW, Miller KM, Foster NC. The T1D Exchange Clinic Network and
            Registry: 10 Years of Enlightenment on the State of Type 1 Diabetes
            in the United States. Diabetes Technol Ther 2019; 21:310.
          </li>
          <li>
            Sherr JL, Schoelwer M, Dos Santos TJ, et al. ISPAD Clinical Practice
            Consensus Guidelines 2022: Diabetes technologies: Insulin delivery.
            Pediatr Diabetes 2022; 23:1406.
          </li>
          <li>
            Glycemic Targets: Standards of Medical Care in Diabetes—2021
            American Diabetes Association Diabetes Care 2021 Jan; 44 (Supplement
            1): S73-S84
          </li>
          <li>
            Joseph JI. Review of the Long-Term Implantable Senseonics Continuous
            Glucose Monitoring System and Other Continuous Glucose Monitoring
            Systems. J Diabetes Sci Technol. 2021 Jan;15(1):167-173. doi:
            10.1177/1932296820911919. Epub 2020 Apr 29. Erratum in: J Diabetes
            Sci Technol. 2024 Jan;18(1):249. PMID: 32345047; PMCID: PMC7783000.
          </li>
          <li>
            Wu J, Liu Y, Yin H, Guo M. A new generation of sensors for
            non-invasive blood glucose monitoring. Am J Transl Res. 2023 Jun
            15;15(6):3825-3837. PMID: 37434817; PMCID: PMC10331674.
          </li>
          <li>
            Rodbard D. Continuous Glucose Monitoring: A Review of Recent Studies
            Demonstrating Improved Glycemic Outcomes. Diabetes Technol Ther.
            2017 Jun;19(S3):S25-S37. doi: 10.1089/dia.2017.0035. PMID: 28585879;
            PMCID: PMC5467105.
          </li>
          <li>
            R. Nimri, A.R. Ochs, J.E. Pinsker, et al. Decision support systems
            and closed loop. Diabetes Technol Ther, 21 (S1) (2019), pp. S42-S56
          </li>
          <li>
            Ekhlaspour L, Raghinaru D, Forlenza GP, Isganaitis E, Kudva YC, Lam
            DW, Levister C, O'Malley G, Church MM, Lum JW, Buckingham B, Brown
            SA. Outcomes in Pump- and CGM-Baseline Use Subgroups in the
            International Diabetes Closed-Loop Trial. J Diabetes Sci Technol.
            2023 Jul;17(4):935-942. doi: 10.1177/19322968221089361. Epub 2022
            Apr 26. PMID: 35473359; PMCID: PMC10347978.
          </li>
          <li>
            Ware J, Hovorka R. Closed-loop insulin delivery: update on the state
            of the field and emerging technologies. Expert Rev Med Devices. 2022
            Nov;19(11):859-875. doi: 10.1080/17434440.2022.2142556. Epub 2022
            Nov 4. PMID: 36331211; PMCID: PMC9780196.
          </li>
          <li>
            What is the pancreas? What is an artificial pancreas device system?
            08/30/2018
            <a
              href="https://www.fda.gov/medical-devices/artificial-pancreas-device-system/what-pancreas-what-artificial-pancreas-device-system#resources"
              target="_blank"
              >https://www.fda.gov/medical-devices/artificial-pancreas-device-system/what-pancreas-what-artificial-pancreas-device-system#resources</a
            >
            (доступ 20.05.24)
          </li>
          <li>
            FDA Clears New Insulin Pump and Algorithm-Based Software to Support
            Enhanced Automatic Insulin Delivery. May 19, 2023.
            <a
              href="https://www.fda.gov/news-events/press-announcements/fda-clears-new-insulin-pump-and-algorithm-based-software-support-enhanced-automatic-insulin-delivery?_ga=2.223358427.1188439105.1716793857-374940888.1716793857"
              target="_blank"
              >https://www.fda.gov/news-events/press-announcements/fda-clears-new-insulin-pump-and-algorithm-based-software-support-enhanced-automatic-insulin-delivery?_ga=2.223358427.1188439105.1716793857-374940888.1716793857</a
            >
            (доступ от 20.05.24)
          </li>
          <li>
            Prasad RB, Groop L. Genetics type 2 diabetes-pitfalls and
            possibilities. Genes (Basel). 2015 Mar 12;6(1):87-1
          </li>
          <li>
            Skrabic, R.; Kumric, M.; Vrdoljak, J.; et al. SGLT2 Inhibitors in
            Chronic Kidney Disease: From Mechanisms to Clinical Practice.
            Biomedicines 2022, 10, 2458. doi.org/10.3390/biomedicines10102458
          </li>
          <li>
            Damiano ER, El-Khatib FH, Zheng H, et al. A comparative
            effectiveness analysis of three continuous glucose monitors.
            Diabetes Care 2013; 36:251.
          </li>
          <li>
            Damiano ER, McKeon K, El-Khatib FH, et al. A comparative
            effectiveness analysis of three continuous glucose monitors: the
            Navigator, G4 Platinum, and Enlite. J Diabetes Sci Technol 2014;
            8:699.
          </li>
          <li>
            Christiansen MP, Garg SK, Brazg R, et al. Accuracy of a
            Fourth-Generation Subcutaneous Continuous Glucose Sensor. Diabetes
            Technol Ther 2017; 19:446.
          </li>
          <li>
            Welsh JB, Gao P, Derdzinski M, et al. Accuracy, Utilization, and
            Effectiveness Comparisons of Different Continuous Glucose Monitoring
            Systems. Diabetes Technol Ther 2019; 21:128.
          </li>
          <li>
            Christiansen MP, Klaff LJ, Brazg R, et al. A Prospective Multicenter
            Evaluation of the Accuracy of a Novel Implanted Continuous Glucose
            Sensor: PRECISE II. Diabetes Technol Ther 2018; 20:197.
          </li>
          <li>
            Демидова Т.Ю., Ушанова Ф.О. Современные технологии непрерывного
            мониторинга гликемии: развивающиеся возможности контроля и
            управления. РМЖ. 2018;11(II):86-90.
          </li>
        </ol>
        <label
          for="sources"
          class="
            sources__button
            button button_empty-violet
            px-8
            py-4
            mx-auto
            mt-10
          "
        >
          Все источники
        </label>
      </div>
    </section>
  </div>
</template>

<script>
import PagesSlider from "@/components/pageComponents/SD2/PagesSlider.vue";
import Test from "@/components/pageComponents/SD2/Test.vue";
import { bus } from "@/main";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "SD2",
  components: {
    PagesSlider,
    PopUpDefault,
    Swiper,
    SwiperSlide,
    Test,
  },
  data: () => ({
    firstTabsActive: 0,
    firstBlockModal: false,
    tab2ActiveTab: 0,
    tab3ActiveTab: 0,
    tab4ActiveTab: 0,
    secondTabsActive: 0,
    block2Tab1ActiveTab: 0,
    block3ActiveTab: 0,
    fourthTabsActive: 0,
    casesActiveTab: 0,
    sixthTabsActive: 0,
    secondBlockPinkBlockVisible: false,
    testKey: 0,
    swiperSettings: {
      slidesPerView: 1,
      spaceBetween: 30,

      pagination: {
        el: ".slider-dots__row",
        type: "bullets",
        clickable: true,
        bulletClass: "slider-dots__dot",
        bulletActiveClass: "slider-dots__dot_active",
      },
    },
    swiperSettings2: {
      slidesPerView: "auto",
      spaceBetween: 30,

      pagination: {
        el: ".slider-dots__row",
        type: "bullets",
        clickable: true,
        bulletClass: "slider-dots__dot",
        bulletActiveClass: "slider-dots__dot_active",
      },
    },
    swiperSettings3: {
      slidesPerView: "auto",
      spaceBetween: 30,

      pagination: {
        el: ".slider-dots__row",
        type: "bullets",
        clickable: true,
        bulletClass: "slider-dots__dot",
        bulletActiveClass: "slider-dots__dot_active",
      },
    },
  }),
  computed: {
    topBanners() {
      return [
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner1_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner1_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner1.jpg`,
        },
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner2_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner2_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner2.jpg`,
        },
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner3_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner3_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/banner3.jpg`,
        },
      ];
    },
    questions() {
      return [
        [
          {
            type: "once",
            title: "Установите диагноз",
            answers: [
              "Сахарный диабет неуточненный",
              "Сахарный диабет 1 типа",
              "Сахарный диабет типа МОДИ",
              "Сахарный диабет 2 типа",
            ],
          },
          {
            type: "once",
            title:
              "Какие наиболее вероятные причины развития гипогликемии у данной пациентки?",
            answers: [
              "«Медовый месяц»",
              "Индуцированные гипогликемии",
              "Передозировка инсулина",
              "Синдром мальабсорбции",
            ],
          },
          {
            type: "more",
            title:
              "Необходимые обследования в данной клинической ситуации (выберите один или несколько вариантов ответа)",
            answers: [
              "Гликированный гемоглобин",
              "Анализ крови на ТТГ, свТ4, АТ к ТПО",
              "Анализ мочи на альбуминурию",
              "Анализ крови на липиды",
              "Молекулярно-генетическое исследование на моногенные формы СД",
            ],
          },
          {
            type: "once",
            title:
              "Каковы целевые показатели гликированного гемоглобина у детей с СД1",
            answers: ["7-10%", "10-12%", "<7%", "<6%"],
          },
          {
            type: "once",
            title: "Проведите коррекцию терапии",
            answers: [
              "Снизить дозы инсулина Деглюдек перед сном и Аспарт перед ужином",
              "Увеличить дозу Аспарта в утренние часы",
              "Перенести прием Деглюдека на дневное время",
              "Добавить к терапии Метформин 500 мг/сут",
            ],
          },
          {
            type: "once",
            title: "Рекомендации",
            answers: [
              "Контроль гликированного гемоглобина 1 раз в 3 мес",
              "ПГТТ в динамике",
              "Контроль гликированного гемоглобина через 1 мес",
              "Контроль уровня инсулина натощак",
            ],
          },
        ],
        [
          {
            type: "once",
            title: "Установите диагноз",
            answers: [
              "Сахарный диабет 1 типа",
              "Неонатальный сахарный диабет",
              "Транзиторная гипергликемия",
              "Сахарный диабет 2 типа",
            ],
          },
          {
            type: "more",
            title:
              "Какие симптомы характерны в дебюте неонатального СД (выберите один или несколько вариантов ответа)",
            answers: [
              "Избыточная прибавка веса",
              "Отсутствие набора веса",
              "Беспокойство, нарушенный сон",
              "Увеличение размеров печени",
            ],
          },
          {
            type: "more",
            title:
              "Какие обследования целесообразно провести в данном случае для подтверждения диагноза? (выберите один или несколько вариантов ответа)",
            answers: [
              "Анализ крови на С-пептид натощак и после приема пищи",
              "Анализ мочи на альбуминурию",
              "Анализ крови на ТТГ, свТ4",
              "Молекулярно-генетическое исследование",
            ],
          },
          {
            type: "once",
            title: "Предложите рекомендации по терапии",
            answers: [
              "Повысить дозу инсулина",
              "Добавить к терапии препараты метформина",
              "Пробная терапия препаратами глибенкламида",
              "Оставить без изменений",
            ],
          },
          {
            type: "once",
            title:
              "Каковы целевые показатели гликированного гемоглобина у детей с СД",
            answers: ["7-10%", "10-12%", "<7%", "<6%"],
          },
          {
            type: "once",
            title:
              "Какие показатели могут быть использованы для дополнительной оценки компенсации СД?",
            answers: [
              "Показатели АД",
              "Уровень глюкозы в моче",
              "Данные НМГ о вариабельности гликемии",
              "Уровень С-пептида крови",
            ],
          },
        ],
        [
          {
            type: "once",
            title: "Укажите предварительный диагноз",
            answers: [
              "Сахарный диабет 1 типа",
              "Нарушение толерантности к углеводам",
              "Гипергликемия натощак",
              "Сахарный диабет 2 типа",
            ],
          },
          {
            type: "once",
            title: "Симптомы, характерные для манифестации СД 1 типа",
            answers: [
              "Избыточная прибавка веса",
              "Повышение температуры тела",
              "Повышенный аппетит",
              "Судороги",
            ],
          },
          {
            type: "once",
            title:
              "Какие обследования целесообразно провести в данном случае для уточнения диагноза?",
            answers: [
              "ПГТТ с оценкой уровня гликемии и инсулина/С-пептида",
              "Анализ мочи на альбуминурию",
              "Анализ крови на ТТГ, свТ4",
              "УЗИ брюшной полости",
            ],
          },
          {
            type: "once",
            title: "Протокол проведения ПГТТ в детском возрасте",
            answers: [
              "Контроль гликемии натощак и через 2 часа после приема сладкой пищи",
              "Контроль гликемии через 1 час после приема раствора глюкозы 75 г",
              "Контроль гликемии натощак и через 2 часа после приема раствора глюкозы 1,75 г/кг массы тела (не более 75 г)",
              "Контроль гликемии каждые 30 мин в течение 4х часов после приема раствора глюкозы 75&nbsp;г",
            ],
          },
          {
            type: "once",
            title:
              "Какой диагноз можно выставить пациентке  на основании полученных результатов ПГТТ?",
            description: `
                <div class="overflow">
                  <div class="overflow__body">
                    <img
                      style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-description1.jpg"
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-description1.jpg"
                    />
                  </div>
                </div>
              `,
            answers: [
              "Сахарный диабет",
              "Гипергликемия натощак, нарушение толерантности к углеводам",
              "Норма",
              "Инсулинорезстентность",
            ],
          },
          {
            type: "more",
            title:
              "Какие дополнительные обследования показаны в данном случае? (выберите один или несколько вариантов ответа)",
            answers: [
              "Анализ крови на гликированный гемоглобин",
              "Анализ крови на антитела к глиадину",
              "Суточное мониторирование гликемии",
              "3-часовой ПГТТ",
            ],
          },
          {
            type: "once",
            title:
              "Какая наиболее вероятная причина нарушения гликемии у данной пациентки и какое обследование целесообразно провести в данном случае?",
            answers: [
              "Дебют СД 1 типа, требуется анализ крови на АТ к островковым клеткам",
              "Дебют СД 2 типа, не требуется дополнительных исследований",
              "Диабет типа МОДИ. Требуется молекулярно-генетическое исследование",
              "Диабет, ассоциированный с муковисцидозом. Требуется обследование на муковисцидоз",
            ],
          },
        ],
      ];
    },
    tips() {
      return [
        [
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "Диагноз СД 1 типа был подтвержден на основании высоких уровней антител к островковым клеткам.",
            },
            {
              status: true,
              conditions: [["2"]],
              title: "Правильный ответ!",
              body: "Диагноз СД 1 типа был подтвержден на основании высоких уровней антител к островковым клеткам.",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "Диагноз СД 1 типа был подтвержден на основании высоких уровней антител к островковым клеткам. Для моногенного варианта диабета характерно наличие отягощенного семейного анамнеза, возраст манифестации при МОDY диабете как правило приходится на подростковый возраст.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "Диагноз СД 1 типа был подтвержден на основании высоких уровней антител к островковым клеткам, отмечается задавленный уровень С-пептида, что нехарактерно для СД2, также возраст пациента говорит против второго типа диабета.",
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: "Учитывая стаж диабета, невысокие дозы инсулина, наиболее вероятно развитие «медового месяца».",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "Учитывая стаж диабета, невысокие дозы инсулина, наиболее вероятно развитие «медового месяца». Синдром Мюнгхаузена вероятен, однако не очевиден в данном случае.",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "Учитывая стаж диабета, невысокие дозы инсулина, наиболее вероятно развитие «медового месяца». Учитывая невысокие дозы инсулина (~0,3 Ед/кг/сут) передозировка маловероятна.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "Учитывая стаж диабета, невысокие дозы инсулина, наиболее вероятно развитие «медового месяца». Нет клинических признаков мальабсорбции.",
            },
          ],
          [
            {
              status: true,
              conditions: [["1", "2"]],
              title: "Правильный ответ!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              `,
            },
            {
              status: null,
              conditions: [["1"]],
              title: "Вы на правильном пути, дополните обследование!",
              body: "<b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.",
            },
            {
              status: null,
              conditions: [["2"]],
              title: "Вы на правильном пути, дополните обследование!",
              body: `
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              `,
            },
            {
              status: null,
              conditions: [["1", "3"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              `,
            },
            {
              status: null,
              conditions: [["1", "4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: null,
              conditions: [["1", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: null,
              conditions: [["2", "3"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              `,
            },
            {
              status: null,
              conditions: [["2", "4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
             <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: null,
              conditions: [["2", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
               <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
              <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "4", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Гликированный гемоглобин = 6,9%.</b> Исследование показано 1 раз в 3 мес при ведении пациентов с СД 1 типа.
              <br>
              <br>
              <b>ТТГ = 2.76 мМЕ/л (0,5–4,8); свТ4 = 14,7 пмоль/л (11,2–18,6); АТ к ТПО = 367, 8 МЕ/мл (0–5,6)</b>
              Целесообразно ввиду повышенного риска аутоиммунного тиреоидита у пациентов с СД 1 типа.
              <br>
              <br>
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              <br>
              <br>
              <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: false,
              conditions: [["5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: false,
              conditions: [["3", "4"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
               <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              `,
            },
            {
              status: false,
              conditions: [["3", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
               <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: false,
              conditions: [["4", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
               <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              <br>
              <br>
               <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
            {
              status: false,
              conditions: [["3", "4", "5"]],
              title: "Требуется коррекция плана обследования!",
              body: `
              <b>Альбумин/креатинин 0.9724365 мг/ммоль (норма 0–3,5).</b> Скрининг на диабетическую нефропатию рекомендован с 11 лет при стаже диабета более 2-х лет.
              <br>
              <br>
               <b>При проведении анализа крови на липиды показатели в пределах нормы.</b> Контроль липидограммы детям с СД 1 типа при отсутствии дополнительных показаний рекомендовано проводить 1 раз в год.
              <br>
              <br>
               <b>При проведении молекулярно-генетического исследования мутаций не выявлено.</b> Нецелесообразно, учитывая отсутствия сомнений в аутоиммунной природе СД.
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `Целевые показатели гликированного гемоглобина у детей с СД1 составляют <7% HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>`,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `Целевые показатели гликированного гемоглобина у детей с СД1 составляют <7% HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>`,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `Целевые показатели гликированного гемоглобина у детей с СД1 составляют <7% HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>`,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `Целевые показатели гликированного гемоглобина у детей с СД1 составляют <7% HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>`,
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: `
              На фоне снижения доз инсулина Деглюдек перед сном и Аспарт перед ужином показатели гликемии с тенденцией к нормализации
              <br>
              <br>
              <div class="full-screen">
                    <div
                      class="full-screen__trigger d-md-none"
                      data-coolbox="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content1.jpg
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    
                      <img
                        src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content1.jpg"
                      />
                   
                  </div>
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `При увеличении дозы Аспартата в утренние часы эпизоды гипогликемии стали чаще, однократно зафиксирована тяжелая гипогликемия 2,5 ммоль/л.`,
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: `При смещении приема Деглюдека на дневное время вариабельность гликемии без существенных изменений, сохраняются эпизоды гипогликемии.`,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `При добавлении к терапии Метформина 500 мг/сут появились жалобы на тошноту, боли в животе, тенденция к гипогликемии после приема пищи.`,
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: `Пациентам с СД показан контроль гликированного гемоглобина 1 раз в 3 мес.`,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `Учитывая, что диагноз СД 1 типа сомнения не вызывает проведение ПГТТ нецелесообразно.`,
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: `За 1 мес динамика гликированного гемоглобина не информативна.`,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `Учитывая, что диагноз СД 1 типа сомнения не вызывает и пациент находится на инсулинотерапии, исследование уровня инсулина нецелесообразно.`,
            },
          ],
        ],
        [
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "Обоснование: против диагноза СД 1 типа типа говорят дебют заболевания до 6 мес жизни, длительная ремиссия, отсутствие специфических антител при рецидиве заболевания.",
            },
            {
              status: true,
              conditions: [["2"]],
              title: "Правильный ответ!",
              body: "Обоснование: дебют СД в младенческом возрасте (до 6 мес жизни) с последующей ремиссией и рецидивом в подростковом возрасте говорит в пользу транзиторного неонатального СД.",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "Обоснование: транзиторный характер СД в младенчестве характерен для некоторых форм неонатального СД, что нельзя расценивать как транзиторную гипергликемию.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "Обоснование: сахарный диабет 2 типа ассоциирован с инсулинорезистентностью и характерен для взрослых пациентов. Дебют заболевания в возрасте до 6 мес указывает на неонатальный вариант СД.",
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              `,
            },
            {
              status: null,
              conditions: [["2"]],
              title: "Дополните ответ!",
              body: "Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.",
            },
            {
              status: null,
              conditions: [["3"]],
              title: "Дополните ответ!",
              body: "В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "<b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).",
            },
            {
              status: null,
              conditions: [["1", "2"]],
              title: "Уточните ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              `,
            },
            {
              status: null,
              conditions: [["1", "3"]],
              title: "Уточните ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Беспокойство, нарушенный сон.</b> В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.
              `,
            },
            {
              status: false,
              conditions: [["1", "4"]],
              title: "Неправильный ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).
              `,
            },
            {
              status: true,
              conditions: [["2", "3"]],
              title: "Правильный ответ!",
              body: `
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Беспокойство, нарушенный сон.</b> В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.
              `,
            },
            {
              status: null,
              conditions: [["2", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3"]],
              title: "Уточните ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Беспокойство, нарушенный сон.</b> В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).
              `,
            },
            {
              status: null,
              conditions: [["2", "3", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Беспокойство, нарушенный сон.</b> В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.
              <br>
              <br>
              <b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Избыточная прибавка веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Отсутствие набора веса.</b> Учитывая высокую гипергликемию и относительную (или абсолютную) инсулиновую недостаточность, дети с неонатальным сахарным диабетом плохо прибавляют в весе.
              <br>
              <br>
              <b>Беспокойство, нарушенный сон.</b> В неонатальном периоде, полидипсия и полифагия чаще всего проявляются беспокойством ребенка (постоянно просит есть) и нарушенным сном.
              <br>
              <br>
              <b>Увеличение размеров печени.</b> Не характерно для большинства форм неонатального СД (за исключением редких синдромальных вариантов с аплазией поджелудочной железы).
              `,
            },
          ],
          [
            {
              status: null,
              conditions: [["1"]],
              title: "Дополните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл.</b> Примечание: остаточная секреция С-пептида говорит против СД 1 типа.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "<b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "<b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.",
            },
            {
              status: null,
              conditions: [["4"]],
              title: "Дополните ответ!",
              body: `
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
            {
              status: null,
              conditions: [["1", "2"]],
              title: "Уточните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл. Примечание: остаточная секреция С-пептида говорит против СД 1 типа.</b>
              <br>
              <br>
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              `,
            },
            {
              status: null,
              conditions: [["1", "3"]],
              title: "Уточните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл. Примечание: остаточная секреция С-пептида говорит против СД 1 типа.</b>
              <br>
              <br>
              <b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.
              `,
            },
            {
              status: true,
              conditions: [["1", "4"]],
              title: "Правильный ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл.</b> Примечание: остаточная секреция С-пептида говорит против СД 1 типа.
              <br>
              <br>
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
            {
              status: false,
              conditions: [["2", "3"]],
              title: "Неправильный ответ!",
              body: `
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.
              `,
            },
            {
              status: null,
              conditions: [["2", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3"]],
              title: "Уточните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл.</b> Примечание: остаточная секреция С-пептида говорит против СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл.</b> Примечание: остаточная секреция С-пептида говорит против СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
            {
              status: null,
              conditions: [["2", "3", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.
              <br>
              <br>
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "4"]],
              title: "Уточните ответ!",
              body: `
              <b>Результат исследования С-пептида: натощак 1,9 нг/мл; через 2 часа после еды 5,4 нг/мл.</b> Примечание: остаточная секреция С-пептида говорит против СД 1 типа.
              <br>
              <br>
              <b>При проведении анализа мочи на альбуминурию отклонений не выявлено.</b> Примечание: Не является информативным для уточнения типа СД
              <br>
              <br>
              <b>При проведении анализа крови: ТТГ 4,3 мЕ/мл (норма); свТ4 13,4 пмоль/л (норма).</b> Примечание: Анализ крови на ТТГ, свТ4 не является информативным для уточнения типа СД.
              <br>
              <br>
              <b>Проведен микроматричный анализ ДНК – выявлено гипометилирование
              материнской аллели локуса 6q24.</b> Примечание: для подтверждения моногенной формы СД, в том числе неонатального диабета оптимально проведение молекулярно-генетического исследования (включая NGS панель и микроматричный анализ ДНК).
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              <b>Показатели гликемии без существенной динамики (сохраняется выраженная вариабельность).</b> Учитывая моногенный характер СД, целесообразна пробная терапия препаратами сульфонилмочевины.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              <b>Отмечается нарушение стула, жалобы на тошноту, вариабельность гликемии прежняя 3,0–18,9 ммоль/л.</b> Учитывая моногенный характер СД, целесообразна пробная терапия препаратами сульфонилмочевины.
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `
              Учитывая моногенный характер СД, целесообразна пробная терапия препаратами сульфонилмочевины. В динамике на фоне  введения препаратов сульфонилмочевины наблюдалось улучшение состояния, инсулин был полностью отменен, колебания гликемии при этом от 4 до 9 ммоль/л
              <br>
              <br>
              <div class="full-screen">
                    <div
                      class="full-screen__trigger d-md-none"
                      data-coolbox="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content2.jpg
                      "
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          fill="#830051"
                        ></rect>
                        <path
                          d="M23 11H29V17"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 29H11V23"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29 11L22 18"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 29L18 22"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    
                      <img
                        src="
                          https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content2.jpg
                        "
                      />
                   
                  </div>
              
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              <b>Показатели гликемии прежние, Гликированный гемоглобин в динамике с нарастанием до 9%.</b> Учитывая моногенный характер СД, целесообразна пробная терапия препаратами сульфонилмочевины.
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              Целевые показатели гликированного гемоглобина у детей с СД <7%.
              О целевых показателях HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              Целевые показатели гликированного гемоглобина у детей с СД <7%.
              О целевых показателях HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `
              Целевые показатели гликированного гемоглобина у детей с СД <7%.
              О целевых показателях HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              Целевые показатели гликированного гемоглобина у детей с СД <7%.
              О целевых показателях HbA1C можно прочитать <a href="#" onclick="window.sd2.fromTestToAnchor('hba1c', event)">Подробнее</a>
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              Наличие или отсутствие артериальной гипертензии не говорит о компенсации сахарного диабета. Для оценки компенсации СД используются показатели гликемии и гликированного гемоглобина.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              Не является достаточно информативным для оценки компенсации СД. Для оценки компенсации СД используются показатели гликемии и гликированного гемоглобина.
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `
              Данные НМГ могут использованы как альтернатива и дополнение к гликированному гемоглобину в целях дополнительной оценки компенсации СД.
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              Не является показательным для оценки компенсации СД. Для оценки компенсации СД используются показатели гликемии и гликированного гемоглобина.
              `,
            },
          ],
        ],
        [
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "На данном этапе данных за СД недостаточно (выявлено только повышение гликемии натощак). Требуется дообследование.",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "На данном этапе обследования данных за НТГ нет. Требуется дообследование.",
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: "На данном этапе обследования выявлена лишь гипергликемия натощак. Требуется дообследование.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "На данном этапе данных за СД недостаточно (выявлено только повышение гликемии натощак). Требуется дообследование.",
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              При инсулиновой недостаточности на фоне СД 1 типа характерна потеря веса.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "Гипергликемия, как правило, не приводит к гипертермии.",
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: "На фоне высокой гипергликемии характерен повышенный аппетит.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "Судороги не характерны для манифестации СД.",
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: `
              При выявлении умеренной гипергликемии для оценки углеводного обмена целесообразно проведение ПГТТ.
              <br>
              Результат проведенного ПГТТ с оценкой уровня гликемии и инсулина/С-пептида.
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content3.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content3.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "<b>При проведении анализа мочи на альбуминурию изменений не выявлено.</b> Не является информативным для уточнения диагноза.",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "<b>Получены результаты: ТТГ 2,6 мЕ/мл (норма); свТ4 16 пмоль/л (норма).</b> Не является информативным для уточнения диагноза.",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              <b>При проведении УЗИ брюшной полости отклонений не выявлено.</b> Не является информативным для уточнения диагноза.
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank" >Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank" >Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank" >Скачать памятку по ПГТТ</a> 
                <img
                class="d-inline"
                style="vertical-align: middle"
                  src="
                    https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                  "
                />
                `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank">Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank" >Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
            {
              status: true,
              conditions: [["2"]],
              title: "Правильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank">Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: `
             <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank">Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              <a  href="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/sd2/%D0%9F%D0%93%D0%A2%D0%A2.pdf"
              download="ПГТТ.pdf" target="_blank">Скачать памятку по ПГТТ</a> 
               <img
               class="d-inline"
               style="vertical-align: middle"
                src="
                  https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/download.svg
                "
              />
              `,
            },
          ],
          [
            {
              status: null,
              conditions: [["1"]],
              title: "Проведите дообследование!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ не является информативным в данном случае.
              `,
            },
            {
              status: null,
              conditions: [["3"]],
              title: "Проведите дообследование!",
              body: `
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["1", "2"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              `,
            },
            {
              status: null,
              conditions: [["1", "3"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["1", "4"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["2", "3"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: false,
              conditions: [["2", "4"]],
              title: "Неправильный ответ!",
              body: `
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["3", "4"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                       https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      :src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "4"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["2", "3", "4"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
            {
              status: null,
              conditions: [["1", "2", "3", "4"]],
              title: "Требуется уточнение плана обследования!",
              body: `
              <b>Анализ крови на гликированный гемоглобин.</b> Результат анализа крови на гликированный гемоглобин 6,4%.
              <br>
              Анализ крови на гликированный гемоглобин даст информацию о среднем уровне гликемии за прошедшие 3 месяца.
              <br>
              <br>
              <b>Анализ крови на антитела к глиадину.</b> При проведении анализа крови на антитела к к глиадину – антитела не выявлены. Анализ н е является информативным в данном случае.
              <br>
              <br>
              <b>Суточное мониторирование гликемии.</b> Проведение суточного мониторирования гликемии предоставит более детальную информацию о гликемической кривой и колебаниях гликемии в течение дня.
              <br>
              При проведении суточного мониторирования гликемии получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content4.jpg
                      "
                    />
                  </div>
                </div>
              <br>
              <br>
              <b>3-часовой ПГТТ.</b> Проведение 3-часового ПГТТ не даст дополнительное информации. При попытке проведения получен результат:
              <br>
              <br>
              <div class="overflow">
                  <div class="overflow__body">
                    <img
                    style="width: auto !important"
                      class="d-block d-md-none d-xl-block"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                    <img
                      style="width: 100% !important"
                      class="d-none d-md-block d-xl-none"
                      src="
                        https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/sd2/test-content5.png
                      "
                    />
                  </div>
                </div>
              `,
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              Клиническая картина не типична для СД1, кроме того, отмечается сохранная секреция С-пептида и инсулина.
              <br>
              При иммунологическом исследовании АТ к островковым клеткам получены отрицательные результаты.
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "Клиническая картина не характерна для СД 2 типа (нет избытка веса и инсулинорезистентности)",
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: "Учитывая отягощенный семейный анамнез, умеренную гипергликемию, возраст пациентки, вероятнее всего речь идет о моногенной форме СД. При проведении молекулярно-генетического исследования выявлена мутация в гене GCK (характерно для диабета MODY 2).",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "Нет клинических данных за муковисцидоз.",
            },
          ],
        ],
      ];
    },
  },
  methods: {
    refreshTest() {
      this.testKey++;
    },
    choiceFirstTab(tab) {
      this.$set(this, "firstTabsActive", tab);
      document
        .querySelector(".first-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".first-block__tabs-row").scrollLeft =
          document.querySelector(".first-block__tab_active").offsetLeft - 76;
      });
    },
    choiceSecondTab(tab) {
      this.$set(this, "secondTabsActive", tab);
      document
        .querySelector(".second-block.tab-pinned")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".second-block__tabs-row").scrollLeft =
          document.querySelector(".second-block__tab_active").offsetLeft - 76;
      });
    },
    firstBlockModalToggle(state) {
      this.$set(this, "firstBlockModal", state);
      bus.$emit("scrollLock", state);
    },
    changeTabs2(tab) {
      this.$set(this, "tab2ActiveTab", tab);
      document
        .querySelector(".first-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    changeTabs3(tab) {
      this.$set(this, "tab3ActiveTab", tab);
      document
        .querySelector(".first-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".first-block__tab3-tabs-list").scrollLeft =
          document.querySelector(".first-block__tab3-tabs-tab_active")
            .offsetLeft - 76;
      });
    },
    changeBlock2Tabs1(tab) {
      this.$set(this, "block2Tab1ActiveTab", tab);
      window.scrollTo({
        top:
          document
            .querySelector(".second-block__tab1-kinds")
            .getBoundingClientRect().top +
          window.pageYOffset -
          200,
      });
      this.$nextTick(() => {
        document.querySelector(
          ".second-block__tab1-kinds-tabs-list"
        ).scrollLeft =
          document.querySelector(".second-block__tab1-kinds-tabs-tab_active")
            .offsetLeft - 76;
      });
    },
    changeBlock3Tabs(tab) {
      this.$set(this, "block3ActiveTab", tab);
      window.scrollTo({
        top:
          document.querySelector(".third-block__tabs").getBoundingClientRect()
            .top +
          window.pageYOffset -
          200,
      });
      this.$nextTick(() => {
        document.querySelector(".third-block__tabs-list").scrollLeft =
          document.querySelector(".third-block__tabs-tab_active").offsetLeft -
          76;
      });
    },
    changeTabs4(tab) {
      this.$set(this, "tab4ActiveTab", tab);
    },

    choiceFourthTab(tab) {
      this.$set(this, "fourthTabsActive", tab);
      this.$set(this, "casesActiveTab", 0);
      this.refreshTest();
      document
        .querySelector(".fourth-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".fourth-block__tabs-row").scrollLeft =
          document.querySelector(".fourth-block__tab_active").offsetLeft - 76;
      });
    },

    changeCasesTabs(tab) {
      this.$set(this, "casesActiveTab", tab);
      document
        .querySelector(".fourth-block__tabs-body")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".fourth-block__case-tabs-list").scrollLeft =
          document.querySelector(".fourth-block__case-tabs-tab_active")
            .offsetLeft - 76;
      });
    },
    fromTestToAnchor(anchor, e) {
      e.preventDefault();
      this.choiceSecondTab(2);
      this.$nextTick(() => {
        setTimeout(() => {
          if (document.querySelector(`#${anchor}`)) {
            document
              .querySelector(`#${anchor}`)
              .scrollIntoView({ block: "start", behavior: "smooth" });
          }
        }, 500);
      });
    },
    choiceSixthTab(tab) {
      this.$set(this, "sixthTabsActive", tab);
      document
        .querySelector(".sixth-block.tab-pinned")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".sixth-block__tabs-row").scrollLeft =
          document.querySelector(".sixth-block__tab_active").offsetLeft - 76;
      });
    },
  },
  mounted() {
    window.addEventListener("load", () => {
      if (
        this.$route.hash &&
        document.getElementById(this.$route.hash.replace("#", ""))
      ) {
        document
          .getElementById(this.$route.hash.replace("#", ""))
          .scrollIntoView({ block: "start" });
      }
    });
    window.sd2 = this;
    window.addEventListener("scroll", function () {
      document.querySelectorAll(".fixed-tabs").forEach((t, i) => {
        if (!t.closest(".tab-pinned")) {
          return;
        }
        const parent = t.closest(".tab-pinned").getBoundingClientRect();
        // t.getBoundingClientRect().top <= 0
        if (parent.top < 0 && parent.top > parent.height * -1) {
          t.style.position = "sticky";
          t.classList.add("sticky");
        } else {
          t.style.position = "";
          t.classList.remove("sticky");
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sd2.scss";
</style>
<style lang="scss">
@import "@/assets/scss/sd2_no-scoped.scss";
</style>